.insurences--tabing {
    margin-bottom: 0.8rem;
    $AlignList: 3, 4, 8;
    $TabList: 1,2,3,4,5,6,7,8;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.insurance--body {
    .prescriber-refresh-icon {
        position: relative;
        top: 0.7rem;
        cursor: pointer;
        font-size: 2.14rem;
        left: 2rem;
    }
    .prescriber-icon {
        color: white;
        font-size: 2rem;
        font-size: 1.5rem;
        font-size: 0.9rem;
        vertical-align: middle;
        padding-right: 0.1rem;
    }
    .prescriber--spi {
        list-style: none;
        color: $primary;
        font-size: 1.8rem;
        float: right;
        margin: 5px 5px 5px 0px;

        li {
            float: right;
            cursor: pointer;
            width: 16%;
        }
    }
    .full--width {
        width: 100%;
    }
    .insurance--group--icon {
        .insurance--icons {
            list-style: none;
            padding: 0rem;

            color: $primary;
            font-size: 2.1rem;
            float: right;
            margin-left: 20px;

            li {
                float: right;
                cursor: pointer;
                width: 16%;
            }
        }
        .settings--icons {
            color: $warning !important;
            font-size: 1.5rem;
            margin-right: 0.35rem;
        }

        .insurance--icons:hover {
            color: $warning !important;
        }
        .settings-label {
            font-size: 1.5rem;
        }
        .trans-label {
            font-size: 1rem;
            margin-bottom: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    .actvbutton {
        /* display: inline-block; */
        /* font-weight: 400; */
        text-align: center;
        white-space: nowrap;
        /* vertical-align: middle; */
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        /* font-size: 1rem; */
        line-height: 1.5;
        border-radius: 3px;
        box-shadow: none;
        border: 1px solid transparent;

        &:hover,&:focus {
            color: #fff!important;
            background: #429cd0!important;
            box-shadow: 0 0.0625rem 0.125rem 0.125rem #ddd;
            outline: 0;
            // border: .125rem solid transparent!important;
        }
    }

    .actvbutton-active {
        background-color: $white;
    }
    .align-box {
        padding-bottom: 10.5%;
    }
    .action {
        margin: 0.6rem 0.25rem 0rem 0.25rem;
        color: $primary;
        border-radius: 50%;
        font-size: 1.6rem;
    }
    .action :hover {
        color: $warning !important;
    }

    .flex--one {
        flex: 1;
        margin: 0rem 1.07rem 1.07rem 1.07rem;
        padding: 0rem;
        .box {
            box-shadow: unset;
            margin-bottom: 0rem;
        }
    }
    .flex--two {
        flex: 1;
        margin: 0rem 2.07rem 1.07rem 1.07rem;
        padding: 0rem;
        .box {
            box-shadow: unset;
            margin-bottom: 0rem;
        }
    }
    .card {
        .table {
            margin: 0.35rem 0px 0.35rem 0px;
        }
    }
    .dropdown-menu.show {
        width: 100%;
    }
    .label-color {
        color: $primary !important;
    }
    .submit--inactive {
        border: 0.1rem solid #5fb0d5;
        border-radius: 0.2rem;
        color: #ffffff;
        font-size: 1rem;
        height: 2.875rem;
        margin: 0 0.625rem;
        min-width: 6.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "Roboto";
        background: #999;
    }
    .notes--block {
        margin-top: 0.5rem;
        .label--text__area {
            height: 13rem;
            resize: none;
            background-color: #ffe7a0;
            width:99.5%;
            top:0.3rem
          //  text-transform: uppercase;
        }
    }
    .doc-align-top {
        padding: 0rem 0rem 0rem 1.2rem;
    }
    .drop-container {
        background: $white;
        border-radius: 6px;
        width: auto;
        @media (max-width: 860px) {
            width: 100%;
        }
        box-shadow: 1px 2px 20px rgba($black, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed $iron;
        padding: 5px 20px;
        height: 100px;
        @media (max-width: 768px) {
            padding: 5px 10px;
            margin: 20px 0%;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: $charcoal;
            padding: 8px 0px;
            margin: 0px 0px !important;
            .upload-button {
                display: inline-block;
                border: none;
                outline: none;
                cursor: pointer;
                color: $primary;
                input {
                    display: none;
                }
            }
        }
    }
    .grid {
        height: auto;
        max-height: 30rem;
    }
    .imagePreview {
        height: 42rem;
        overflow-y: auto;
    }
    .messsage-height {
        height: 4.6rem;
    }
    .height-adjust {
        max-height: 3rem;
    }

    .messsage-height--for-refill {
        height: 4rem;
    }

    .messsage-height--for-refill_sort{
        height: 2.5rem;
    }

    .messsage-height-forschedule--task {
        height: 2rem;
    }

    .message-height--for-workflwRxVerf {
        height: 5.0rem;
    }

}

.exprx--common-block {
    .eprx--block__header {
        .eprx--header__heading {
            display: block;
        }
    }
    .exprx--common-block {
        .trans-block-header {
            background-color: #999;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
            height: 2.85rem;
            line-height: 2.85rem;
            text-align: center;
            padding: 0px 0.35rem;
            text-align: center;
            color: white;
        }
    }
}
