.notes--block {
    background-color: solid-lighten($warning, 0.7646);
    padding: 1rem;
    // text-transform: uppercase;
    .notes--block--head {
        font-weight: bold;
    }
    .label--text__area {
        height: 6.666666666666667rem;
        resize: none;
        background-color: solid-lighten($warning, 0.7);
        width: 99.5%;
        top: 0.3rem;
        //  text-transform: uppercase;
    }
}
.bucket--edit--buttons {
    padding-top: 8.125rem;
}

.dose--schedule__block {
    .table-striped {
        thead {
            tr {
                background-color: $primary;
                color: $white;
            }
        }
        tbody {
            tr {
                .custom--input {
                    height: auto !important;
                }
                .custom--select {
                    top: 0.1rem !important;
                }
            }
        }
    }
    .card {
        background: $white !important;
        .card-header {
            background-color: unset !important;
        }
    }
}

.comp--Ingred__content {
    .custom--text__area {
        background-color: solid-lighten($warning, 0.7);
    }
    .search--awp {
        margin-top: 0.4375rem !important;
    }
    .custom--input input {
        margin-top: 0.4375rem;
    }
    .custom--date {
        margin-top: 0.5 rem;
        top: 0 !important;
        .input-btn-group-append{
            margin-top: 0.4rem;
        }
    }
    .dropdown-menu.show {
        width: auto !important;
    }
    .edit--drug .inventory {
        padding: 1rem;
    }
    .icon-center {
        vertical-align: middle;
        text-align: center;
        input[type="checkbox"] {
            display: none !important;
        }
    }
}

.check--dur__block {
    .vertical--tabs {
        .nav-pills {
            > li {
                > a {
                    border: 0px !important;
                    border-right: 0.1rem solid $primary !important;
                }
            }
            & .nav-link {
                border-radius: 0px !important;
            }
            .nav-link.active {
                border: 0.1rem solid $primary !important;
                border-right: 0px !important;
                background-color: unset;
                color: unset !important;
                border-top-left-radius: 0.35rem !important;
                border-bottom-left-radius: 0.35rem !important;
            }
        }
        .nav {
            li{
                a{
                    &:focus{
                        background: #c1c1c1;
                    }
                }
            }
        }
    }
    .check--Dur__accs {
        .card {
            background: $white !important;
            .card-header {
                background-color: unset !important;
            }
        }
        max-height: 35.7rem;
        overflow-y: auto;
    }
    .form-check-label {
        // margin-top: 2rem;
    }
}

.position--for-menu {
    position: relative;
    margin-top: -13px;
    box-shadow: 6px 2px 11px 0px rgba(48, 50, 50, 0.31);
}

.check-refschedule-task {
    .vertical--tabs {
        .nav {
            .nav-item {
                margin-bottom: 10px;
                width: 100%;
            }
            .nav-link {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 9px;
            }
        }
    }
}

.modal-footer {
    &.modal-footer--forscheduletask {
        position: relative;
        bottom: 0px;
        float: right;
        right: 0px;
        text-align: left;
    }
}

.divider {
    border-bottom: 1px solid $aluminum;
    float: left;
    height: 2rem;
    width: 100%;
}

.circle {
    background: $aluminum;
    border-radius: 50%;
    color: $white;
    float: left;
    font-size: 1.5rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    width: 4rem;
}

.label {
    color: $aluminum;
    line-height: 4rem;
    text-align: center;
}

.active {
    .circle {
        background: $primary;
    }
    .label {
        color: $primary;
        font-weight: 400;
    }
}

.bg--focus {
    .circle {
        background: $tertiary !important;
    }
}

.batchrefill {
    .refill-inProgress {
        display: contents;
        padding-left: 1rem;
        height: 2.6rem;
        .refill-prog--li {
            margin: 0.1rem;
            // padding: 0.1rem 0.5rem;
            border: 1px solid #ccc;
            min-width: 5.9rem;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        }
        .notinProgress--refill {
            background-color: rgb(247, 148,29);
            color: white;
        }
        .inProgress--refill {
            background-color: #51a351;
            color: white;
        }
    }

    .batchrefill-list--block{
        height: 70px;
        overflow-y: scroll;
        width: 100%;
    }
}

.grpTransm-inprogress {
    position: fixed;
    top: 0px;
    right: 0vh;
    max-height: 70vh;
    overflow: scroll;
    margin: 1.6rem 3rem;
    background: white;
    padding: 12px 4px 4px 13px;
}
.batchVerf-inprogress {
    position: fixed;
    top: -12px;
    right: -4vh;
    max-height: 70vh;
    overflow: scroll;
    margin: 1.6rem 3rem;
    background: white;
    padding: 12px 4px 4px 13px;
}
.main-header{
    .input-group{
        .dropdown-menu {
            &.show {
                max-height: 80vh;
            }
        }
    }
}

.search-height-xl{
    .input-group{
        .dropdown-menu {
            &.show {
                max-height: 59rem;
            }
        }
    }
}

.search-height-lg{
    .input-group{
        .dropdown-menu {
            &.show {
                max-height: 53rem;
            }
        }
    }
}


.search-height-md{
    .input-group{
        .dropdown-menu {
            &.show {
                max-height: 47.2rem;
            }
        }
    }
}
.search-height-sm{
    .input-group{
        .dropdown-menu {
            &.show {
                max-height: 35.5rem;
            }
        }
    }
}

.envelope-position--for-menu {
    position: relative;
    margin-top: 4px;
    left: 0.3rem;
    box-shadow: 6px 2px 11px 0px rgba(48, 50, 50, 0.31);
    left:1rem;
}
.nav-item-envelope{
    margin-bottom: 67px;
    width:147%;
    left:-1rem;
    color:#fff !important;
    cursor: pointer;
    margin-top: 0.2rem;
}

.check-envelope-task {
    .vertical--tabs {
        .nav {
            .nav-item-envelope {
                margin-bottom: 67px;
                width:147%;
                left:-1rem;
                color:#fff !important;
                cursor: pointer;
            }
            h6:hover{
                background-color:#429cd0 !important;
                color:#fff !important;
                border-radius: 20rem;
            }
            .nav-link {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 6px;
                left:-1.5rem;
                border-radius: -0.5rem;
            }
        }
    }
}
.margin-left{
margin-left: 12rem;
}
.text--left--1{
    margin-left: -1rem;
}
.text--left{
    margin-left: -3.0rem;
}
.envelope-position--for-menu--text{
    position: relative;
    margin-top: -2px;
    left: 0.3rem;
    box-shadow: 6px 2px 11px 6px rgba(48, 50, 50, 0.31);
    left:1rem;
}
.left--envelope--height{
    // height:51rem;
    // left:-4rem;
    margin-top: -13px;
}
.padding--1--rem{
    padding:1rem;
}

.grpTransOut-inprogress {
    position: absolute;
    top: -140px;
    right: -19vh;
    max-height: 70vh;
    overflow: scroll;
    margin: 5.75rem 5rem;
    background: white;
    padding: 12px 4px 4px 13px;
}

