.main-header {
    position: fixed !important;
    width: 100%;
    height: $header-height;
    background-color: $header-background !important;
    .logo {
        height: 100% !important;
        padding: 0px !important;
        .logo-lg {
            margin-top: 0.7rem;
        }
    }
    .envelope-icon{
        height: 100% !important;
        padding: 0px !important;
        .margin-envelope{
            font-size: 3rem;
            margin-left: 105rem;
        }
        
    }
    .navbar {
        .nav {
            li {
                a {
                    color: $white !important;
                    .label {
                        top: 0.125rem !important;
                        right: 4.21rem !important;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .navbar {
        // display: inline-flex;
        padding: 0rem 0.2rem;
        float: unset !important;
        min-height: 5.714rem !important;
        background-color: transparent !important;
        margin-left: 0rem !important;
        padding: 0px 10px;
        .sidebar-toggle {
            float: unset !important;
            padding: 0rem 1rem !important;
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
            }

            &::before {
                font-size: 2rem;
            }
            &:hover {
                color: #3c8dbc !important;
            }
        }
        .navbar-custom-menu {
            .navbar-nav {
                flex-direction: unset;
                .messages-menu {
                    margin: 0rem 0.3rem;
                    .dropdown {
                        background: transparent !important;
                        color: $secondary !important;
                        .header--icons {
                            background: $secondary;
                            padding: 0.7rem;
                            border-radius: 4.5rem;
                            img {
                                width: 1.2rem;
                                height: 2rem;
                                top: -0.17rem;
                                position: relative;
                            }
                            &:hover {
                                background: -webkit-linear-gradient(
                                    120deg,
                                    #0078ae 7%,
                                    #87e5f4 93%
                                );
                                background: -moz-linear-gradient(
                                    120deg,
                                    #0078ae 7%,
                                    #87e5f4 93%
                                );
                                background: -o-linear-gradient(
                                    120deg,
                                    #0078ae 7%,
                                    #87e5f4 93%
                                );
                                background: -ms-linear-gradient(
                                    120deg,
                                    #0078ae 7%,
                                    #87e5f4 93%
                                );
                                background: linear-gradient(
                                    -30deg,
                                    #0078ae 7%,
                                    #87e5f4 93%
                                );
                            }
                        }
                        .label-warning {
                            position: absolute;
                            border-radius: 50%;
                            display: block;
                            padding: unset;
                            line-height: unset;
                            font-size: 0.9rem;
                            background: $tertiary !important;
                            height: 1.42rem;
                            width: 1.5rem;
                            padding-top: 0.07rem;
                            @include media-breakpoint-up(lg) {
                                top: -0.9rem !important;
                                right: -0.5rem !important;
                                // padding: 0.11rem 0.5rem;
                            }
                            @include media-breakpoint-only(md) {
                                top: -0rem !important;
                                right: -0.5rem !important;
                                // padding: 0rem 0.5rem;
                            }
                            @include media-breakpoint-down(sm) {
                                top: -0.3rem !important;
                                right: -0.4rem !important;
                                width: 1.4rem;
                            }
                        }
                    }
                    .text--margin {
                        margin-top: 0.35rem;
                    }
                    .intake--sub__menu {
                        li {
                            .menu {
                                min-height: 25rem;
                                overflow-x: unset;
                                .rx-head {
                                    background: $light-blue;
                                    padding: 0.71rem 0rem;
                                    font-size: 1.14rem;
                                    a {
                                        color: $primary;
                                        padding: 1.07rem;
                                    }
                                }
                                li {
                                    p {
                                        margin-bottom: 0.25rem;
                                        color: #595959;
                                        font-weight: 400;
                                        .text-bold {
                                            color: #1d90bd;
                                            font-weight: 700;
                                        }
                                    }
                                    .submenu--buttons {
                                        .continue,
                                        .trash {
                                            background: $light-blue;
                                            border-radius: 0.25rem;
                                            padding: 0.35rem;
                                            color: $white;
                                            border: none;
                                            margin-right: 0.25rem;
                                            min-width: 4.28rem;
                                        }
                                        .trash {
                                            background: solid-lighten(
                                                $orange,
                                                0.9946
                                            );
                                        }
                                    }
                                    .erx {
                                        cursor: pointer;
                                        ::after {
                                            content: ":";
                                            float: right;
                                        }
                                    }
                                }
                            }
                            li {
                                padding: 0.35rem;
                                border: 0.07rem solid $iron;
                                font-size: 0.85rem;
                                margin: 0.25rem 0.57rem;
                                a {
                                    p {
                                        margin: 0rem 0rem 0.28rem 0rem !important;
                                        font-size: 0.85rem;
                                        color: #595959 !important;
                                        .text-bold {
                                            color: #1d90bd;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.drop--padding {
                        padding: 0.92rem;
                    }
                    &.intake--menu:hover {
                        .intake--sub__menu {
                            display: block;
                        }
                    }
                    &:hover {
                        background: #e4eaf6;
                        .header--icons {
                            background: -webkit-linear-gradient(
                                120deg,
                                #0078ae 7%,
                                #87e5f4 93%
                            );
                            background: -moz-linear-gradient(
                                120deg,
                                #0078ae 7%,
                                #87e5f4 93%
                            );
                            background: -o-linear-gradient(
                                120deg,
                                #0078ae 7%,
                                #87e5f4 93%
                            );
                            background: -ms-linear-gradient(
                                120deg,
                                #0078ae 7%,
                                #87e5f4 93%
                            );
                            background: linear-gradient(
                                -30deg,
                                #0078ae 7%,
                                #87e5f4 93%
                            );
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 0.6rem 0.3rem !important;
                    }
                }
            }
            .navbar-custom-menu-list{
                width: 14rem;
                position: relative;
                font-size: 0.85rem;
                margin: 0px 4px;
                .text-bold{
                    cursor: pointer;
                }
            }
        }
        @include media-breakpoint-up(md) {
            position: relative;
            float: right;
        }
    }
}
