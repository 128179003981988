.main-sidebar {
    background-color: $secondary !important;
    padding: unset !important;
    top: 5.5rem !important;
    position: fixed !important;
    width: 195px !important;
    @include media-breakpoint-only(xs) {
        top: 9.3rem !important;
    }
    @include media-breakpoint-only(sm) {
        top: 10.7rem !important;
    }
    .sidebar {
        .input-group {
            input[type="text"] {
                background-color: solid-lighten($Mirage, 0.9) !important;
                font-size: 0.9rem;
                border: none !important;
                color: solid-lighten($Mirage, 0.545) !important;
            }
            padding: 1.3rem 0.8rem;
            .fa-times {
                color: solid-lighten($Mirage, 0.545);
                position: absolute;
                right: 1.2rem;
                top: 2.1875rem !important;
            }
        }
        .sidebar-menu {
            li {
                &.treeview {
                    cursor: pointer;
                    a {
                        i{
                            color: $primary !important;
                        }
                        img {
                            width: 1.4rem;
                            height: 1.4rem;
                            margin-top: -0.4rem;
                        }
                        .nav--text {
                            left: 3rem;
                            position: absolute;
                            color: $side-nav-text;
                        }
                        .fa-angle-left {
                            color: $side-nav-text !important;
                        }
                    }
                    .pharmacy-name{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color:#fff !important
                    }
                    .pharmacy {
                        font-weight: bold;
                        color: $white;
                        font-size: 1.2rem;
                        margin-top: 3.4rem;
                    }
                    &.menu-open {
                        .nav--text {
                            color: $side-nav-active-text !important;
                        }
                        .fa-angle-left {
                            color: $side-nav-active-text !important;
                        }
                    }
                    .treeview-menu {
                        background-color: $secondary !important;
                        padding: 0px !important;
                        a {
                            color: $side-nav-text;
                            padding-left: 2.8rem;
                            font-size: 1rem;
                            cursor: pointer;
                            &:hover {
                                background-color: solid-lighten(
                                    $Mirage,
                                    0.9
                                ) !important;
                            }
                        }
                        li {
                            display: block;
                            a {
                                width: 98%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                &:hover {
                                    overflow: visible !important;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &:hover,
                    &:focus {
                        background-color: solid-lighten(
                            $Mirage,
                            0.9
                        ) !important;
                    }
                    a {
                        &:hover,
                        &:focus {
                            background-color: solid-lighten(
                                $Mirage,
                                0.9
                            ) !important;
                        }
                    }
                }
                &.overflow--scrool {
                    height: 30rem !important;
                    overflow-y: scroll !important;
                    overflow-x: hidden;
                    @extend %scroll-bar;
                }
            }
            &.footer--position {
                position: absolute;
                bottom: 6rem;
                width: 100%;
            }
        }
        .sidenav--stats{
            .sidenav--stats__ele, .sidenav--stats__ele2{
                padding-left: 0.4rem;
                padding-right: 0.4rem;
                margin-bottom: 0.4rem;
                margin-top: 0.4rem;
                cursor: pointer;
                a{
                    width: 100%;
                    display: block;
                    padding: 0.1rem 0.3rem 0.5rem 0.3rem;
                    color: $white;
                    font-weight: 500;
                    text-align: center;
                    border-radius:4px;
                    .pharmacy-label {
                        font-size: 0.813rem;
                    }
                    .pharmacy-value{
                        font-size: 1.4rem;
                        line-height: 0.7;
                        // display: inline-flex;
                        .sidenav--stats__reveal{
                            font-size: 1rem;
                            position: absolute;
                            right: 0px;
                            width: auto;
                        }
                    }
                }
                &:nth-child(4n+1) {
                    a {
                        background: $orange;
                        &:hover {
                            color: $orange;
                            background: transparent;
                        }
                    }
                }
                &:nth-child(4n+2) {
                    a{
                        background: $cyan;
                        &:hover {
                            color: $cyan;
                            background: transparent;
                        }
                    }
                }
                &:nth-child(4n+3) {
                    a{
                        background: $teal;
                        &:hover {
                            color: $teal;
                            background: transparent;
                        }
                    }
                }
                &:nth-child(4n+4) {
                    a{
                        background: $red;
                        &:hover {
                            color: $red;
                            background: transparent;
                        }
                    }
                }
                // &:nth-child(5) {
                //     background: solid-lighten($orange, 0.9);
                // }
            }
            .sidenav--stats__ele2{
                .carousel{
                    width: unset !important;
                    background: $pink !important;
                    &:hover {
                        color: $pink !important;
                        background: transparent !important;
                    }
                    a{
                        background: transparent;
                    }
                    .carousel-control-prev, .carousel-control-next{
                        span {
                            height: 38px;
                        }
                        opacity: 0.9 !important;
                        width: 30px !important;
                    }
                    .carousel-indicators{
                        display: none;
                    }
                    min-height: 60px;
                }
            }
        }
        .footer--menu {
            list-style: none;
            padding-left: 1.3rem !important;
            li {
                float: left;
                a {
                    padding: 0 0.3rem;
                    border-right: 0.07rem solid $side-nav-text;
                    color: $side-nav-text;
                }
                &:nth-child(1) {
                    a {
                        padding: 0 0.3rem 0 0;
                    }
                }
                &:nth-child(3) {
                    a {
                        border-right: none !important;
                    }
                }
            }
        }
    }
    .sidebar--menu::-webkit-scrollbar-thumb {
        background: #ECF0F5  !important;
      }
    .sidebar--menu {
        height: 50vh !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.sidebar-open {
    .main-sidebar {
        @include media-breakpoint-down(sm) {
            -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important;
        }
    }
}


.error-log--block{
    .word-break {
        word-break: break-all;
    }
    .error-log--filters {
        background: $white;
        box-shadow: 0px 3px 6px 1px rgba(165, 165, 165, 0.6);
        margin-bottom: 1rem;
        padding: 1rem;
        .error-log--filtr-btns{
            text-align: center;
        }
    }
    .error-log--list {
        background: $white;
        box-shadow: 0px 3px 6px 1px rgba(165, 165, 165, 0.6);
        padding: 1rem 2rem;
        overflow: scroll;
        .headers {
            background: $smoke;
            height: 80px;
            text-align: center;
            .col-width {
                padding: 27px 0;
                font-weight: 600;
            }
        }
        .col-width {
            width: 6%;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 100px;
            border: 0.1px solid $gainsboro;
            padding: 5px;
            word-break: break-all;
            border-left: 0px;
            border-bottom: 0;
        }
        .key2, .key4, .key6, .key8, .key5 {
            width: 10% !important;
        }
        .key7 {
            width: 25% !important;
        }
        .key2 {
            border-left: 0.1px solid $gainsboro;
        }
    }
    .error-pagination{
        padding: 1rem 2rem;
    }
}
