// Content main headings
.content--heading {
    .icon {
        text-align: center;
        padding: 0.4rem 0.5rem 0.3rem 0.8rem;
        border-radius: 50%;
        background-color: $tertiary;
        margin: 0rem 0.8rem 0rem 1rem;
        position: relative;
        top: -0.12rem;
        height: 36px;
        .far {
            font-size: 18px;
            top: 4px;
            position: relative;
            left: -2px;
        }
    }
    .pa-icon {
        width: 40px;
        height: 40px;
        background-color: $tertiary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .fal {
            color: $white;
            font-size: 24px;
        }
    }
    .content--heading__title {
        font-size: 1.57rem;
        // margin-top: 0.28rem;
        .content--name {
            @include rgba(color, $primary, 1);
        }
    }
    margin: 0rem;
}

.label-weight {
    font-weight: 400 !important;
}

// Content main headings
.small--content--heading {
    .icon {
        text-align: center;
        padding: 0.2rem 0.4rem 0.3rem 0.6rem;
        border-radius: 50%;
        background-color: $tertiary;
        margin: 0rem 0.8rem 0rem 1rem;
        position: relative;
        top: -0.12rem;
        width: unset;
        .far {
            font-size: 18px;
            top: 4px;
            position: relative;
            left: -2px;
        }
        img {
            width: 16px;
        }
    }
    .content--heading__title {
        font-size: 1.2rem;
        // margin-top: 0.28rem;
        .content--name {
            @include rgba(color, $primary, 1);
        }
    }
    margin: 0rem;
}

.small--erx__screen {
    .custom--rx-info {
        display: block;
        background: white;
        padding: 0px;
        border: 0.07rem solid #e5e5e5;
        box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        margin-top: 0.5rem;
        .exprx--common-block {
            margin-top: 0px;
        }
        .eprx--block__content {
            border: unset;
            box-shadow: unset;
        }
    }
    .exprx--common-block {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        .eprx--block__header {
            background-color: $primary;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
            line-height: 2.65rem !important;
            text-align: center;
            padding: 0px 0.35rem;
            text-align: left;
        }
        .eprx--block__content {
            // border: 0.07rem solid solid-lighten($shaded-bg-clr, 0.968);
            background-color: $white;
            // box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
            height: auto;
            padding: 0.2rem 0.7rem;
            .add__icon {
                padding: 1.75rem 0.2rem 0.2rem 0.2rem;
                font-size: 1rem;
                position: absolute;
            }
            .label--data {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0.3rem;
                font-size: 13px;
            }
            .label--data-docs {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 0.3rem;
                font-size: 17px;
                font-weight: bold;
            }
            label {
                font-size: 13px;
            }
        }
    }

    .label--data {
        text-transform: uppercase;
        margin-bottom: 0;
        word-wrap: break-word;
    }
    .seconday-search--grid {
        margin-bottom: 0rem;
    }
    .secondary {
        margin: 0rem 0.625rem !important;
        top: -1px !important;
    }
    .eprx--block__header {
        height: 2.5rem !important;
    }

    .erx--body {
        .pagination {
            padding-top: 0 !important;
        }
        .erx-pageSize-select {
            margin-top: 0px !important;
        }
        .hotkey_success {
            margin-top: 0px !important;
        }
    }
    .rx--select {
        top: -2px !important;
    }

    .enable--hotkey__buttons {
        padding: 0.52rem 0.1rem;
        min-width: 5.5rem;
        text-transform: uppercase;
        display: block;
        margin: 0 0.25rem 0.35rem 0;
        cursor: pointer;
        line-height: 1.3;
        .save--buttons {
            color: #51a351;
            font-weight: 700;
            position: relative;
            font-size: 0.8rem;
            text-transform: uppercase;
        }
        &:hover,
        &:active,
        &:focus {
            .save--buttons {
                color: $white;
            }
        }
    }
}

// Common grids
.exprx--common-block {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .large_header {
        height: 3.1rem !important;
    }
}
.account-setting-search {
    .dropdown-menu {
        &.show {
            width: 96% !important;
        }
}
}
.exprx--common-block,
.card {
    border-radius: 0 !important;
    .eprx--block__header,
    .card-header {
        background-color: $primary;
        box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        height: 2.85rem;
        line-height: 2.85rem;
        text-align: center;
        padding: 0px 0.35rem;
        text-align: left;
        .eprx--header__heading,
        a {
            //font-family: $common-grid-font-family;
            color: $white;
            text-align: left;
            margin-left: 0.5rem;
            // display: inline-flex;
            font-weight: 700;
            .eprx--header__titl-grd {
                display: inline-flex;
                .eprx--header__titl {
                    // width: 7rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: clip;
                }
            }
            .refresh--icon {
                font-size: 1.07rem;
                margin-top: 1.07rem;
            }
        }
        .add--icon {
            font-size: 1.42rem;
            margin-right: 0.35rem;
            margin-top: 0.57rem;
        }
    }
    .eprx--block__content {
        border: 0.07rem solid solid-lighten($shaded-bg-clr, 0.968);
        background-color: $white;
        box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        height: auto;
        padding: 0.7rem;
        .add__icon {
            padding: 1.75rem 0.2rem 0.2rem 0.2rem;
            font-size: 1rem;
            position: absolute;
        }
        &.entr_Inv_block {
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }
    .notes--block__content {
        background-color: $white;
        height: auto;
        padding: 0.7rem;
        .add__icon {
            padding: 1.75rem 0.2rem 0.2rem 0.2rem;
            font-size: 1rem;
            position: absolute;
        }
    }
    .report--block__header {
        background-color: #d9d9d94f;
        box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        height: 2.85rem;
        line-height: 2.85rem;
        text-align: center;
        padding: 0px 0.35rem;
        text-align: left;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;  
        border: 0.1rem #D9D9D9 solid !important;
    }
}

// refacor

.modal-icon {
    position: absolute;
    top: 0.7rem;
    right: 1rem;
    cursor: pointer;
    font-size: 2.14rem;
    top: -0.21rem;
}

// form-group
.form-group {
    margin-bottom: 0.6rem !important;
}

//label
label {
    color: $cyan !important;
    margin-bottom: 0px !important;
    font-weight: 400;
    font-family: $default-font-family;
}

//Pavan upper case
.ng-option-label {
    text-transform: uppercase;
}

.ng-select {
    font-weight: 400;
    .ng-select-container {
        .ng-value-container {
            .ng-value-label {
                text-transform: uppercase;
            }
            .ng-value{
                text-transform: uppercase;
            }
        }
    }
}
.eRxFormatText
{
    .ng-value .ng-value-label {
        text-transform: none !important;
    }
}

//Pavan upper case end

// .edit-patient {
//     color: $jet  !important;
//     .dropdown-item.active,
//     .dropdown-item {
//         color: $jet  !important;
//         .secondary-patient-card,  {
//             color: $jet !important;
//             &:hover,
//             &:active,
//             &:focus {
//                 background: $white  !important;
//                 color: $jet  !important;
//             }
//             .row{
//                 color: $jet !important;
//                 &:hover,
//             &:active,
//             &:focus {
//                 background: $white  !important;
//                 color: $jet  !important;
//             }
//             }
//         }
//         background: $white  !important;
//         color: $jet  !important;
//         &:hover,
//         &:active,
//         &:focus {
//             background: $white  !important;
//             color: $jet  !important;
//         }
//         .row{
//             color: $jet !important;
//             &:hover,
//             &:active,
//             &:focus {
//                 background: $white  !important;
//                 color: $jet  !important;
//             }
//         }
//     }
// }

.margin-top {
    margin-top: 1.42rem;
}

.secondary-patient-card {
    color: $jet !important;
    &:hover,
    &:active,
    &:focus {
        background: $white !important;
        color: $jet !important;
    }
}

// pop-up
.modal-dialog {
    border-radius: 1rem;
    .modal-header {
        background: $primary;
        color: $white;
        font-size: 1.125rem;
    }
}

//Dashboard Search grid SCSS
%search-block {
    margin-top: 6.25rem;
    .input-group-btn {
        width: 22%;
        .select--box {
            background-color: solid-lighten($orange, 0.9946);
            height: 3.75rem;
            padding-left: 0.625rem;
            color: $white;
            width: 100%;
            border: 0;
            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
                cursor: pointer;
            }
        }
        &.disabled {
            background: $disabled-txt-clr !important;
            cursor: not-allowed !important;
            height: 3.25rem;
            .select--box {
                background: $disabled-txt-clr !important;
                cursor: not-allowed !important;
            }
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
            }
        }
    }
    .search--icon {
        background-color: transparent !important;
        border: 0rem;
        .fa-search {
            color: $monsoon !important;
            top: 1.1rem;
            right: 1.7rem;
            position: relative;
            z-index: 9;
            width: 0rem;
        }
    }
    .add-new--icon {
        padding: 0.5rem 0 0.5rem 0.3rem;
        color: $tertiary;
        font-size: 1.6rem;

        &:hover {
            cursor: pointer;
        }
    }
    .search--input__box {
        background-color: $white;
        height: 3.75rem !important;
        border-radius: 0rem !important;
        border: 0.07rem solid $body-dark-back !important;
        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
            // background-color: $white !important;
            // color: unset !important;
        }
    }
    .search--input__box:disabled {
        background-color: #e9ecef !important;
    }
}

//secondary search grid i.e., small searcg grids
%second-search-block {
    .input-group {
        @extend %search-block;
        margin-top: 0rem;
        .input-group-btn {
            .select--box {
                height: 3.25rem;
            }
        }
        .search--input__box {
            height: 3.25rem !important;
        }
        .document_queue {
            .search--input__box {
                height: 2.8rem !important;
            }
        }
        .fa-search {
            top: 1rem !important;
        }
    }
}
.eqv_drugs---search {
    position: absolute;
    top: 100%;
    // right: 0px;
    left: 30%;
    height: auto;
    // background: white;
    z-index: 1000;
    padding-left: 0px;
    padding-right: 0px;
    .eq--list {
        padding-left: 0px;
        .eq--drug-item {
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:hover,
            &:active,
            &:focus {
                background: #429cd0;
            }
        }
        .dropdown-item {
            width: 99%;
            border:  2px  solid transparent;
            cursor: pointer;
            &:hover,
            &:active,
            &:focus {
                .card{
                    background: #429cd0;
                }
                border:  2px  solid #429cd0;
                // border-left:  2px  solid #429cd0;
                // border-right:  2px  solid #429cd0;
            }
            &.active{
                .card{
                    background: #429cd0;
                }
                border:  2px  solid #429cd0;
                // border-left:  2px  solid #429cd0;
                // border-right:  2px  solid #429cd0;
            }
        }
    }
    .eq_drug_header{
        width: 75%;
        background-color: $tertiary;
        h3{
            color: $white;
            margin-bottom: 0rem !important;
            text-transform: uppercase;
        }
    }
}

.search-glbl{
    left: 0% !important;
    .dropdown-item{
        width: unset !important;
    }
}

.expiration_date{
    .dropdown-menu {
        &.show {
            width: 0% !important;
        }
    }
}
.dropdown-menu {
    padding: 0px;
    margin: 0px;
    &.show {
        width: 89%;
        overflow-y: auto;
        max-height: 21.875rem;
        // border: 0.1rem solid solid-lighten($iron, 0.9946);

    }
}

.dropdown-menu_eq_list {
    padding: 0px;
    margin: 0px;
    &.show {
        width: 843px;
        overflow-y: auto;
        max-height: 21.875rem;
        border: 0.1rem solid solid-lighten($iron, 0.9946);
        margin-left: -5px;

    }
}
.has-no-add {
    .eq_drug_header {
        width: 100% !important;
    }
    .dropdown-menu {
        &.show {
            width: 100% !important;
        }
    }
}

    .has-no-add {
        .eqv_drugs---search{
            .eq_drug_header_70 {
                width: 70% !important;
                background-color: $tertiary;
            }
        }
    }

.seconday-search--grid {
    @extend %second-search-block;
    margin-bottom: 0.5rem;
}

// custom text area
.custom--text__area {
    width: 100%;
    height: unset !important;
    border: 0.07rem solid #a6a8b3 !important;
    color: #495057;
    border-radius: 0.28rem !important;
    padding: 0.375rem 0.75rem;
    resize: none;
    white-space: normal;
    border: 0.07rem solid solid-darken($shaded-bg-clr, 0.97) !important;
    text-transform: unset !important;
    &:hover,
    &:focus,
    &:active {
        border: 0.07rem solid $aluminium !important;
        outline: none !important;
    }
    &:focus {
        background-color: $input-focus !important ;
        color: black !important;
    }
}

.inline-flex {
    display: inline-flex;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    background: #429cd0;
    border: #429cd0;
    color: white;
    padding-right: 5px;
}
.close-button--clr {
    position: absolute;
    top: 0;
    right: 0;
    background: #f6251a;
    border: #f6251a;
    color: white;
}

// start Scroll bar
.modal--custom-scroll {
    display: block;
    position: relative;
    height: 50vh;
    overflow: scroll;
}
// radio buttons styles
.radiobuttons--block {
    padding-bottom: 0.5rem;
    .dash--custom__label {
        font-weight: 600;
        &::before {
            top: 0.1rem !important;
            left: -1.64rem !important;
            border: 0.07rem solid #888 !important;
            height: 1.3rem;
            width: 1.3rem;
            background: $white;
        }
        &::after {
            background-size: 61% 63% !important;
        }
    }
    .patient--wid {
        width: 8rem;
    }
}
.seconday-search--grid {
    .exprx--common-block {
        margin-bottom: 0rem !important;
    }
    .radiobuttons--block {
        padding-bottom: 0rem !important;
    }
    &.search--by__radiobtn {
        // padding-bottom: 0.6rem;
        margin-bottom: 0px;
        .radiobuttons--block {
            padding-top: 0.14rem !important;
            padding-bottom: 0.21rem !important;
        }
        .patient--wid {
            width: 8rem;
        }
    }
}

.lg-secondary-search--grid {
    .dropdown-menu {
        &.show {
            width: 300% !important;
            left: 113% !important;
        }
    }
}
.lg-secondary-search--grid--pd {
    .dropdown-menu {
        &.show {
            width: 300% !important;
            left: 114% !important;
        }
    }
}
// End For Disabled elements

.equal--height {
    display: flex;
    .equal--height-grid {
        background: $white;
        flex: 1;
        margin-left: 0.9375rem;
        margin-right: 0.9375rem;
        padding: 0px 0px 0.9375rem 0px;
        .box {
            box-shadow: none;
            margin-bottom: 0px;
        }
    }
}

.inv--wijmo--table {
    height: auto;
    max-height: 12.5rem;
    overflow-y: auto;
    border: solid $black 1px;
    padding-left: 0px;
    padding-right: 0px;
}

.inv--druglots {
    max-height: 13rem !important;
}

.inv--button {
    margin-top: 2rem;
}

.help--text__danger {
    color: $danger !important;
}

// Nav Tabs styling start

@mixin nav-tabs($tabList, $tab-list) {
    $class-slug: Patient !default;
    @each $tab in $tab-list {
        $i: index($tab-list, $tab);
        ##{$class-slug}#{$tab} {
            .txt {
                z-index: 10 * ((length($tab-list) + 1) - $i);
                @if $i != 1 {
                    left: 1rem + (6.5rem * ($i - 1));
                }
                position: relative;
                width: 9.28rem;
                height: 2.9rem;
                // height: 12.8%;
                background-image: url("/assets/inActiveTab-outline3-mm.svg");
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                color: unset;
                background-color: unset;
                border-color: unset;
                border: unset;
                &.avail-rec, &.payRecords-tab-head-bg-green{
                    background-image: url("/assets/ActiveTab-green.svg");
                    color: white;
                }
                &:active,
                &:hover {
                    background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                    z-index: 200;
                    color: $white;
                    height: 3rem;
                }
                div {
                    padding: 0.4rem 1.4rem 0.4rem 1rem;
                    font-size: 0.92rem;
                    font-weight: 700;
                    line-height: 2.4;
                    $index: index($tabList, $tab);
                    @if $index {
                        line-height: 1.1;
                        padding: 0.6rem 1.67rem 0.6rem 1.4rem;
                    }
                }
            }
            &.active {
                .txt {
                    background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                    z-index: 200;
                    color: $white;
                    height: 3rem;
                }
            }

            &.disabled {
                .txt {
                    z-index: 10 * ((length($tab-list) + 1) - $i);
                    background-image: url("/assets/inActiveTab-outline3grey-mm.svg");
                    &:hover {
                        z-index: 10 * ((length($tab-list) + 1) - $i);
                        background-image: url("/assets/inActiveTab-outline3grey-mm.svg");
                        color: unset;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    #Patient1 {
        .txt {
            left: 1rem !important;
        }
    }
}

@mixin nav-tabs-base($tabList, $tabLength) {
    border-bottom: 0.21rem solid $light-blue;
    height: 3.15rem;
    li {
        border: unset;
        text-align: center;
        color: unset;
        background: unset;
        .active {
            color: unset;
            background: unset;
        }
        &.nav-item {
            a {
                text-align: center;
                color: unset;
                background: unset;
                padding: 0;
                .active {
                    color: unset;
                    background: unset;
                }
            }
        }
    }
    @include nav-tabs($tabList, $tabLength);
}

// large Tab CSS

@mixin nav-large-tabs($tabList, $tab-list) {
    $class-slug: Patient !default;
    @each $tab in $tab-list {
        $i: index($tab-list, $tab);
        ##{$class-slug}#{$tab} {
            .txt {
                z-index: 10 * ((length($tab-list) + 1) - $i);
                @if $i != 1 {
                    left: (1rem * ($i - 1)) + (21.1rem * ($i - 1));
                }
                position: relative;
                width: 30.28rem;
                height: 2.9rem;
                // height: 12.8%;
                background-image: url("/assets/inActiveTab-outline3-mm.svg");
                position: absolute;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                color: unset;
                background-color: unset;
                border-color: unset;
                border: unset;
                &:active,
                &:hover {
                    background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                    z-index: 200;
                    color: $white;
                    height: 3rem;
                }
                div {
                    padding: 0.4rem 1.4rem 0.4rem 1rem;
                    font-size: 0.92rem;
                    font-weight: 700;
                    line-height: 2.4;
                    $index: index($tabList, $tab);
                    @if $index {
                        line-height: 1.1;
                        padding: 0.6rem 1.67rem 0.6rem 1.4rem;
                    }
                }
            }
            &.active {
                .txt {
                    background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                    z-index: 200;
                    color: $white;
                    height: 3rem;
                }
            }

            &.disabled {
                .txt {
                    z-index: 10 * ((length($tab-list) + 1) - $i);
                    background-image: url("/assets/inActiveTab-outline3grey-mm.svg");
                    &:hover {
                        z-index: 10 * ((length($tab-list) + 1) - $i);
                        background-image: url("/assets/inActiveTab-outline3grey-mm.svg");
                        color: unset;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    #Patient1 {
        .txt {
            left: 0rem !important;
        }
    }
}

@mixin nav-large-tabs-base($tabList, $tabLength) {
    border-bottom: 0.21rem solid $light-blue;
    height: 3.15rem;
    li {
        border: unset;
        text-align: center;
        color: unset;
        background: unset;
        .active {
            color: unset;
            background: unset;
        }
        &.nav-item {
            a {
                text-align: center;
                color: unset;
                background: unset;
                padding: 0;
                .active {
                    color: unset;
                    background: unset;
                }
            }
        }
    }
    @include nav-large-tabs($tabList, $tabLength);
}

.txt--white {
    color: $white;
}

.bg--red {
    background-color: $danger;
    padding: 2px 10px;
    margin-left: 10px;
}

.bg--green {
    background-color: $success;
    padding: 2px 10px;
    margin-left: 10px;
}

.border--bottom:not(:last-child) {
    border-bottom: 1px solid $shaded-bg-clr;
}

.color-red {
    color: $red;
}

.color-green {
    color: $success;
}

.color-orange {
    color: $orange;
}

.background--orange {
    background: orange;
}

.color-yellow {
    color: $yellow;
}
.color-blue{
    color:rgb(49, 175, 229);
}
.fa-user-injured {
    &::before {
        content: "\f728";
    }
}

.erx-to-add-modal {
    .modal-content {
        padding: 1rem;
    }
}

.modal-content {
    box-shadow: unset !important;
}

.transmsg--modal .modal-dialog {
    width: 64rem;
}

.grp-tran-pat-hist {
    .modal-lg {
        max-width: 90%;
        width: 100%;
    }
}

.comp--modal{
    .modal-lg {
        padding: 1%;
        background: $white !important;
        min-height: 86%;
    }
}

.comp--modal2 .modal-lg {
        min-height: 98% !important;
    }
.modal-open .modal.modal-center-to-page {
    display: flex !important;
    align-items: center !important;
    .modal-dialog {
        padding: 0px !important;
        background: $white   !important;
        flex-grow: 1 !important;
        flex-direction: column;
    }
}
.modal-open .modal.modal-md {
    .modal-dialog {
        max-height: 95vh !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
}
.modal-open .modal.modal-md-Overflow {
    .modal-dialog {
        max-height: 95vh !important;
       overflow: visible !important;
    }
}
.modal-open .modal.modal-large{
    .modal-dialog {
        padding: 1% !important;
        height: 95vh !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
}
.modal-open .modal.modal-xl-large{
    .modal-dialog {
        padding: 1% !important;
        height: 98vh !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
}
.modal-padding-0 {
    .modal-dialog {
        padding: 0% !important;
    }
    .modal-body {
        padding: 0.25rem 0.5rem !important;
    }
}
.modal-newrx-req {
    .modal-header {
        padding: 0.5rem 1rem !important;
        h4 {
            margin: 0.2rem !important;
        }
    }
    .modal-body {
        padding: 0.1rem 0.5rem !important;
    }
    .modal-footer {
        display: none !important;
    }
}
.modal-open .modal.modal-padding-1{
    .modal-dialog {
        padding: 1% !important;
    }
}
.modal-open .modal.modal-padding-2{
    .modal-dialog {
        padding: 2% !important;
    }
}
.modal-open .modal.modal-patient {
    .modal-dialog {
        padding: 1% !important;
        max-height: 95vh !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
}
.medium-custom-confirm-popup {
    .modal-dialog {
        min-width: 45% !important;
    }
}
.modal-open .modal.modal-dur{
    .modal-dialog {
        padding: 0% !important;
        height: 90vh !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        min-width: 75% !important;
    }
}
.medium-custom-confirm-popup-max-width {
    .modal-dialog {
        max-width: 45% !important;
    }
}
.scroll-popup-anywhere {
    .modal-dialog {
        pointer-events: all !important;
    }
}
// End Modal Sizes

// Rx History Color Codes
.rx--his {
    .his-bg--disabled__text--white {
        background-color: $disabled;
        color: $white;
      }
    .his-bg--red__text--white  {
        background-color: $danger !important;
        color: $catskill-White;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--grey__text--white {
        background-color: grey !important;
        color: $catskill-White;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--white__color--red {
        background-color: $white !important;
        color: $danger;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--darkgreen__text--black  .his-bg--darkgreen__text--white{
        background-color: darkgreen !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--lightgreen__text--black .his-bg--lightgreen__text--white {
        background-color: lightgreen !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--blue__text--white {
        background-color: #0000ff !important;
        color: $catskill-White;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--orange__text--black {
        background-color: orange !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--white__text--black {
        background-color: $white !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--blue_peach__text--black {
        background: linear-gradient(to left, rgb(255, 153, 153) 20%, blue 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--blue_red__text--white {
        background: linear-gradient(to left, red  20%, blue 80%)  !important;
        color: $catskill-White;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--blue_grey__text--white {
        background: linear-gradient(to left, rgb(160, 159, 159)  20%, blue 80%)  !important;
        color: $catskill-White;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--peach_red__text--black {
        background: linear-gradient(to left, red  20%, rgb(255, 153, 153) 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--peach_grey__text--black {
        background: linear-gradient(to left, grey  20%, rgb(255, 153, 153) 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--blue_white__text--black {
        background: linear-gradient(to left, white 20%, blue 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--white_red__text--black {
        background: linear-gradient(to left, red  20%, white 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
    .his-bg--white_grey__text--black {
        background: linear-gradient(to left, grey  20%, white 80%)  !important;
        font-weight: bold;
        padding-left: 1rem;
    }
}

//Refill Status modal css
.refill--stats {
    .success {
        padding-left: 0%;
        position: relative;
        color: $success !important;
        .fas {
            font-weight: 900;
        }
    }
    .error {
        padding-left: 0%;
        position: relative;
        color: $danger !important;
        .fas {
            font-weight: 900;
        }
    }
    .inProgress {
        //  padding-left: 0%;
        position: relative;
        color: #288fa7 !important;
        .fas {
            font-weight: 900;
        }
    }
}



.padding--left__0 {
    padding-left: 0;
}

.padding__left-1 {
    padding-left: 1rem;
}

.padding__left-2 {
    padding-left: 2.5rem !important;
}


.modal-body-menu .modal-dialog .modal-body{
    .new-menu {
        padding-bottom: 0px;
    }
}


.padding--right__7 {
    padding-right: 0.5rem;
}

.padding--left__7 {
    padding-left: 0.5rem;
}

.padding--left__84 {
    padding-left: 6rem;
}

.order__select--container {
    // display: inline-flex;
    .select--block {
        display: block;
        color: #333;
        cursor: default;
        display: flex;
        outline: 0;
        overflow: hidden;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        left: 0;
        min-width: 28rem;
    }
    .nav--arrows {
        position: absolute;
        padding-left: 29rem;
        padding-top: 1.5rem;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield !important;
}

input {
    &.form-control:focus {
        background-color: $input-focus !important ;
        color: black !important;
    }
}

.ng-select-focused {
    .ng-select-container {
        background-color: $input-focus !important ;
        color: black !important;
    }
}
.maintanance-prgs {
    .far {
        color: yellow;
    }
}

.ExportIcon {
    font-size: 2rem;
    color:#31afe5;
    margin-top: 12px;
    &.medium--font {
        font-size: 1.7rem !important;
    }
}

.date-picker {
    display: inline-flex;
    width: 100%;
    .form-control {
        width: 100%;
    }
    .input-btn-group-append {
        position: absolute;
        right: 0px;
        border-left: 1px solid #ccc;
        .calendar {
            padding: 6px 1rem;
        }
        .btn-outline-secondary:hover {
            background-color: $white;
            color: #1b2033;
            border: 1px solid #429cd0;
        }
    }
}
.pat-insuType {
    .inline {
    display: inline-flex;
    width: 100%;
    }
    .right-part {
        width: 50% !important;
    }
    .left-part {
        position: relative;
        width: 100%;
        left: unset;
    }
}

.curency-format {
    display: inline-flex;
    width: 100%;
    .form-control {
        width: 100%;
    }
    .input-btn-group-prepend {
        position: absolute;
        left: 0px;
        .fa-dollar-sign {
            padding: 10px 3px;
            background-color: unset;
            border: none !important;
            &:hover,
            &:active,
            &:focus {
                color: inherit !important;
                background-color: none !important;
                border-color: none !important;
            }
            &.dollar-inline--align {
                padding: 8px 3px !important;
            }
        }
    }
    :not(.eprx-price-info) * {
        .form-control {
            padding-left: 15px;
        }
    }
    span {
        width: 100%;
    }
}

.modal-lg-scroll {
    .modal-body {
        overflow-x: hidden;
        max-height: 75vh;
        overflow-y: scroll;
        // display: flow-root;
        // position: relative;
        // width: 100%;
    }
}
.modal-body {
    .eprx--block__content {
        label {
            font-size: 1rem;
            font-weight: 600;
            // padding: 1.5rem 0 0.6rem 0;
            &.custom-control-label {
                padding: 0 0 0 0.3rem;
            }
        }
    }
}

.padding-unset {
    label {
        padding: unset !important;
    }
}

.bg-light-red {
    background: solid-lighten($red, 0.8);
    color: $white;
}

.bg-hard-red {
    background: $red;
    color: $white;
}

.modal-bg--red {
    .modal-header {
        background: $red;
    }
}

.modal--header {
    border-radius: 0rem;
    color: $white;
    font-size: 1.125rem;
    background: $red;
    padding: 1rem 1rem 1rem 1rem;
}
.ng-select {
    input {
        &:hover,
        &:active,
        &:focus {
            border: none !important;
        }
    }
    &.ng-select-focused {
        .ng-select-container {
            border: 1px solid $light-blue;
        }
    }

    .ng-dropdown-panel {
        width: max-content !important;
        min-width: 100%;
        .ng-dropdown-header {
            h6 {
                margin-bottom: 0px;
                font-weight: bold;
            }
        }
        .ng-dropdown-panel-items {
            .ng-option {
                padding: 4px 10px;
                text-transform: uppercase;
                .custom--select__dataflow {
                    text-transform: uppercase;
                }
                .notextTransform {
                    text-transform: none !important;
                }
            }
            // .total-padding {
            //     height: unset !important;
            // }
            // .scrollable-content{
            //     position: relative !important;
            // }
        }
        .ng-dropdown-panel-items-more {
            width: unset !important;
        }
    }

    .help--text__icons {
        float: right;
        position: relative;
        right: 30px;
    }
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
    display: none !important;
}

.erx-change-request {
    .erx__doc--body {
        label {
            color: $cyan !important;
        }
        h5 {
            &.erx--label--primary {
                font-weight: bold;
                color: $primary;
                margin-bottom: 0rem;
                font-size: 1.3rem;
            }
        }
        h4 {
            color: $primary;
            font-weight: bolder;
        }
    }
}

.erx__Doc--section {
    .erx__doc--body {       
        .electron_rx {
            border-right: 3px solid $tertiary;
        }
        // border-radius: 1rem !important;
        border: 3px $tertiary solid !important;
        border-left: 20px $tertiary solid !important;
        // line-height: 1.2rem !important;
        label {
            color: black !important;
            font-weight: bold !important;
        }
        h5 {
            &.erx--label--primary {
                font-weight: bold;
                color: $primary;
                margin-bottom: 0rem;
            }
        }
        span.supv{
            font-weight: bold;
            color: #31afe5;
            margin-bottom: 0rem;
        }
        h4 {
            color: $primary;
            font-weight: bolder;
        }
        .exprx--common-block {
            margin-top: 0rem !important;
            margin-bottom: 0rem !important;
        }
        .prescrib--focus {
            background: $input-focus !important;
        }
        .prescrib--focus, .eprx-bg-focus {
            background: $input-focus !important;
            .eprx--block__content {
                background: $input-focus !important;
            }
        }
    }
    .fa-check,
    .fa-check-circle {
        color: $success !important;
        font-size: 1.5rem;
    }
    .fa-times,
    .fa-times-circle {
        color: $danger !important;
        font-size: 1.5rem;
    }
    .fa-exclamation-circle {
        color: $warning;
        font-size: 1.5rem;
    }
    .color-primary {
        color: $primary !important;
    }
    .line-height-2 {
        line-height: 2.5;
        padding-top: 1rem;
    }
}

.rx--sigDescription {
    textarea {
        text-transform: uppercase;
        font-size: 0.9rem;
        line-height: 1.2;
    }
}

.visible {
    display: block;
}
.hidden {
    display: none;
}
.margin-bottom-1rem {
    margin-bottom: 1rem;
}

.margin-bottom-1Halfrem {
    margin-bottom: 0.5rem;
}

::-webkit-scrollbar {
    width: 8px;
    max-height: 8px;
}



::-webkit-scrollbar-thumb {
    border-radius: 10px;
  //  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.form-check-input:disabled ~ .form-check-label {
    &::before {
        background: $gainsboro;
    }
}
.form-check-input ~ .form-check-label {
    &::before {
        background: $snow;
    }
}

.form-check-input_medium:disabled ~ .form-check-label {
    &::before {
        background: $gainsboro;
    }
}
.form-check-input_medium ~ .form-check-label {
    &::before {
        background: $snow;
    }
}

.sidebar-wijmo {
    .wj-rowheaders {
        visibility: visible !important;
    }
}
.exprx--common-block {
    .RxSig {
        .ng-dropdown-panel {
            width: max-content !important;
            // max-width: 550px !important;
        }
    }
}

.exprx--common-block {
    .change-select {
        .custom--input {
            height: unset !important;
        }
    }
}

.border-red {
    border-color: $danger !important;
}

.label-search {
    padding-right: 10px !important;
    padding-top: 7px !important;
    font-size: 1.1rem !important;
    font-weight: 600;
    width: 100px;
}

.label-search-large {
    padding-top: 23px !important;
}

.TechInit {
    .custom--input {
        input {
            text-transform: unset !important;
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #c5ddf5;
}

.transmit-list-grid {
    border: 1px solid #ccc;
    padding: 10px;
    height: 360px;
    overflow: scroll;
    .transmit-list-hdr {
        border-bottom: 1px solid #ccc;
        font-weight: 600;
        margin: 5px 2px;
        line-height: 1;
        padding: 2px;
    }
    .isM3P {
        background-color: #51a351;
        color: white;
        padding: 6px 2px !important;
    }
    .transmit-list-item {
        margin: 5px 2px;
        line-height: 1;
        padding: 6px 2px;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            background: #e2e9f1;
        }
        &.active {
            background: #c5ddf5;
            display: flex !important;
        }
    }
}

.transmit-insu-list-grid {
    .col-1 {
        padding: 0px;
    }
}

.notes__log--block {
    overflow: scroll;
    max-height: 75vh;
    &.eprx--block__content {
        overflow: unset;
        max-height: unset;
    }
}

.modal-unset-overflow {
    .modal-body {
        overflow: unset !important;
    }
}

.modal-dropdown-overflow {
    display: inline-table;
}

.erx-tooltip {
    .tooltip-inner {
        // background-color: white;
        font-size: 1rem;
        max-width: 600px;
        min-width: 520px;
        color: white;
        border: 3px solid $success;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
        label {
            color: black !important;
            font-weight: 600 !important;
        }
        table {
            td {
                font-size: unset !important;
            }
        }
    }
    .arrow {
        &::before {
            border-top-color: $success;
        }
    }
    opacity: 1 !important;
}

.password-valid {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 400px;
        min-width: 400px;
    }

}





.erx-listing-table {
    overflow-y: auto !important;
    max-height: 41.1rem !important;
    .table {
        table-layout: fixed;
        width: 100%;
        .thead-light {
            th {
                border-color: #c6c6c6 !important;
                // word-wrap: break-word;
            }
        }
    }
    .table-bordered {
        tbody {
            tr {
                td {
                    border: none;
                    border-left: 1px solid #c6c6c6 !important;
                    border-right: 1px solid #c6c6c6 !important;
                    vertical-align: middle;
                    display: table-cell;
                    // overflow-wrap: break-word;
                    padding: 0.3rem !important;
                    overflow: hidden;
                    // overflow-y: hidden;
                }
            }
            border-bottom: 1px solid #c6c6c6;
        }
    }
    .dropdown-filter {
        position: absolute;
        right: 0;
        padding: 0 1rem;
        .btn-outline-primary {
            color: unset;
            background-color: unset;
            color: unset;
            border: unset;
            .fa-cog {
                position: absolute;
                font-size: 20px;
                background: rgb(234, 234, 234);
                color: rgb(0, 0, 0);
            }
        }
        &::after {
            content: none;
        }
        .btn-outline-primary:hover,
        .btn-outline-primary:not(:disabled):not(.disabled):active,
        .btn-outline-primary:not(:disabled):not(.disabled).active,
        .show > .btn-outline-primary.dropdown-toggle {
            color: unset;
            background-color: unset;
            color: unset;
            border: unset;
            .fa-cog {
                position: absolute;
                font-size: 20px;
                background: rgb(234, 234, 234);
                color: rgb(0, 0, 0);
            }
        }
        .btn {
            &:active,
            &:focus {
                outline: none;
                box-shadow: unset;
            }
        }
        .dropdown-menu {
            &.show {
                width: 370px;
                padding: 4px;
                .dropdown-item {
                    display: inline-flex;
                    width: 170px;
                    &:active,
                    &:focus {
                        label {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }
}

.font-bigger {
    font-size: 1.3rem;
    color: $primary;
    font-weight: 500;
    text-transform: capitalize;
}

// input[type=checkbox] {
//     display: block !important;
//     opacity: 0;
//   }

.drug-img {
    width: 15rem !important;
    height: 10rem !important;
    border: 0.2rem solid !important;
    margin-left: 1rem !important;
}

.form-check-input {
    display: block !important;
    height: 1.4rem;
    width: 1.4rem;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    // opacity: 0;
}

.form-check-input_medium {
    display: block !important;
    height: 1.59rem;
    width: 1.4rem;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    // opacity: 0;
}


.form-check-input-report {
    display: block !important;
    height: 1.15rem;
    width: 1.4rem;
    margin-top: 0.1rem;
    margin-left: 1rem;
}

.min-width-0 {
    min-width: 0px !important;
}

.drugtodrug {
    max-height: 42.85rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.text--ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ngb-tooltip--class {
    max-width: 35rem;
    .tooltip-inner {
        max-width: 35rem;
    }
}
.previous-refills-editRx{
    font-size: 2.5rem;
    color: #31AFE5 !important;
    margin-right: 7px;
}
.previous-refills{
    font-size: 2.5rem;
    position: relative;
    right: 16rem;
    top: -2px;
    color: $lightSkyBlue !important;
}
.clinical-alerts{
    padding-bottom: 0.9rem;
    padding-top: 0.5rem;
}

.warn-rejRes--grid {
    max-height: 9.4rem;
    overflow-y: auto;
}

.rphDash--board {
    .filter-button {
        .filterInp-per {
            width: 50%;
        }
        .lrg-button {
            width: 9rem;
        }
    }
    .button-sml {
        margin-top: 1.1rem;
        height: 2.6rem;
    }
    .table {
        &.thead-bold {
            td {
                font-weight: bold;
            }
            th {
                font-weight: normal;
                color: #17a2b8;
                font-size: large;
            }
            .top-report {
                font-weight: bold;
                color: black;
                font-size: large;
                border-bottom: 0px solid #f4f4f4;
            }
            .top-report-bold {
                font-weight: 500;
                border-top: 0px solid #f4f4f4;
            }
        }
    }

    .table th,
    .table td {
        padding: 0rem !important;
    }
    .rph--filter--icon {
        color: #51a351;
        font-size: 1.5rem;
        padding-top: 0rem;
    }
    .rphDash_button {
        .hotkey_success {
            height: 2.4rem !important;
        }
    }
}

.accordion {
    .card-header {
        .btn,
        .btn-link {
            color: #ffffff;
            text-align: left;
            margin-left: 0.5rem;
            font-weight: 700;
            text-decoration: none;
        }
    }
}

.icon-alignment {
    padding-right: 7px;
}

.max-height-radio {
    height: 3rem;
}

.scanner-section {
    border: 3px solid gray;
    border-radius: 0.5rem;
    padding: 1rem 0;
}

.seconday-search--grid {
    @extend %second-search-block;
    margin-bottom: 0.5rem;

    .UC-Button__width {
        .hotkey_primary {
            min-width: 18rem;
        }
    }
}

.myPatinets {
    .wj-flexgrid {
        height: 29rem;
    }
    .radio-padding {
        form {
            padding-top: 1rem;
        }
    }
    .bottom-fixedheight {
        height: 29rem;
    }
    .inventory {
        margin-left: unset !important;
    }
    .table th,
    .table td {
        padding: 0rem;
        line-height: 1.7rem;
    }
    .piechart {
        height: 19rem;
    }
    .big-label {
        font-size: large;
    }
}

.batch_doc_table{
    .selected-doc-row{
        background: $primary !important;
        color:$white !important;
        td{
            i{
                &.actions{
                    color: $white !important;
                }
            }
            cursor: pointer !important;
        }
    }
    tbody{
        tr{
            td{
                cursor: pointer !important;
                .custom--input{
                    height: unset !important;
                }
                overflow-wrap: break-word;
            }
        }
    }
}

.z-index-1055 {
    z-index: 1055;
}

.ngxImageZoomContainer{
    width: 100% !important;
    .ngxImageZoomThumbnail{
        width: 100% !important;
        border:2px solid;
    }
}

.upload_doc_height_changes{
.ngxImageZoomContainer{
    width: 100% !important;
    height: 719px !important;
    overflow-y: scroll !important;
    .ngxImageZoomThumbnail{
        width: 100% !important;
        border:2px solid;
    }
}
}

.view--Trans__icon {
    border: 0.125rem solid #51a351;
    margin-bottom: 0.4rem;
    margin-right: 0.2rem;
    border-left: 0px;
    border-radius: 0px 5px 5px 0px;
    &.view--Trans-7 {
        border: 0.125rem solid $error;
        border-left: 0px;
    }
    &.view--Trans-11 {
        border: 0.125rem solid $notInitate;
        border-left: 0px;
    }
    &.view--Trans-paQueue {
        border: 0.125rem solid #a8a8a8;
        border-left: 0px;
        border-radius: 0px 5px 5px 0px;
    }
    .view-trans-arrow {
        padding: 0.1rem;
        min-width: 0px;
        background: transparent;
        color: $success;
        &.view-Trans-arrow-7 {
            color: #bd362f;
        }
        &.view-Trans-arrow-11 {
            color: $notInitate;
        } 
        &.view-Trans-arrow-paQueue {
            color: #a8a8a8;
            pointer-events: none;
        }
        &::after {
            border-top: .4em solid;
            border-right: .4em solid transparent;
            border-bottom: 0;
            border-left: .4em solid transparent;
        }
        &:hover,
        &:active,
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .dropdown-menu{
        &.show{
            overflow-y: hidden;
            border: none;
            min-width: 10.5rem;
            margin-left: -40px;
        }
        .enable--hotkey__buttons {
            margin: 0px;
            min-width: 0px;
            width: 10.5rem;
        }
    }
}



.notes-log-content {
    .heading{
        hr{
            margin: auto !important;
            width: 90%;
            border-top: 1px solid;
        }
    }
    .footer{
        hr{
            margin: auto !important;
            width: 100%;
            border-top: 1px solid;
        }
    }
    .notes-log-body {
        position: relative;
        min-width: 300px;
        min-height: 100px;
        margin: 25px auto;
        padding: 20px 15px 15px 15px;
        background: #ffff99;
        -webkit-box-shadow: 0 2px 12px rgba(0,0,0,.5);
        -moz-box-shadow: 0 2px 12px rgba(0,0,0,.5);
        box-shadow: 0 1px 2px #000;
        transform: rotate(-.5deg);
        -moz-transform: rotate(-.5deg);
        -o-transform: rotate(-.5deg);
        &:nth-child(even) {
            transform: rotate(.5deg);
            -moz-transform: rotate(.5deg);
            -o-transform: rotate(.5deg);
        }
        &:nth-child(even)::before {
            transform: rotate(6deg);
            -moz-transform: rotate(6deg);
            -o-transform: rotate(6deg);
        }
        &::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 35%;
            top: -15px;
            width: 75px;
            height: 25px;
            z-index: 2;
            background-color: rgba(243,245,228,0.5);
            border: 2px solid rgba(255,255,255,0.5);
            -webkit-box-shadow: 0 0 5px #888;
            -moz-box-shadow: 0 0 5px #888;
            box-shadow: 2px 2px 2px #000;
            -webkit-transform: rotate(-6deg);
            -moz-transform: rotate(-6deg);
            -o-transform: rotate(-6deg);
        }
        p {
            margin-top: 1rem;
            text-align: left;
            // font: normal normal normal 40px/48px 'Reenie Beanie', Helvetica, Arial, sans-serif;
            font-size: 18px;
            color: #000;
            text-shadow: white 1px 1px 0px;
            overflow:hidden;
        }
        .fa-times{
            margin-left: 9px;
        }
        .fa-edit{
            margin-left: 6px !important;
        }
        .cross-btn{
            position: absolute;
            top: -10px;
            right: -10px;
            width: 30px;
            height:30px;
            background: white;
            border-radius: 50%;
            border: 1px solid;
            box-shadow: black 3px 3px 6px;
            font-size: 1.5rem;
            &.left-edit{
                left: -10px !important;
            }
        }

    }
}

.qz-steps{
    ul{
        li{
            list-style: initial;
        }
    }
    #activateImage{
        width: 100%;
        border: 2px solid;

    }
    #activateImage2{
        width: 70%;
        border: 2px solid;
        margin-top: 1rem;
    }
    #activateImage3{
        width: 49%;
        border: 2px solid;
    }
}

.ng-dropdown-panel-items {
    .total-padding {
        // height: unset !important;
    }
    .scrollable-content{
        // position: relative !important;
        // overflow-y: scroll !important;
        width: max-content !important;
        min-width: 100% !important;
    }
}

.box-default {
    .large-data-label {
        margin-top: 2rem;
        .label--data {
            font-size: initial;
        }
    }
}

.wijimo-header {
    margin-bottom: 0rem;
    margin-top: 1rem;
    color: #17a2b8;
}

.allergy-color {
    color: $red;
}

.wj-flexchart{
    svg{
        position: relative !important;
    }
}


.label-design-block{
    .label-btn{
        color: #fff!important;
        background: #429cd0!important;
    }
    .actvbutton{
        &:focus{
            border: unset !important;
        }
    }
}


.high-light {
    color:red;
    background: yellow;
  }

  .cust-inp-heigh-unset{
    .custom--input{
        height: unset !important;
    }
  }


.anime-shake{
    animation: shakeanm 0.3s linear infinite !important;
}

@keyframes shakeanm{
    0%{margin-left: 0px }
    25%{margin-left: 3px }
    50%{margin-left: 0px }
    75%{margin-left: 3px }
    100%{margin-left: 0px }
    }

.back-dark-color{
    background-color: #003355 !important;
}

.back-white{
    background: #fff;
}

.color-gold{
    color: #e0b000;
    font-size: 16px;
}
.padding-top-14{
    padding-top: 14rem !important;
}
.padding-top-11{
    padding-top: 11rem !important;
}
.background_red {
    background-color: $red !important;
}
.help-box-information{
    font-weight: bold;
    color: white;
    padding: 2px;
    position: absolute;
    height: 24px;
    width: 33px;
    top: -1px;
    position: absolute;
    text-align: center;
    font-size: 13px;
    background: rgb(247, 148,29);
    border-radius: 73%;
    right: 8px;
}

.font-15
{
    font-size: 1.4rem !important;
}
.text-black {
        color: black !important;
}
.more-options{
    padding: 15px 11px;
    font-size: 12px;
    line-height: 1.5;
    color: #192e3e; 
    position: relative;
    left: 9px;
    background-color: #429cd0 !important;
    border: 0 !important;
    margin: 0 8px !important;
    padding-top: 0;
    padding-bottom: 2px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4) !important;
    -webkit-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px);
    border-radius: 0 0 6px 6px !important;
}
.margin_filters{
    margin-left: -11px;
    &.top-performer {
        margin-bottom: 1rem;
    }
}

.ncpdp_height_changes{
    .form-group{
        height: 40px !important;
    }
    .height_overflow{
        height: 527px;
    overflow-y: auto;
    }
}
.table_scroll {
    height: 146px;
    overflow-y: scroll;
}

.text-area-body-height {
        height: 230px !important;

}

.setting-height {
    height: 5rem;
}


.pdf-view-block{
    .pdf-view-cont{
        .thumb-block{
            max-height: 800px;
            overflow-y: auto;
            .thumb-sect{
                width:95%;
                position: relative;
                margin-bottom: 0.5rem !important;
                // border: 2px solid red;
                .sel-triang{
                    width: 0;
                    height: 0;
                    border-top: 50px solid #787878;
                    border-left: 50px solid transparent;
                    position: absolute;
                    top:0;
                    right: 0px;
                }
                .sel-check{
                    color:white;
                    font-size: 1.5rem;
                    position: absolute;
                    top:3px;
                    right: 3px;
                }
            }
        }
        .view-block{
            border-left: 3px solid;
            .view-sect{
                width: 95%;
                // max-height: 700px;
                // overflow-y: auto;
            }
        }
    }
}

.fax--tabbing {
    margin-bottom: 1.07rem;
    $AlignList: 1;
    $TabList: 1,2,3,4;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.infax--tabbing {
    margin-bottom: 1.07rem;
    $AlignList: 1,2;
    $TabList: 1,2,3;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.schedule--tabbing {
    margin-bottom: 1.07rem;
    $AlignList: 1,2;
    $TabList: 1,2;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}


.search--filter--icon {
    color: #51a351;
    font-size: 1.5rem;
    padding-top: 2rem;
}
.cursor_pointers{
    cursor: pointer !important;
}
.color_legend_dashboard{
    height: 25px;
    width: 25px;
    margin-top:-2px;
    border-radius:50% !important;
}
.z-index-0 {
    z-index: 0 !important;
}


.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .drug_lifestyle_content_styles{
    padding: 5px !important;
    border: 1px solid #c5c0c0 !important;
    border-radius: 12px !important;
  }

  .add__icon {
    padding: 1.75rem 0.2rem 0.2rem 0.2rem;
    font-size: 1rem;
    position: absolute;
}

.max-height-1{
    max-height: 2.7rem;
    overflow-y: hidden;
}
.reports_default_height {
    height:81.4vh  !important;
}
.reports_default_height_patient_medication {
    height:63rem !important;
}
.reports_default_height3 {
    height:92.5vh !important;
}
.reports_default_tabs_height {
    height:74.14vh !important ;
}
.reports_default_more_height {
    height:74.95rem  !important ;
}
.zip-Extn {
    width: 5%;
    padding-top: 0.8rem;
    padding-left: 0.4rem;
    font-size: xx-large;
}
.trans_message {
    .accordion .card-header .btn {
            width: 100% !important;
    }
    .accordion {
        .card-header {
            .btn,
            .btn-link {
                color: #495057;
            }
            .btn:active {
                box-shadow: none
            }
            .btn:focus {
                box-shadow: none !important;
            }
        }
    }
    .card-header {
        background-color: #ffffff;
    }
}
.trans-Header {
    line-height: 2.85rem;
    padding: 0px 0.35rem;
    font-weight: bold;
}
.width_6{
    width: 6rem !important;
}

.label-weight {
    font-weight: 400 !important;
}
.word-break {
    word-break: break-all !important;
}
.text-decoration_underline {
    text-decoration:  underline !important;
}
.space_overflow {
    word-break: break-all;
    white-space: initial;
}
.word_Space_Pre_Line {
    white-space: pre-line !important;
}
.text-bg-red{
    background: red;
    color: white;
    font-weight: bold;
}

.text-bg-green{
    background: green;
    color: white;
    font-weight: bold;
}

.prior-apprvl-link{
    font-size: 1.5rem;
    font-weight: bold;
}
.light_gray {
    background-color: #e9ecef !important;
}
.color_light_gray {
     color: gray !important;
}
.light_gray_for_dropdown
{
    .ng-select-container  {
        background-color: #e9ecef !important
    }
}
.m-17{
    margin-top: 17px !important;
}
.bg-light-gray-2{
    background: #e5e0e0;
}

.top-report-check{
    .form-group {
        margin-bottom: -7px !important;
    }
}
.pop_font_sizes{
    font-size: 1.1rem;
    font-weight: 500;
}
.common_patient_medication_report{
    height: 35px;
    margin-top: 19px;
    border-radius: 4px;
}
.margin_top-9 {
    margin-top: 9px;
}
.margin_top-28 {
    margin-top: 28px !important;
}
.margin_top-10 {
    margin-top: 10px;
}
.margin_top-12 {
    margin-top: 12px;
}
.margin_top-20{
    margin-top: 20px;
}
.common_icons{
    font-size: 23px;
    background: #429cd0 !important;
    padding: 9px;
    color: #fff !important;
    margin-left: -13px;
}
.coomon_primary {}
.common_icons_danger{
    color: #cc0033;
    font-size: 2.5rem;
    position: relative;
    margin-top: -1px;
}
.font-size_12 {
    font-size: 1.1rem !important;
}
.drug_categories {
    .search--input__box {
        background-color: $white;
        height: 3.75rem !important;
        border-radius: 0rem !important;
        border: 0.07rem solid $body-dark-back !important;
        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
        }
    }
    .search--icon {
        background-color: transparent !important;
        border: 0rem;
        .fa-search {
            color: $monsoon !important;
            top: 1.1rem;
            right: 1.7rem;
            position: relative;
            z-index: 9;
            width: 0rem;
        }
    }
}

.padding--left_refilldue {
    padding-left: 2.5rem;
}

.padding--left_refilldue-scheduletask {
    padding-left: 3.4rem;
}

.padding--left_scheduletask {
    padding-left: 3.5rem;
}

.setting_border{
    border: 1px solid #e5e5e5;
    padding: 6px;
    border-radius: 10px;
}

.small_col-2 {
    padding: 0rem;
    max-width: 12.24%;
}

.update_drop-down_position {
    .ng-select .ng-dropdown-panel {
        left: unset;
        right: 0%;
    }
}
.pop_searches{
    .input-group-btn {
        width: 22%;
        .select--box {
            background-color: solid-lighten($orange, 0.9946);
            height: 3.75rem;
            padding-left: 0.625rem;
            color: $white;
            width: 100%;
            border: 0;
            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
                cursor: pointer;
            }
        }
        &.disabled {
            background: $disabled-txt-clr !important;
            cursor: not-allowed !important;
            height: 3.25rem;
            .select--box {
                background: $disabled-txt-clr !important;
                cursor: not-allowed !important;
            }
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
            }
        }
    }
    .search--icon {
        background-color: transparent !important;
        border: 0rem;
        .fa-search {
            color: $monsoon !important;
            top: 1.1rem;
            right: 1.7rem;
            position: relative;
            z-index: 9;
            width: 0rem;
        }
    }
    .add-new--icon {
        padding: 0.5rem 0 0.5rem 0.3rem;
        color: $tertiary;
        font-size: 1.6rem;

        &:hover {
            cursor: pointer;
        }
    }
    .search--input__box {
        background-color: $white;
        height: 3.75rem !important;
        border-radius: 0rem !important;
        border: 0.07rem solid $body-dark-back !important;
        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
            // background-color: $white !important;
            // color: unset !important;
        }
    }
}
.margin_top-2 {
    margin-top: 2px !important;
.quickVerf__doc--grid {
    height: 28rem;
    overflow: scroll;
}
.common_patient_audherance_report {
    .dropdown-menu.show {
        width: 217% !important;
        position: absolute !important;
        left: 14.9rem !important;
    }
}



.common_patient_audherance_report_for_drug_search {
    .dropdown-menu.show {
        width: 156% !important;
    position: absolute !important;
    left: 10.9rem !important;
    }
}
.font-size_2_2{
    font-size: 2.1rem !important;
}
}
.margin-top_10 {
    margin-top: -10px !important;
}

.erx_request {
    .search--input__box {
        height: 3rem !important;
    }
}
.height_7rem {
    height: 8rem !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height_7rem_drug {
    height: 7.5rem !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height_6rem {
    height: 6.5rem !important;
}
.margin_top_9{
    margin-top: -9px !important;
}
.margin_top_6{
    margin-top: -6px;
}
.update_drop-down_position {
    .ng-select .ng-dropdown-panel {
        left: unset;
        right: 0%;
    }
}
.model_widht_51 .modal-dialog {
    max-width: 51% !important;
}
.mode_width_33  .modal-dialog {
    max-width: 33.75% !important;
}
.large--content .modal-dialog {
    max-width: 31.75%;
}
.large--content_medium .modal-dialog {
    max-width: 41.75%;
}
.large--content_medium_sm .modal-dialog {
    max-width: 34.75%;
}
.small--content_24_75 .modal-dialog {
    max-width: 24.75%;
}
.small--content .modal-dialog {
    max-width: 27.75%;
}
.small--content2 .modal-dialog {
    max-width: 17.75%;
}
.max_width_62 .modal-dialog {
    max-width: 62% !important;
}
.medium--content .modal-dialog {
    max-width: 30.75% !important;
}
.large--content, .large--content_medium, .small--content,.small--content2, .max-modal-34, .mode_width_33, .max-wdth_25,.max-wdth_29, .large--content_medium_sm{
    .modal-content, label {
        font-size: 1.4rem !important;
    }
    label {
        font-size: 1.4rem !important;
    }
}
.medium--content{
    .modal-content, label {
        font-size: 1.2rem !important;
    }
    label {
        font-size: 1.2rem !important;
    }
}
.screen__fit {
    
    label {
        font-size: 1.2rem !important;
    }
}
.width_567 {
    width: 567px !important;
}
.account-setting-date_fields{
    .custom--date {
        margin-top: -17px !important;
    }
}
.bright_green{
    color:  	#7FFF00 !important;
}
.radio_common_sizes {
    position: absolute !important;
    margin-top: 0.3rem !important;
    margin-left: -1.25rem !important;
}
.refresh--icon-style{
    position: relative;
    top: 2rem;
    left: -1rem;

}
.patient_prescriber_card {
    height: 7rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
.margin_8_2{
    margin-top: 0.8rem !important;
    margin-left: 2rem !important;
}
.account-setting-date_fields{
    .custom--date {
        margin-top: -17px !important;
    }
}
}
.bright_green{
    color:  	#7FFF00 !important;
}
.min_height_37{
    min-height: 37rem !important;
}
.document_queue {
    .search--input__box {
        height: 2.8rem !important;
    }
}
.dms_width_changes {
    .ngxImageZoomContainer {
        height: 719px !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
}

.common_scroll{
    height: 53rem;
    overflow-y: scroll;
}
.dms_document_scroll {
    height: 55rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}


.ng2-pdf-viewer-container {
    overflow-x: revert !important;
}

.font-size-14{
    font-size: 1.4rem !important;
}

// .edit-rx_lot {
//     .form-group {
//         margin-top: -4px !important;
//         margin-bottom: 0.6rem !important;
//     }
// }

.drug-price-notExist {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left: auto;
}
.color_legend_vertical_line{
    border-left: 16px solid rgb(8, 111, 27) !important;
}
.color_legend_vertical_line-notassign{
    border-left: 16px solid #bbb !important;
}
.color_vertical_line--orange {
    border-left: 5px solid #F57600 !important;
}
.pickup_rx{
    .input-group-text {
            background-color: #429cd0 !important;

    }
}
.document_queue_from_rx_history {
    height: 37.4rem !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.min_height_51 {
    min-height: 51rem;
}
.far_plus {
    .actions {
        margin: 0rem 0.25rem 0rem 0.25rem;
        color: #429cd0;
        border-radius: 50%;
        font-size: 1.25rem;
    }
}
.asap_max-height {
    max-height: 647px !important;
}
.content-fit-popup {
    max-height: 545px;
    overflow-y: auto;
}
.ins-box-popup {
    margin-bottom: 5px !important;
}
.ins-body-popup {
    padding-top: 2px !important;
    padding-bottom: 0px !important;
}
.entr-invntry-popup {
    .box {
		margin-bottom: 10px !important;
	}
	.exprx--common-block {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.equal--height .equal--height-grid {
		padding: 0px 0px 0.25rem 0px !important;        
	}
}
.common_qty_order_quantity {
    padding-left: 8px;
    padding-right: 13px;
}
.cursor_pointer_disable{
    cursor: not-allowed !important;
}
.accord-ttl-icon {
    align-items: center;
    margin-right: 10px;
}
.check-dur-block {
    .card-body {
        padding: 0.25rem !important;
    }
    .btn-link:focus {
        box-shadow: none !important;
    }
    .accordion-section-over {
        max-height: 670px;
        overflow: auto;
    }
    .rx-label {
        color: #429cd0 !important;
        text-transform: none !important;
    }
    .accordion-custom-header {
        position: absolute;
        width: 100%;
        padding: 10px 5px 10px 20px;
        top: 0;
        left: 0;
    }
}

.duplicate-drug-modal .eprx--block__content {
    max-height: 75vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.patient-family-info-btn {
    min-width: 2rem !important;
    padding: 0px 2px !important;
    margin: 4px 0px !important;
    height: 2.6rem !important;
    span {
        margin-right: 0px !important;
    }
}
.edit-patient {
    .exprx--common-block .eprx--block__content {
        padding: 0.2rem 0.7rem !important;
    }
    .table th, .table td {
        padding: 0.35rem !important;
    }
}
.preview_height {
    height: 263px !important;
}
.view-block {
    .page {
        width: 592px !important;
        height: 570px !important;

    }
    .canvasWrapper {
        overflow: hidden !important;
    }

   .pdfViewer .page
    {
        overflow: auto !important;
        overflow-x: hidden !important;
    }

}
.autoRefresh-checkbox{
    margin-top: 0.5rem;
     left: 1rem
}
.erx-pat-presc-drug-card-spacing {
    margin: 10px 0px 5px 0px !important;
}

.editpatient--tabing {

}


.check-right{
    height: 2.5rem;
    left: 4rem ;
}
.label-right{
    right: 0.5rem !important;
}
.left-text-field{
    left: 0.5rem;
}
.password-valid-Settings {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 300px !important;
        min-width: 200px !important;
    }
}
.setting-font-weight{
    label{
        font-weight: 400 !important;
    }
}
.left--4{
    left: 4rem;
}
.left--1{
    left: 1rem;
}
.padding-top{
    padding-top: 0.5rem;
}


.erx-inactive-drug {
    color: red;
}
.top-check{
    top : 1.8rem
}
.days-top-check{
    padding-top:1.5rem;
}
.check-cntrl-pad-height{
    left:3rem;
    padding-top:2rem;
}
.top-rem{
    top: -1rem;
}
.right-one-rem{
    right: 1rem;
}
.custom-radio-eRx .was-validated .custom-control {
    line-height: 1.1;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    overflow-x: auto;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c6c6c6 !important;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// .envelope-icon{

// }
}
.font-size-for-envelope{
    font-size: 1.3rem;
    left:1rem
}
.text-primary--big-font{
    font-size:1.4rem;
    color:#429cd0 !important
}
.left-2-rem{
    left: -1rem;
    font-size: 1.5rem;
    color:rgb(49, 175, 229);
}
.blue-eye-icon{
    font-size:1.4rem;
    color:rgb(49, 175, 229);
}
.margin--left{
    margin-left: 2.5rem;
}
.margin-left-window{
    margin-left: 0.5rem;
}
.margin-envelope-top-left{
    margin-top:-7rem;
    margin-left:106rem;
    font-size:2rem;
    width:5rem;
    position: absolute;
}
.notification--count-top-left{
    margin-top:-4.6rem;
    margin-left:104rem !important;
    font-size:1.3rem;
    width:5rem;
    position: absolute;
    color:white;
    margin-left:115rem;
}
.rounded-pill{
    border-radius: 20rem !important;
}
.icon-right{

    font-size: 1.42rem;
    margin-left: 10rem;
    margin-top: 0.57rem;

}
.stngs-scroll{
    height: 50.05rem;overflow-y: scroll; overflow-x: hidden;
}
.stngs-scroll-NY{
    overflow-y: scroll; overflow-x: hidden;
}
.icon-font-size{
    font-size:1.3rem;
    margin-left:3px;
}
.right-rem{
right:5.8rem;
}
.search-left-width{
    .custom--input{
        width:36rem !important;
    }
}
.grid_custom_timePicker{
    .ngb-tp-hour,.ngb-tp-minute {
         flex-direction: initial !important;
        .btn {
            margin-top: -29px;
            margin-right: -70px;
            padding: 0.3rem !important;
        }
        .ngb-tp-input{
            width: 45px !important;
        }
    }
    .ngb-tp-hour {
        .ngb-tp-input {
            margin-right: -95px;
            margin-top: -3px;
        }
    }
    .ngb-tp-minute {
        margin-top: -3px;
        margin-right: 37px;
        .ngb-tp-input{
        margin-right: 62px;
        margin-top: 0px;
        }
    }
   .ngb-tp-minute > button:nth-child(1){
    margin-left: 55px;
    margin-top: -28px;
    }
    .ngb-tp-minute > button:nth-last-child(1){
        margin-bottom: -51px;
        margin-right: -30px;
        margin-left: -57px;
    }
    .ngb-tp-hour > button:nth-child(1){
        margin-top: -81px;
        margin-right: -154px;
        margin-bottom: -53px;
    }
    .ngb-tp-hour > button:nth-last-child(1){
        margin-top: 22px;
        margin-right: -115px;

    }
    .ngb-tp-spacer {
        margin-left: 72px;
    }
    .btn-outline-primary {
        color:white;
        background-color: #429cd0;
        margin-left: -7rem;
        margin-top: -3px;
}
}
.checkin-custom--input {
    .ngb-tp {
    height: 3rem ;
    }
}
.input-mb-increase {
    margin-bottom: 0.75rem !important;
}
.commonboldfont{
    font-weight: bold
}
.login-box-body {
    background: none !important;
}
.cust--login .login-box-body{
    width: 59% !important;
}
.common_gray{
    color: #5b6068 !important;
}
.custom_margin_for_login {
    margin-top: 10rem !important;
    margin-left: 2px
}
.i-icon-left{
left:8rem;
margin-top: -5px;
}
.left-2{
    font-size: 1.5rem;
    color:rgb(49, 175, 229);
}
.edit--icon-color{
    font-size: 1.3rem;
    color:#429cd0;
    margin-left:13px;
}
.font-header-size{
    font-size:13px;
    right:16px;
}
.font-innertext-size{
    font-size: 12px;
}
.tooltip--top{
margin-top:-1rem;
}
.tooltip--top--more{
    margin-top:22rem;
}
.text---left{
left:16px;
font-size: 25px;
margin-top: 8px;
color: #429cd0;
}
.form--check--label--2{
    font-size:1rem;
    font-weight: 400 !important;
}
.text-right--margin-left{
    margin-left: 90rem;
}
.download--icon{
    font-size: 1.35rem;
    color: #429cd0;
    margin-top: 3px;
}
.preview--icon{
    color: #429cd0;
    font-size: 1.51rem;
}
.bell--icon{
    width: 5.1rem;
}
.count_bellIcon{
    position: relative;
    left: -33px;
}
.resolvedOrNot{
    width:2rem;
    margin-top:-3px
}
.error--count{
    width:2rem;margin-top:-5px
}
.success--count{
    width:2rem;margin-top:-4px
}
.oth-amt-tooltip {
    .tp-header-text {
        text-decoration: underline;
        font-size: 14px !important;
    }
    .font-innertext-size {
        font-size: 14px;
    }
}
.icon--styling{
   font-size: 1.3rem;
       color:#429cd0;
}
.common-css-tabs{
    width:272px;
}
.runTask--icon{
    color:#429cd0;
    font-size: 1.2rem;
}
.icon--styling--del{
    font-size: 1.3rem;
    color:red;
}
.font-innertext-size_PharmAcc{
    font-size: 13px;
}

.in-active{
    color:lightgrey !important;
    font-size: 1.25rem;
   margin: 0rem 0.25rem;
}
.inactive-icon {
    color:grey;
    cursor:not-allowed;
}
.text_overflow_eRx_ExtraPopUp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 11rem;
}
.ellpise {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.benefitCheck {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 2px solid #31afe5;
    color: #31afe5;
    text-align: center;
    font: 11px Arial, sans-serif;
    display: inline-block;
    padding: 1px 2px 3px 3px;
    margin-right: 2px;
    font-weight: 600;
  }
.benefitCheckPosition {
    position: relative;
    bottom: 0.1rem;
}
.benefitCheckeRx {
    position: relative;
    bottom: 0.2rem;
}
.benefitCheck:hover {
    color: #ffc107;
    border: 2px solid #ffc107;
}
.benefitCheck-disable {
    border: 2px solid grey !important;
    color: grey !important;
}
.DUR_intakeQueue {
    position: relative;
    bottom: 2px;
    margin: 2px
}
.active_tab_bg {
    background: #FFF5EA !important;
}

   .border-top-right-radius_10 {
    border-top-right-radius: 10px;
   }
   .border-top-left-radius_10 {
    border-top-left-radius: 10px;
   }
   .active_bar {
     background-color: #d1fbd1 !important;
   }
.help--text__success {
    color: green !important;
    font-weight: 500;
    margin-left: 7.4rem;
}
   .left--help{
    padding-top: 26px;
    left:2rem
   }
.light_orange_forBg {
    background-color: rgb(255, 191, 138)  !important;
}
.ml-65 {
    margin-left: -65% !important;
}
.range_dark_1 {
    background: $submitted !important;
}
.erx_doc_scroll {
    overflow-x: hidden;
    overflow-y: scroll !important;
    height: 22rem !important;
}
.dur_hover_class{
    font-size: 15px;
    font-weight: bold;
}
.font-size_14px{
    font-size: 14px;
}
.content-fit-popup--grid {
    max-height: 30vh;
    overflow-y: auto;
}
.box_control_rx {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.error_Placement {
    position: absolute;
    top: 0.9rem !important;
    left: 1rem !important;
}

.highlighted{ background-color: #f7941d; }

.image_placement {
    width: 21px;
    height: 21px;
    position: absolute;
}

.documents_Scroll {
    max-height: 82vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.position-340{
    right: 14px;
    top: 6px;  
    color: #ffc107 !important;
    padding:0 0.5rem 0 0.5rem !important;
    border-radius: 20rem !important;
    font-weight: 700 !important;
    position: absolute !important;
    border: solid 1px #ffc107!important;
}
.borderBottom {
    border-bottom: 0.5px solid gray;
}
.reportPrevDisplay {
    overflow-y: scroll;
    height: 79.4vh !important;
    overflow-x: hidden;
    display: flex;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: 0.07rem solid #e5e5e5;
    margin-left: 0 !important;
    padding: 0.7rem;
}
.water_discontinued{
    position: absolute;
    top: -4rem !important;
    left: -49rem;
    top: 37.5rem !important;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #807b7b !important;
    opacity: 0.3;
    font-size: 26px;
}

.post-it-note {
    padding: 0.4em;
    background: #D9F9C1;
    position: relative;
    min-height: 3rem;
    min-width: 9em;
    height: 3rem !important;
    border-radius: 5px 5px 16px 5px !important;
    border: 1px solid #5FBA94;
 }
 .post-it-note:before {
    content: "";
    position: absolute;
    bottom: 0em;
    right: 0;
    border-width: 1.1em 1.2em 0 0;
    border-style: solid;
    border-color: #5FBA94;
    border-radius: 6px 0px 21px 0px;
 }
.ng-dropdown-panel
.ng-dropdown-panel-items
.ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel
.ng-dropdown-panel-items .ng-option.ng-option-selected {
    .textFont {
        font-weight: 600;
    }
}

.docthumb {
    position: absolute;
    top: 38px;
    padding: 0px 6px;    
    &.thumb {
        top: unset !important;
        bottom: 0;
    }
}
.glyphicon--color {
    background: #F57600;
    color: #fff;
    border: #fff;
}
.position--ColorLegend {
    position: relative !important;
    bottom: 0.3rem !important;
}
/* Parent Container */
.parent-container {
    position: relative;
    overflow: visible;
    z-index: 500;
}

/* Wijmo Grid */
.wijmo-grid {
    position: relative;
    z-index: 100;
}

/* Input Container */
.input-container {
    position: relative;
    z-index: 600;
}

/* Suggestion Dropdown */
.common_global_search_inline.dropdown-menu.show {
   
    width: 56% !important;
    left: 21.5% !important;
    max-height: 421px;         /* Set a maximum height */
    overflow-y: auto;
    position: absolute;
    top: 100%; /* Aligns directly below the input */
    left: 0;
    width: 100%;
    z-index: 5000 !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.datepicker--inline.dropdown-menu.show {
    @extend .dropdown-menu;
    width : auto !important;
    overflow-y: unset !important;
}
.datepicker-auto-width.dropdown-menu.show {
    width: auto !important;
}
.iou-badge {
    display: inline-block;
    padding: 2px;
    font-size: 10px; 
    font-family: Arial, sans-serif; 
    border-radius: 5px !important; 
    border: 2px solid; 
    font-weight: bold; 
}
.iou-badge span {
    color: white;
    padding: 2px 6px;
    border-radius: 3px;
    margin-left: 3px;
}

.iou-badge.iou-green {
    color: green;
    border-color: green;
}

.iou-badge.iou-green span {
    background-color: green;
}

.iou-badge.iou-orange {
    color: orange;
    border-color: orange;
}

.iou-badge.iou-orange span {
    background-color: orange;
}

.erx--drug-body {
    .dash--drug--grid {
        position: absolute;
        z-index: 9999;
        width: 200%;
        max-width: 200%;
        left: -289px;
    }
    .notdash--drug--grid {
        position: absolute;
        z-index: 9999;
        width: 207%;
        max-width: 208%;
        left: -46px;
    }
    .block__header {
        height: 48px;
        font-size: 16px;
    }
    .block__content {
        border: 0;
        box-shadow: none;
    }
    .search--icon{
        position: absolute;
        right: 1.5rem;
        top: 2.1rem;
        color: #777777;
    }
    .help-block {
        font-size: 12px;
        position: absolute;
        text-align: right;
        color: #f6251a;
        right: 17px;
        top: 84%;
    }
    .sub--table--grid{
        border-top: 1px solid #7777;
        border-left: 1px solid #7777;
        border-right: 1px solid #7777;
        border-radius: 10px 10px 0px 0px;
    }
    .drug--wjgrid {
        max-height: 28.8vh;
    }
    .equlentwj-grid {
        max-height: 15.8vh;
        border-top-right-radius:0;
        border-top-left-radius:0;
    }
    .radio--label {
        color: #000 !important;
        font-size: 14px;
        font-weight: 400 !important;
        padding-top: 1px;
    }
    .drug-check-input {
        height: 1rem;
        width: 1rem;
        margin-top: 0.2rem;
        margin-left: 0.28rem;
    }
      
      .custom-radio {
        display: flex;
        align-items: center;
        margin-right: -50px;
      }
      
      .custom-radio input[type="radio"] {
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #99AEBE;
        border-radius: 50%;
        margin-right: 10px;
        outline: none;
        position: relative;
      }
      
      .custom-radio input[type="radio"]:checked {
        border: 3px solid #31afe5;
      }
      
      .custom-radio input[type="radio"]:checked::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
}

  .progress-bar-container {
    position: relative;
    width: 98%;
    background-color: #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    height: 7px;
    max-width: 98%;
    margin: 1px auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }

  .progress-bar--common {
    height: 100%;
    background: #31afe5;
    width: 100%;
    position: absolute;
    transition: width 0.3s ease;
  }
  /*.progress-bar--common.Loading {
    animation: loading 3s infinite linear;
    background-color: #4cc1f3; 
    }

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}*/
.pa-body {
    .sort-section {
        display: flex;
        flex-direction: column;
            .custom-radio {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-top: 13px !important;
            }
            
            .custom-radio input[type="radio"] {
                appearance: none;
                width: 16px;
                height: 16px;
                border: 1px solid #99AEBE;
                border-radius: 50%;
                margin-right: 10px;
                outline: none;
                position: relative;
            }
            
            .custom-radio input[type="radio"]:checked {
                border: 4px solid #31afe5;
            }
            
            .custom-radio input[type="radio"]:checked::before {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }    
        }
        
        .filter-section {
            position: absolute;
            left: 13%;
            .search--icon{
                position: absolute;
                right: 1.9rem;
                top: 2.4rem;
                color: #777777;
            }
        }
        
        .badge-section {
            position: absolute;
            left: 64.5%;
            padding-top: 4px;
            .status-badges {    
                display: flex;
                flex-direction: row;
                gap: 12px;
                column-gap: 12px;
                width: 136%;  
                .badge {
                    display: flex;
                    padding: 4px;
                    border-radius: 8px;
                    background-color: #FFFFFF;
                    font-size: 13.5px;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 900;
                    cursor: pointer;
                .badge-count {           
                    width: auto;
                    min-width: 36px;
                    text-align: center;
                    border-radius: 20px;
                    padding: 4px;
                    line-height: 19px;
                }

                &.pending { 
                    color: $pending;
                    border: 1px solid #CEE5F1;
                    border-bottom: 2px solid #CEE5F1;
                    .badge-count {
                        background: #E7F2F8;
                    }
                    &.pa-active {
                        background-color: $pending;
                        color: #fff;
                        border: 1px solid $pending;
                        border-bottom: 2px solid $pending;
                        .badge-count {
                            background: #fff;
                            color: $pending;
                        }   
                    }
                }

                &.submitted { 
                color: $submitted;
                border: 1px solid #FDEAD2;
                border-bottom: 2px solid #FDEAD2;
                .badge-count {
                    background: #FEF4E8;
                }
                &.pa-active {
                    background-color: $submitted;
                    color: #fff;
                    border: 1px solid $submitted;
                    border-bottom: 2px solid $submitted;
                    .badge-count {
                        background: #fff;
                        color: $submitted;
                    }   
                }
                }

                &.approved {
                color: $approved;
                border: 1px solid #DCEDDC;
                border-bottom: 2px solid #DCEDDC;
                .badge-count {
                    background: #EEF6EE;
                }
                &.pa-active {
                    background-color: $approved;
                    color: #fff;
                    border: 1px solid $approved;
                    border-bottom: 2px solid $approved;
                    .badge-count {
                        background: #fff;
                        color: $approved;
                    }   
                }
                }

                &.denied { 
                color: $denied;
                border: 1px solid #FDD3D1;
                border-bottom: 2px solid #FDD3D1;
                .badge-count {
                    background: #FEE9E8;
                }
                &.pa-active {
                    background-color: $denied;
                    color: #fff;
                    border: 1px solid $denied;
                    border-bottom: 2px solid $denied;
                    .badge-count {
                        background: #fff;
                        color: $denied;
                    }   
                }
                }

                &.cancelled { 
                color: $cancelled;
                border: 1px solid #DADADA;
                border-bottom: 2px solid #DADADA;
                .badge-count {
                    background: #E8E9EB;
                }
                &.pa-active {
                    background-color: $cancelled;
                    color: #fff;
                    border: 1px solid $cancelled;
                    border-bottom: 2px solid $cancelled;
                    .badge-count {
                        background: #fff;
                        color: $cancelled;
                    }   
                }
                }

                &.error {
                color: $error;
                border: 1px solid #F2D7D5;
                border-bottom: 2px solid #F2D7D5;
                .badge-count {
                    background: #F8EBEA;
                }
                &.pa-active {
                    background-color: $error;
                    color: #fff;
                    border: 1px solid $error;
                    border-bottom: 2px solid $error;
                    .badge-count {
                        background: #fff;
                        color: $error;
                    }   
                }
                }

                &.inActive {
                    color: $inActive;
                    border: 1px solid #FFE5DC;
                    border-bottom: 2px solid #FFE5DC;
                    .badge-count {
                        background: #FFF2EE;
                    }
                    &.pa-active {
                        background-color: $inActive;
                        color: #fff;
                        border: 1px solid $inActive;
                        border-bottom: 2px solid $inActive;
                        .badge-count {
                            background: #fff;
                            color: $inActive;
                        }   
                    }
                }

                &.notInitate {
                    color: $notInitate;
                    border: 1px solid #EDE4DB;
                    border-bottom: 2px solid #EDE4DB;
                    .badge-count {
                        background: #F6F1ED;
                    }
                    &.pa-active {
                        background-color: $notInitate;
                        color: #fff;
                        border: 1px solid $notInitate;
                        border-bottom: 2px solid $notInitate;
                        .badge-count {
                            background: #fff;
                            color: $notInitate;
                        }   
                    }
                }

                &.archive {
                    color: $archive;
                    border: 1px solid #E1D8F7;
                    border-bottom: 2px solid #E1D8F7;
                    .badge-count {
                        background: #F0EBF8;
                    }
                    &.pa-active {
                        background-color: $archive;
                        color: #fff;
                        border: 1px solid $archive;
                        border-bottom: 2px solid $archive;
                        .badge-count {
                            background: #fff;
                            color: $archive;
                        }   
                    }
                    }

            }
              }
        }
}
.pawj-grid {
    .pa-check-input {
        height: 1.2rem;
        width: 1.2rem;
        margin-top: 0.4rem;
        margin-left: 0.28rem;
    }
    .pa-status {
        text-transform: capitalize;
        font-weight: 500;
    }
    .pa-send_retry {
        color: #2FAFE4;
        font-size: 16px;
        padding-top: 5px;
    }
}

.label-printing-history-table{
    tbody {
        max-height: 220px;
        tr {
            table-layout: fixed;
        }
    }
}
/* will use in next sprint(Immediate Mode)*/
.dashb--infoText {
    background-color: #FFE097;
    border-radius: 5px;
    padding: 7px;
    // margin-bottom: 5px;
    display: inline;
    font-size: 1rem;
}

.pa-status-1{
    color: $pending;
}
.pa-status-2, .pa-status-14{
    color: $approved;
}
.pa-status-3, .pa-status-13{
    color: $submitted;
}
.pa-status-8, .pa-status-15 {
    color: $denied;
}
.pa-status-6{
    color: $cancelled;
}
.pa-status-7{
    color: $error;
}
.pa-status-9{
    color: $inActive;
}
.pa-status-11{
    color: $notInitate;
}
.pa-status-12{
    color: $archive;
}


/* Header styling */
.paheading {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
}   

.main-title {
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #444444;
    white-space: nowrap;
}
.main-title::before {
    content: "";
    background-color: #CCCCCC;
    width: 50%;
    height: 1px;
}
.main-title::after {
    content: "";
    background-color: #CCCCCC;
    width: 50%;
    height: 1px;
}

/* Status item container */
.status-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 14px; 
    padding-bottom: 0px;   
}
.br-5{
    border-radius: 5px;
}
.status-item-bg-1 {   
    background-color: #E7F2F8;
    @extend .br-5;
}
.status-item-bg-2, .status-item-bg-14 {
    background-color: #EEF6EE;
    @extend .br-5;
}
.status-item-bg-3,.status-item-bg-13 {
    background-color: #FEF4E8;
    @extend .br-5;
}
.status-item-bg-6 {
    background-color: #E8E9EB;
    @extend .br-5;
}
.status-item-bg-7 {
    background-color: #F8EBEA;
    @extend .br-5;
}
.status-item-bg-8, .status-item-bg-15 {
    background-color: #FEE9E8;
    @extend .br-5;
}
.status-item-bg-9 {
    background-color: #FFF2EE;
    @extend .br-5;
}
.status-item-bg-11 {
    background-color: #F6F1ED;
    @extend .br-5;
}
.status-item-bg-12 {
    background-color: #F0EBF8;
    @extend .br-5;
}
.status-item-bg-16 {
    background-color: #F6F6F6;
    @extend .br-5;
}

/* Status icons */
.status-icon {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #ffffff;
    margin-right: 8px;
}

.status-title {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
}
.status-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 0px 7px; 
    border: 1px solid #CCCCCC;
    border-radius: 20px; 
}
.status-sub::before {
    content: "";
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

/* Status colors */
.paStatus-text-1 {
    color: $pending;
    .status-sub::before {
        background-color: $pending;
    }
    .status-icon { background: $pending; }
}
.paStatus-text-2, .paStatus-text-14 {
    color: $approved;
    .status-sub::before {
        background-color: $approved;
    }
    .status-icon { background: $approved; }
}
.paStatus-text-3, .paStatus-text-13 {
    color: $submitted;
    .status-sub::before {
        background-color: $submitted;
    }
    .status-icon { background: $submitted; }
}
.paStatus-text-6 {
    color: $cancelled;
    .status-sub::before {
        background-color: $cancelled;
    }
    .status-icon { background: $cancelled; }
}
.paStatus-text-7 {
    color: $error;
    .status-sub::before {
        background-color: $error;
    }
    .status-icon { background: $error; }
}
.paStatus-text-8, .paStatus-text-14 {
    color: $denied;
    .status-sub::before {
        background-color: $denied;
    }
    .status-icon { background: $denied; }
}
.paStatus-text-9 {
    color: $inActive;
    .status-sub::before {
        background-color: $inActive;
    }
    .status-icon { background: $inActive; }
}
.paStatus-text-11 {
    color: $notInitate;
    .status-sub::before {
        background-color: $notInitate;
    }
    .status-icon { background: $notInitate; }
}
.paStatus-text-12 {
    color: $archive;
    .status-sub::before {
        background-color: $archive;
    }
    .status-icon { background: $archive; }
}
.paStatus-text-16 {
    color: $delete;
    .status-sub::before {
        background-color: $delete;
    }
    .status-icon { background: $delete; }
}
/* Status text */
.status-text {
    flex: 1;
    text-align: left;
}

.divider-line {
    height: 1px;
    background: #ddd;
    margin: 0px 30px;
    margin-top: 5px;
}
.status-time {
    font-size: 12px;
    color: #444444;
    padding-left: 12%;
    padding-top: 2%;
    display: flex;
    align-items: center;
}

/* User action text */
.user-action {
    font-size: 12px;
    color: #444444;
    padding-left: 10px;
    display: flex;
    align-items: center;

}
.user-action::before {
    content: "";
    margin-right: 4px;
    background-color: #444444;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.archived_traingle_icon {
    border-right: 10px solid $archive !important;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    border-bottom: 10px solid transparent !important;
    display: inline-block !important;
}
.paStatus_name {
    position: absolute;
    left: 17%;
    bottom: 11px;
    &.paStatus_bottom{
        bottom: 18px;
    }
}
.paVendor_name {
    position: absolute;
    left: 17%;
    font-size: 12px;
    bottom: 5px;
}
.disabled--selection{
    cursor: not-allowed !important;
    opacity: 0.4 !important;
}
.border-bg--color_InfoBlock {
    background: #FFF3E4 !important;
    border-color: #FFCF93 !important;
}
.main_error_block {
    background-color: #fce4e4;
    padding: 12px;
    color: #cc0033;
    font-weight: 500;
    font-size: 15px;
    .error_msg_text {
        padding-left: 8px;
        &::before {
            content: ".";
            margin-right: 4px;
            background-color: #cc0033;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            display: inline-block;
            line-height: 17px;
            font-size: 0;
        }
    }
}


