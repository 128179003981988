.common_global_search_max_content_display_for_changeRequest
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 315% !important;
                left: 107% !important;
            }
        }
    }
}
.common_global_search_max_content_display_for_dms
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 135% !important;
                left: 3% !important;
                right: 41% !important;
            }
        }
    }
}
.common_global_search_max_content_display_for_drug_allergy
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 128% !important;
                left: -14% !important;
            }
        }
    }
}
.common_global_search_max_content_display
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 186% !important;
                left: 43.5% !important;
            }
        }
    }
}
.common_global_search_max_content_display_PatientAudheranceReport
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 201% !important;
                left: 51% !important;
            }
        }
    }
}
.common_global_search_max_content_display_for_reports
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 144% !important;
                left: 22.5% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_fullHeight
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: max-content !important;
                transform:  translate(1px, 46px) !important;

            }
        }
    }
}
.common_global_search_max_content_display_length_left
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 227% !important;
                left: -61% !important;
            }
        }
    }
}
.common_global_search_max_content_display_adjust
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 259% !important;
                left: 80% !important;
            }
        }
    }
}
.common_global_search_max_content_display_adjust_prescriber
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 181% !important;
                left: 41% !important;
            }
        }
    }
}
.common_global_search_max_content_display_for_message_log
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 100% !important;
                left: 0% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_reports_control_Rx
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 166% !important;
                left: 33% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_reports_price_list
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 159% !important;
                left: 32% !important;

            }
        }
    }
}
.common_global_search_max_send_refill_req
{
.dropdown-menu {
            &.show {
                width: 103% !important;
                left: 57px !important;
            }
        }
}
.common_global_search_inline
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 97% !important;
                left: 0.3% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_druginv
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 97% !important;
                left: 0.3% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_ndrug
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 97% !important;
                left: 0% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_Presc
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 135% !important;
                left: -17% !important;

            }
        }
    }
}
.common_global_search_max_content_display_for_ReportWriter
{
    .has-no-add {
        .eq_drug_header {
            width: 100% !important;
        }
        .dropdown-menu {
            &.show {
                width: 108% !important;
                left: 4% !important;
            }
        }
    }
}

.eRx_transfer-request {
    .dropdown-menu.show {
        width: 132% !important;
         left: 23% !important;
    }
}