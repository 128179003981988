.cust--login {
    background-color: white !important;
    font-family: $default-font-family;
    width: 100%;
    background-size: cover !important;
    float: left;
    background: url("../../assets/img/Background.png");
    height: 88vh;
    .login-box-body {
        // background: $primary;
        padding: 2.42rem;
        width: 50%;
        margin: 8% 0% 0% 20%;
        padding-bottom: 4rem;
        padding-top: 4rem;
        @media (max-width: 1024px) {
            width: 50%;
            margin: 8% 0% 0% 60%;
        }
        @media (max-width: 768px) {
            width: 90%;
            margin: 15% 0% 0% 5%;
        }
        border-radius: 0.28rem;
        .control-label {
            span {
                color: $black;
                // font-weight: bold;
            }
        }
        .custom--input {
            input {
                // text-transform: unset !important;
            }
        }
    }
    .login-submit {
        background: $login-button;
        border: none;
    }
    .submit {
        background: $orange;
        border: 0.1rem solid $orange;
    }
}
.footer--block {
    margin: 4.5% 0px 7.2%;
    color: $white;
}
