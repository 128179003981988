.editpatient--tabing {
    margin-bottom: 1.07rem;
    // $patientTabList: 2, 3, 7, 10, 11, 12;
    $AlignList: 2, 3, 5, 6, 10, 11;
    $TabList: 1,2,3,4,5,6,7,8,9,10,11,12,13;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.prescriber--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 1, 3, 4;
    $TabList: 1,2,3,4,5;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.security--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 1, 3, 4;
    $TabList: 1,2,3,4,5;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.reports--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 3;
    $TabList: 1,2,3;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.auditTrail--tabing {
   // margin-bottom: 1.07rem;
    $AlignList: 0;
    $TabList: 1,2,3,4;
    .nav-tabs {
        @include nav-large-tabs-base($AlignList, $TabList);
    }
}

.rx-actnbtn--tabing {
    margin-bottom: 0.7rem;
    $AlignList: 10;
    $TabList: 1,2,7,8,9,12;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.editpatient {
    .edit-patient--group--icon {
        .edit-patient--icons {
            list-style: none;
            padding: 0rem;
            color: $primary;
            font-size: 2.3rem;
            float: right;
            margin: 0rem 0rem 0rem 1.42rem;
            li {
                float: right;
                cursor: pointer;
                width: 16%;
            }
        }
        .edit-patient--icons:hover {
            color: $warning !important;
        }
    }
    .inline--class {
        .form-group {
            span {
                display: block !important;
            }
        }
    }
    .align--msg--top {
        padding-top: 1rem;
        height: 12rem;
    }
    .bar--chart__design {
        li {
            background-color: red;
            height: 100px;
            float: left;
            width: 50px;
            border: 1px solid #fff;
            padding-right: 7px;
            margin-right: 5px;
            text-align: center;
        }
    }
    .header-button {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    .exprx--common-block{
        margin-bottom: 0px;
        .eprx--block__content{
            padding-bottom: 0px;
        }
    }
}

.add-family {
    display: inline-block;
    position: absolute;
    left: 14.5rem;
}

.family--info__group {
    margin-top: 1.42rem;
    .famil--icons {
        .fa {
            padding: 0.71rem 0.21rem 0.71rem 0.21rem;
        }
    }
}
.dashboard--header {
    .input-group {
        padding-top: 1.5rem;
        @include media-breakpoint-down(xs) {
            width: 100%;
            max-width: 100%;
        }
        .input-group-btn {
            .select--box {
                background-color: $light-blue;
                height: 2.56rem;
                padding-left: 0.625rem;
                color: $header-background;
                min-width: 7.5rem;
                border-radius: 0.25rem 0rem 0rem 0.25rem !important;
            }
        }
        .search--icon {
            height: 2.375rem;
            background-color: $header-background;
            border: 0rem;
            .fa {
                color: $faded-txt-clr !important;
                position: absolute;
                right: 0.5rem;
                top: 2.2rem;
            }
        }

        .search--input__box {
            background-color: $header-background;
            max-width: 39.375rem;
            margin: auto;
            border-radius: 0rem 0.25rem 0.25rem 0rem !important;
        }
    }
    .dropdown-menu {
        &.show {
            width: 77%;
            left: 19% !important;
            overflow-y: scroll;
            max-height: 21.875rem;
            border: 0.1rem solid solid-lighten($iron, 0.9946);
        }
    }
}

.notes--log {
    .modal-lg {
        width: 56.25rem;
    }
    .notes--log--modal__btns {
        position: relative;
        top: -0.4rem;
    }
    .notes--log__btns {
        position: relative;
        top: 2rem;
        padding-left: 5rem;
    }
    .saved--notes {
        .fa {
            padding-left: 0.5rem;
            margin-top: 0.5rem;
        }
    }
    .overflow--text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 250px;
    }
}

.hide--tag {
    visibility: hidden;
}

.before--notes__transistion,
.after--notes__transistion {
    -webkit-transition: width 1s ease, height 1s ease;
    -moz-transition: width 1s ease, height 1s ease;
    -o-transition: width 1s ease, height 1s ease;
    transition: width 1s ease, height 1s ease;
}
.before--notes__transistion {
    height: 0px;
    overflow: hidden;
}
.after--notes__transistion {
    height: 10rem;
}

.link__checkboxes {
    font-size: 1.2rem;
}

.delivery--auto__search {
    width: 15.75rem;
}
.editpathistory--tabing {
    margin-bottom: 1.07rem;    
    $AlignList: 1,2,3;
    $TabList: 1,2,3;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}