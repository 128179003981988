.toasterGrid {
    position: fixed;
    left: 0%;
    bottom: 4%;
    width: 195px;
    z-index: 99999;
    transition: ease-in 3s all;
     .alert {
        @extend %alert--message;
        padding: 0.3rem 0.8rem;
        margin-bottom: 0.5rem;
        transition: ease-in 1s all;
        width: 195px;
        border: 1px solid $aluminium;
        min-height: 4rem;
        top: 0vh;
        left: 0vh;
     }
    .alert-toaster {
        background-color:#fce4e4 !important;
        }
    .alert-toaster--success {
        background-color: #DFF2BF !important;
 }
    .alert-toaster--warning {
        background-color: #FEEFB3 !important;
    }
    .fa {
        min-width: 10rem;
        margin: 0px;
        padding: 0.7rem 0rem;
        position: relative;
        font-weight: 500;
        font-size: 1.5rem;
        margin-top: 0.07rem;
    }
    .ta {
        top: -4px;
        right: -4px;
    }
    .toast-size {
        font-weight: 500;
    }
}

.toasterGridmsg {
    position: fixed;
    left: 32%;
    bottom: 55%;
    z-index: 99999;
    transition: ease-in 3s all;
    width: 57rem;
    // height: 100%;
    // width: 100%;
    // background: rgba(0,0,0,0.3);

    .alert {
        text-align: center;
        @extend %alert--message;
        .toast-size{
            font-size: 1.1rem;
            text-align: left;
            padding: 0.5rem 1rem;
        }
     }
    .toast-message--error {
        display: inline-flex;
        background-color:#fce4e4 !important;
        box-shadow: 0 0 0.8rem $faded-txt-clr;
        padding: .75rem 1.25rem;
        button, input[type="submit"], input[type="reset"] {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }
    }

    .toast-message--warning {
        background-color: #FEEFB3 !important;
        box-shadow: 0 0 0.8rem $faded-txt-clr;
        padding: .75rem 1.25rem;
        display: inline-flex;
    }
    .fa {
        min-width: 10rem;
        margin: 0rem;
        padding: 0.7rem 0rem;
        position: relative;
        font-weight: 500;
        font-size: 1.5rem;
        margin-top: 0.07rem;
    }
    .ta {
        top: -4rem;
        right: -4rem;
    }
    .toast-size {
        font-size: 1.2rem;
        font-weight: 500;
    }
}


%alert--message {
    // top: 15vh;
    // left: 38vw;
    // z-index: 999999;
    // box-shadow: 0 0 0.8rem $faded-txt-clr;
    // display: table;
    .toast-error-img{
        font-style: oblique;
        text-align: center;
        padding: 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        color: $white;
        .fas, .fa {
            font-size: 1.5rem;
            margin-top: 0.07rem;
        }
    }
    .toast-error-msg{
        color: $white;
    }

    .toast-message--error {
        color: #cc0033;
        // font-weight: 500;
         .fa-times-circle{
            color: #cc0033;
            font-size: 2.5rem;
            position: relative;
            top: calc(50% - 1.00rem);
        }
    }

    .toast-message--success {
        color: #4F8A10;
        // font-weight: 500;
         .fa-check-circle{
            color: #4F8A10;
            font-size: 2.5rem;
            position: relative;
            top: calc(50% - 1.25rem);
        }
    }
    .toast-message--unsuccess {
        color: #cc0033;
         .fa-times-circle{
            color: #cc0033;
            font-size: 2.5rem;
            position: relative;
            top: calc(50% - 1.25rem);
        }
    }

    .toast-message--warning {
        color: #9F6000;
        // font-weight: 500;
         .fa-exclamation-triangle{
            color: #9F6000;
            font-size: 2.5rem;
            position: relative;
            top: calc(50% - 1.25rem);
         }
    }

    .toast-close {
        color: #4F8A10;
    }

    .toast-close--warning {
        color: #9F6000;
    }

    .toast-message--error {
        color: #cc0033;
    }

}

.alert-successs {
    @extend %alert--message;
    background-color: $success;
    font-weight: 300;
}

.alert-warningg {
    @extend %alert--message;
    color: $tertiary;
    background-color: $tertiary;
    font-weight: 300;
}

.alert-errorr {
    @extend %alert--message;
    background-color: $danger;
    font-weight: 300;

}
