%drop-container {
    background: $white;
    border-radius: 6px;
    width: 500px;
    @media (max-width: 860px) {
        width: 100%;
    }
    box-shadow: 1px 2px 20px rgba($black, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed $iron;
    margin: 20px 10%;
    padding: 5px 20px;
    @media (max-width: 768px) {
        padding: 5px 10px;
        margin: 20px 0%;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: $charcoal;
        padding: 8px 0px;
        margin: 0px 0px !important;
        .upload-button {
            display: inline-block;
            border: none;
            outline: none;
            cursor: pointer;
            color: $primary;
            input {
                display: none;
            }
        }
    }
}

.emplrecord-drop {
    @extend %drop-container;
    margin: 0px;
    width: auto !important;
    text-align: center;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 200px;
    height: 70px;
    .btn {
        border: 2px solid gray;
        color: gray;
        background-color: white;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        width: 200px;
        height: 70px;
        .warn-text{
            font-size: 0.9rem;
            color: rgb(247, 148,29);
            position: absolute;
            font-weight: bold;
            left: 27%;
        }
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    width: 200px;
    height: 70px;
    cursor: pointer;
}
}