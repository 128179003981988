.merge--body {
    .eprx--block__header {
        height: 3rem !important;
        .merge--patient__search {
            margin-top: 0.21rem;
        }
    }
    .title{
        font-weight: 300
    }
    .grid {
       height: auto;
       max-height: 28.57rem;
   }
   .btn-float {
       float: right;
       margin-top: 0.71rem;
       margin-left: 0.71rem;
   }
   .title--width {
    //    height: 500px;
   }
   }
