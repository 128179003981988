.transfer--type-active {
    border: 1px solid #e9e5e5;
    padding: 16px 16px 16px 8px;
    border-radius: 8px;
    cursor:  pointer !important;
}
.transfer--type-disabled {
    border: 1px solid #F5F5F5;
    padding: 16px 16px 16px 8px;
    border-radius: 8px;
    cursor:  pointer !important;
}
label:hover {
    color: $black;
}
 .transfer--type-active:hover {
    background: #F1F9FE !important;
    border: 1px solid #BEE6F9 !important;
    .fa-pills-regular, .fa-file-prescription-regular, .fa-history, .fa-pharmacy-icon {
        color: $primary !important;
        font-weight: 200;
    }
    label{
        font-weight: 200;
        color: $black !important;
    }
 }
 .transfer--type-disabled{
    cursor: not-allowed !important;
    background-color: #fff;
    .fa-pills-regular, .fa-file-prescription-regular, .fa-history, .fa-pharmacy-icon {
        color: #E9E9E9 !important;
        font-weight: 200;
    }
    label{
        font-weight: 200;
        color: #E9E9E9 !important;
    }
 }
.fa-pharmacy-icon{
    font-size: 2.5rem !important;
    margin-top: 3px;
}
.transfer_type{
    position: relative;
    top: 2px !important;
}
.results {
    min-height: 14rem !important;
    max-height: 14rem !important;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    box-shadow: 2px;
    box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
    padding: 4px;
    font-family: "Roboto";
    border: 0.07rem solid #e5e5e5;
    height: auto;
    border-radius: 10px !important;
    color: #000000;
    cursor: pointer !important;
    width: 41.5rem;
    z-index: 999;
    
}
.patient-edit--transfer-in{
    .eprx--block__content{
        border: 0.07rem solid $transferinBorder !important;
    }
    .ng-dropdown-panel-items .scrollable-content{
        width: 0px !important;
      }
    .fa-edit{
        font-size: 20px;
    }
    .fa-pills-regular, .fa-file-prescription-regular, .fa-history, .fa-pharmacy-icon {
        font-size: 20px;
        color: #b4b4b4;
    }
    .fa-circle-2-solid,  .fa-circle-3-solid, .fa-circle-1-solid {
        font-size: 20px;
        color: $jumbo;
        position: relative;
        top: 2px;
        right: 8px;
    }
    .text-light_gray {
        color: $jumbo;
    }   
    .fa-check-circle{
        color: $green;
        font-size: 20px;
        position: relative;
        top: 2px;
        right: 8px;
    }
}
.pharmacy_suggest {
    padding: 8px;
    font-family: "Roboto";
    margin: 4px;
    border: 0.07rem solid #e5e5e5;
    height: auto;
    border-radius: 10px !important;
    color: #000000;
    position: relative;
    cursor: pointer !important;
    z-index: 999999;
    .dropdown-item {
        white-space: normal !important;
    }
  }
  .pharmacy_suggest:hover {
    background-color: #c5ddf5;
  }
  .transfer_position{
    position: relative;
    top: -25px;
  }
  .pharmacy_label{
    font-weight: 500;
    color: #17a2b8 !important;
  }
  
.ng-option.ng-option-marked {
    background-color: $white;
}
.transferred_in{
    position: absolute;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #8a8484 !important;
    opacity: 0.3;
    font-size: 46px;
}
.water_mark_Transferredin {
    @extend .transferred_in;
    top: 25rem;
    right: 10rem;
}
.water_mark_TransferredinforVrf {
    @extend .transferred_in;
    top: 24rem;
    right: 16rem;
}
.eprx-transfer-req {
    .control-label span {
        font-weight: 600;
    }
    .ng-select-container {
        height: 0px !important;
    }
}
.transfer_in_height{
    height: 25.2rem !important;
}
.transfer_in_height_editRx{
    height: 23.2rem !important;
}
.parent_transfeType{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.child_transfeType{
    align-items: center;
    display: grid;
    justify-items: normal;
}
.suggest_display
{
    .dropdown-menu {
        &.show {
            width: 130% !important;
            left: -10% !important;
        }
    }
}
.transfer_type_display{
    width: 10px;
    display: block;
    height: 15px;
}
.transferable_information{
    display: flex;
    align-items: center;
    gap: 5px;
}
.transfer-in_icon{
    vertical-align: middle;
    line-height: 15
}
.unavailable_bg{
        background: $brightOrange;
}
.available_bg{
    background: $green;
}
.wijmo_transfer_in{
    .wj-flexgrid {
        border-radius: 0px !important;
    }
}
#transferrableNonRxs.wj-flexgrid .wj-cell {
    padding: 6px;
}
#fullScreen{
    font-size: 26px !important;
    position: relative;
    top: 4px;
    left: 10px;
}
.remove_headers{
    .wj-flexgrid .wj-header {
        display: none;
      }
      /* Remove the space allocated for the header */
    .wj-flexgrid .wj-flexgrid {
        padding-top: 0 !important;
        margin-top: 0 !important;
      }
}
.show_originalReq{
    height: 30px;
    line-height: 1;
}