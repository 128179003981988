
.md-xx-lg {
    .modal-lg {
        max-width: 96%;
    }
}

.md-xs-lg {
    .modal-sm {
        max-width: 45%;
    }
}

.md-x-lg {
    .modal-lg {
        max-width: 80%;
    }
}
.md-x-lg_77 {
    .modal-lg {
        max-width: 77%;
    }
}
.rx-transfer_modal{
    .modal-lg {
        max-width: 84%;
        margin-left: 15rem;
    }
    .modal-header {
            height: 50px  !important;
    }   
}
.schedule-width{
    .modal-lg {
        max-width: 80% !important;
        margin-top: 20rem;
        min-height:0px;
        margin-right: 12.1rem;
    }

}
.schedule-inner-popup-width{
    .modal-lg {
        max-width: 86% !important;
        margin-top: 6rem;
        min-height:0px;
        left:7rem;
    }

}

.md-x-ref-lg {
    .modal-lg {
        max-width: 70%;
    }
}

.md-x1-lg {
    .modal-lg {
        max-width: 85%;
    }
}

.md-x2-lg {
    .modal-lg {
        max-width: 92%;
    }
}


.md-xx-lg {
    .modal-lg {
        max-width: 95%;
        padding: 2%;
    }
}
.md-xx-screenWidthCmn {
    .modal-lg {
        max-width: 84%;
        margin-left: 15rem;
    }
}
.md-xx-screenWidth {
    .modal-lg {
        max-width: 88.5%;
        margin-left:15rem;
    }
}

.medium-modal .modal-dialog {
    max-width: 45%;
}
.medium-modal-50 .modal-dialog {
    max-width: 50%;
}
.medium-modal-60 .modal-dialog {
    max-width: 60%;
}
.medium-modal-68 .modal-dialog {
    max-width: 68%;
}
.max-modal-74 .modal-dialog {
    max-width: 74%;
}
.max-modal-78 .modal-dialog {
    max-width: 78%;
}
.max-modal-80 .modal-dialog {
    max-width: 80% !important;
}
.max-modal-94 .modal-dialog {
    max-width: 94% !important;
}
.max-modal-82 .modal-dialog {
    max-width: 82% !important;
}
.medium-sm-modal .modal-dialog {
    max-width: 35%;
}
.font-size-11 {
    font-size: 1.1rem !important;
}
.max-modal-27 .modal-dialog {
    max-width: 27% !important;
}
.max-modal-33 .modal-dialog {
    max-width: 33.55% !important;
}
.max-modal-34 .modal-dialog {
    max-width: 34.55% !important;
}
.medium-sm-modal-23 .modal-dialog {
    max-width: 23%;
}
.medium-sm-modal-30 .modal-dialog {
    max-width: 30%;
}
.medium-sm-modal-337 .modal-dialog {
    max-width: 33.75% !important;
}
.medium-sm-modal-305 .modal-dialog {
    max-width: 30.75% !important;
}
.medium-sm-modal-40 .modal-dialog {
    max-width: 40%;
}

.medium-sm-modal-54 .modal-dialog {
    max-width: 54%;
}
.medium-sm-modal-66 .modal-dialog {
    max-width: 66%;
}
.medium-sm-modal-63 .modal-dialog {
    max-width: 63%;
}
.medium-sm-modal-73 .modal-dialog {
    max-width: 73%;
}
.medium-sm-modal-25 .modal-dialog {
    max-width: 25%;
}
.max-modal-75 .modal-dialog {
    max-width: 75%;
}
.max-modal-1339 .modal-dialog {
    max-width: 1339px !important;
}
.max-modal-1293 .modal-dialog {
    max-width: 1293px !important;
}
.max-modal-25 .modal-dialog {
    max-width: 25% !important;
}
.max-modal-26 .modal-dialog {
    max-width: 26% !important;
}
.small-modal-scroll .modal-dialog {
    max-width: 30%;
    font-size: 1.4rem !important;
    .modal-body {
        max-height: 60vh;
        overflow-y: scroll;
    }
}
.max-modal-1400 .modal-dialog {
    max-width: 1400px !important;
}

.erx-to-add-modal {
    .modal-content {
        padding: 1rem;
    }
}

.refillStats .modal-dialog {
    width: 15.5rem;
    position: fixed;
    left: 85%;
    height: fit-content;
    border: 2px solid $tertiary;
}

.preview--control--file .modal-dialog {
    width: 70rem;
}

.quickRefill--modal .modal-dialog {
    width: 75rem;
    position: relative;
}
.quickRefill--modal--Expand .modal-dialog {
    max-width: 88% !important;
    left: 7rem !important;
}
.model_widht_51 .modal-dialog {
    max-width: 51% !important;
}
.mode_width_33  .modal-dialog {
    max-width: 33.75% !important;
}
.mode_width_37  .modal-dialog {
    max-width: 37% !important;
}
.mode_width_34  .modal-dialog {
    max-width: 34% !important;
}
.large--content .modal-dialog {
    max-width: 31.75%;
}
.model_width_41 .modal-dialog {
    max-width: 41%;
}
.large--content_medium .modal-dialog {
    max-width: 41.75%;
}
.small--content .modal-dialog {
    max-width: 27.75%;
}
.small--content2 .modal-dialog {
    max-width: 17.75%;
}
.transmsg--modal .modal-dialog {
    width: 64rem;
}

.model_widht_51 .modal-dialog {
    max-width: 51% !important;
}
.mode_width_33  .modal-dialog {
    max-width: 33.75% !important;
}

.large--content .modal-dialog {
    max-width: 31.75%;
}
.large--content_62 .modal-dialog {
    max-width: 62% !important;
}
.large--content_medium_sm .modal-dialog {
    max-width: 34.75%;
}
.small--content .modal-dialog {
    max-width: 27.75%;
}
.small--content2 .modal-dialog {
    max-width: 17.75%;
}
.small--content_placement .modal-dialog {
    max-width: 44.75%;
    transform: translate3d(434px, 254px, 0px) !important;
}
.max-wdth_73 .modal-dialog{
    max-width: 73.49% !important;
}
.max-wdth_25 .modal-dialog{
max-width: 25.75% !important;
}
.max-wdth_26 .modal-dialog{
    max-width: 26.75% !important;
    }
.max-wdth_29 .modal-dialog{
    max-width: 29.75% !important;
    }
    .max-wdth_56 .modal-dialog{
        max-width: 56% !important;
        }
        .max-wdth_50 .modal-dialog{
            max-width: 50% !important;
            }
    .width_72 {
        height: 72px !important;
    }
  .margin_top_10 {
    margin-top: -10px;
    }
.screen__fit .modal-dialog {
    max-width: 87%;
    left: 7rem;
    top: 1.7rem !important;
}



    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #2196F3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
      .btn-settings_styles {
        border: 1px solid #429cd0 !important;
        background: white !important;
      }
.mode_width_85  .modal-lg {
    max-width: 85% !important;
    left:7rem;
 }
 .pat_extern_hist  .modal-lg {
    max-width: 85% !important;
    left:6rem;
 }
 .max-width-80 {
    .modal-lg {
        max-width: 80%;
        margin-left: 18rem;
    }
 }
 .max-modal-40 .modal-dialog {
    max-width: 40% !important;
}
 .max-modal-44 .modal-dialog {
    max-width: 44% !important;
}

.max-modal-39 .modal-dialog {
    max-width: 39% !important;
}

.max-modal-51 .modal-dialog {
    max-width: 51% !important;
}
.max-modal-51  .modal-dialog .modal-content{
    border-radius: 10px !important;
}

.erx_extra_infoPopup .modal-title {
    margin-bottom: 0;
    line-height: 1;
    font-weight: 500;
    font-size: 18px;
}
.max-modal-1300 .modal-dialog {
    max-width: 1300px !important;
}
.max-modal-1350 .modal-dialog {
    max-width: 1350px !important;
}
.max-modal-1267 .modal-dialog {
    max-width: 1267px !important;
}
.max-modal-750 .modal-dialog {
    max-width: 750px !important;
}
.modal-content {
    border-radius: 10px !important;
}
.modal-header, .modal--header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
.max-modal-970 .modal-dialog {
    max-width: 970px !important;
}
.max-modal-1050 .modal-dialog {
    max-width: 1050px !important;
}
.max-modal-1450 .modal-dialog {
    max-width: 1450px  !important;
}
.max-modal-1500 .modal-dialog {
    max-width: 1500px  !important;
}

.max-modal-90  .modal-dialog {
    max-width: 90%;
}

.max-modal-79  .modal-dialog {
    max-width: 79%;
}

.max-modal-1070 .modal-dialog {
    max-width: 1070px !important;
}
.max-modal-1485 .modal-dialog {
    max-width: 1486px !important;
}
.screen__fit--Center .modal-dialog {
    max-width: 87%;
    left: 7rem;
    top: 1.7rem !important;
}

.max-modal-87 .modal-dialog {
    max-width: 87%;
    margin-left: 15rem;
 }
 .misc_info .modal-dialog {
    max-width: 96.75%;
    margin-left: 11%;
}
 .transfer-in {
    .modal-header {
        height: 50px  !important;
        }
 }
 .markasTransferIn_ediRx{
    .modal-lg {
        max-width: 87%;
    }
    .modal-header {
        height: 50px; /* Adjust the height to your needs */
      }
}
.max-modal-3575 .modal-dialog {
    max-width: 35.75% !important;
}

.medium-modal-50-margin {
    .modal-content {
        margin-top: 3rem !important;
    }
}

.max-modal-fullScreen {
    .modal-dialog {
        max-width: 85%;
        margin-left: 11.2%;
    }
    .modal-header {
        height: 50px  !important;
}   
}