.erx--body {
    .align-button {
        margin-top: 1.25rem;
        margin-left: auto;
    }

    .align-button-top--prfm {
        margin-top: 7.25rem;
        margin-left: auto;
    }

    .align-label {
        width: 7.85rem;
    }
    .align-label-filters {
        width: 8.85rem;
    }
    .align-label-user {
        width: 5.85rem;
    }
    .select-spacing {
        margin-left: 1rem;
    }
    .filter-top {
        margin-top: 0.9375rem;
    }
    .erx-pageSize-select {
        margin-top: 0.75rem;
        float: right;
        text-align: center;
    }
    .color-code_Style {
        border-radius: 9px !important;
        margin-top: 0.17rem !important;
        padding: 2px !important
    }
    .warning-color-code {
        @extend .color-code_Style;
        background-color: $red;
        color: $white;
        
    }
    .success-color-code {
        @extend .color-code_Style;
        background-color: $green;
        color: $black;
        // padding: 7px;;
    }

    .waiting-color-code {
        @extend .color-code_Style;
        background-color: $yellow;
        color: $black;
    }
    .delete-color-code {
        @extend .color-code_Style;
        background-color: $red;
        color: $white;
    }
    .no-scrollbars.wj-flexgrid [wj-part="root"] {
        overflow-y: hidden !important;
    }

    .align-erx-field {
        padding-top: 0.5rem;
    }

    .actions {
        margin: 0.2rem 0.25rem 0rem 0.25rem;
        color: $primary;
        border-radius: 50%;
        font-size: 1.25rem;
        cursor: pointer;
        &.archive-icon {
            color: $archive;
        }
        &.in-active{
            color:lightgrey !important;
            &:hover{
                color:lightgrey !important;
            }
        }
        &.danger-actve {
            color: red !important
        }
    }
    .del-actions {
        color: $primary;
        border-radius: 50%;
        font-size: 1.25rem;
        cursor: pointer;
        &.in-active{
            color:lightgrey !important;
            &:hover{
                color:lightgrey !important;
            }
        }
        &.danger-actve {
            color: red !important
        }
    }
    .fa-check,.fa-check-circle {
        color: $success;
    }
    .fa-uncheck, .fa-times-circle {
        color: $danger;
    }
    .fa-exclamation-triangle{
        color: $warning;
    }
    .actions:hover {
        color: $warning !important;
    }
    .erx-pageSize-select {
        margin-top: 0.75rem;
        margin-left: 0.25rem;
        text-align: center;
    }
    .content-not-found {
        text-align: center;
       // height: 7.8rem;
    }
    .text-display-center {
        font-family: $default-font-family;
        font-size: 1.42rem;
        margin-top: 2.85rem;
    }

    .pagination {
        padding-top: 0.35rem;
        ul{
            margin-bottom: unset;
        }
    }

    .secondary-search--grid {
        @extend %second-search-block;
        margin-bottom: 0.5rem;
    }
    %second-search-block {
        .input-group {
            @extend %search-block;
            .fa-search {
                top: 0.6rem !important;
            }
        }
    }
    %search-block {
        margin-top: 0rem;
        .search--icon {
            background-color: transparent !important;
            border: 0rem;
            .fa-search {
                color: $monsoon !important;
                top: 0.6rem;
                right: 1.7rem;
                position: relative;
                z-index: 9;
                width: 0rem;
            }
        }
        .search--input__box {
            background-color: $white;
            height: 2.6rem !important;
            border-radius: 0rem !important;
            border: 0.07rem solid $body-dark-back !important;
            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06) !important;
            }
            &.heightInline {
                height: 2.2rem !important;
            }
        }
    }
    .radiobuttons--block {
        padding-bottom: 0.5rem;
        .patient--wid {
            width: 20%;
            height: 2rem;
        }
        .prescriber--wid {
            width: 25%;
            height: 2rem;
        }
        .drug--wid {
            width: 12%;
            height: 2rem;
        }
    }
    .align-button-top__right {
        bottom: 32%;
        left: 77%;
        position: absolute;
    }

    .align--btn__width {
        width: 19rem;
        text-align: left;
        margin-bottom: 0.2rem;
    }

    .align--btn__width--small {
        width: 15rem !important;
        text-align: left;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 1200px;
    }

    .modal-xlarge .modal-lg {
        max-width: 1400px;
    }
   .modal-xxlarge  .modal-lg{
        max-width: 1701px;
        margin-top:6rem;
        left:7rem;
        min-height: 0px;
    }
    .modal-xxlargefitToReports  .modal-lg{
        max-width: 1360px;
        margin-top: 6rem;
        left: 6.7rem;
        min-height: 0px;
        position: relative;
    }
}

.export--button {
    margin-top: 0.35rem;
}

.show-transistion,
.hide-transistion {
    -webkit-transition: width 1s ease, height 1s ease;
    -moz-transition: width 1s ease, height 1s ease;
    -o-transition: width 1s ease, height 1s ease;
    transition: width 1s ease, height 1s ease;
}
.show-transistion {
    display: none;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.hide-transistion {
    display: block;
    opacity: 1;
}
.mis-match {
    color: #f6251a;
    font-weight: 900;
}
