.drug-verify--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 1, 2;
    $TabList: 1,2;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}



.drug-verify-body {
    .align--rph--label {
        width: 8.85rem;
    }

    .radio-height {
        height: 4.6rem;
    }

    .wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
        background-color: white
    }

    input[type="checkbox"] {
        display: inline;
    }

}
