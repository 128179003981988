/* Wijmo Control */

/* Primary wj control style - applies to all controls */
.wj-content {
    display: inline-block;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    box-sizing: border-box;
}

    /* set display: inline-table so height:100% works properly even without <!DOCTYPE html> tag */
    .wj-content.wj-dropdown,
    .wj-content.wj-inputnumber,
    .wj-content.wj-inputmask,
    .wj-content.wj-calendar-outer,
    .wj-content.wj-pager {
        display: inline-table;
    }

.wj-control {
    outline: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*
  Required to make contenteditable work on iOS
  http://stackoverflow.com/questions/21295698/html5-contenteditable-attribute-not-working-properly-on-ios7-mobile-safari
*/
.wj-control [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}


/* Define control header */
.wj-header {
    background-color: #EAEAEA;
    color: #444;
    font-weight: bold;
}

/* Selected state colors */
.wj-state-selected {
    background-color: #DDDDDD;
    color: #000;
}

.wj-state-multi-selected {
    background-color: #DDDDDD;
    color: #000;
}

/* Selected state colors */
.wj-state-focused .wj-state-multi-selected {
     background-color: #80ADBF;
     color: #fff;

}

.wj-state-focused .wj-state-multi-selected .card{
    color: #000;
 }

/* Disabled elements (via CSS) */
.wj-state-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

/* Disabled controls (via attribute) */
.wj-control[disabled] {
    opacity: .5;
    background-color: #eeeeee;
    pointer-events: none;
}

/* Button Styles */

/* Basic style for all button types */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    overflow: visible;
    border-radius: inherit;
    cursor: pointer;
}

/* Global base button style */
.wj-btn {
    /*display: block;*/
    padding: 0px 10px;
    height: 100%;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

/* Additional styling for default buttons */
.wj-btn-default {
    border: 1px solid rgba(0,0,0,0.2);
    background-color: transparent;
    color: inherit;
    /*font-weight: bold;*/
}

    /* Hover on 'default' button */
    .wj-btn-default:hover {
        background-color: rgba(0,0,0,0.1);
    }

    .wj-btn-default:focus {
        background-color: rgba(0,0,0,0.1);
    }

/* Link Buttons in Wijmo controls*/
.wj-control a[wj-part^="btn-"] {
    background: #e6e6e6;
    padding: 6px 20px;
    color: #444;
    display: inline-block;
    margin-top: 5px;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
}

    .wj-control a[wj-part^="btn-"]:hover {
        text-decoration: none;
        background: #e0e0e0;
    }

/* Button Group Styles */

/* Define button group based on bootstrap */
.wj-btn-group,
.wj-btn-group-vertical {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    vertical-align: top;
}

    /* Remove inner button border radius */
    .wj-btn-group > .wj-btn:not(:first-child):not(:last-child) {
        border-radius: 0;
    }

    .wj-btn-group > .wj-btn:first-child {
        margin-left: 0;
    }

        /* Remove first button right-side border radius and margin */
        .wj-btn-group > .wj-btn:first-child:not(:last-child) {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

    /* Remove last button left-side border radius and margin */
    .wj-btn-group > .wj-btn:last-child:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    /* Remove margin from inner buttons to prevent double border */
    .wj-btn-group .wj-btn + .wj-btn,
    .wj-btn-group .wj-btn + .wj-btn-group,
    .wj-btn-group .wj-btn-group + .wj-btn,
    .wj-btn-group .wj-btn-group + .wj-btn-group {
        margin-left: -1px;
    }

/* ListBox Styles */

.wj-listbox {
    overflow: auto;
    cursor: default;
}

.wj-listbox-item {
    box-sizing: border-box;
    padding: 3px 10px;
}

    .wj-listbox-item:focus,
    .wj-listbox-item input[type=checkbox]:focus {
        outline: none;
    }

    .wj-listbox-item.wj-separator {
        height: 1px;
        margin: 3px 0px;
        padding: 0px;
        background-color: rgba(0,0,0,.1);
    }

    .wj-listbox-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
        background-color: rgba(0,0,0,.05);
    }

.wj-listbox .wj-listbox-item label {
    font-weight: normal;
    margin: 0px;
}

.wj-dropdown .wj-listbox {
    padding: 0;
    white-space: nowrap;
}

.wj-listbox-item.wj-state-selected .wj-control {
    background: #fff;
    color: #444;
}

/* Dropdown Styles */

.wj-dropdown {
    vertical-align: middle;
}

    .wj-dropdown .wj-template,
    .wj-dropdown .wj-dropdown-menu {
        border-radius: inherit;
    }

.wj-template {
    height: 100%;
}

.wj-dropdown-panel {
    outline: none;
    box-shadow: 0 6px 13px rgba(0,0,0,0.2);
    z-index: 1500; /* Boostrap modals have zIndex 1050, so go higher */
}

.wj-autocomplete-match {
    font-weight: bold;
}

/* Input Group Styles */

/* Remove spinner buttons from InputNumber control */
.wj-inputnumber input[type=number]::-webkit-inner-spin-button,
.wj-inputnumber input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove clear icon from IE textbox */
.wj-control input::-ms-clear {
    display: none;
}

/* Remove default browser focus outlines */
.wj-control button,
.wj-control input,
.wj-control optgroup,
.wj-control select,
.wj-control textarea {
    outline: 0;
}

.wj-inputnumber,
.wj-inputmask {
    vertical-align: middle;
}

.wj-input-group-btn,
.wj-input-group .wj-form-control {
    box-sizing: border-box;
    display: table-cell;
}

.wj-input {
    height: 100%;
    overflow: hidden;
}

/* Define input group based on bootstrap */
.wj-input-group {
    position: relative;
    display: table;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 2em;
    border-collapse: separate;
    border-radius: 4px;
    background: inherit;
}

    /* Style for text input box in group */
    .wj-input-group .wj-form-control {
        position: relative;
        float: left;
        padding: 0 8px;
        min-height: 2em;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        background-color: transparent;
        color: inherit;
    }

        /* Text alignment for numeric input types */
        .wj-input-group .wj-form-control.wj-numeric {
            text-align: right;
        }

    /* Adjust float, width, and padding for Menu control */
    .wj-input-group div[wj-part='header'] {
        float: none;
        width: auto;
        vertical-align: middle;
        padding: 6px 8px;
    }

/* Remove border from outside of buttons - outer border set at wj-content level */
.wj-input-group-btn > .wj-btn {
    border-width: 0;
}

/* Remove first button right-side border radius */
.wj-input-group-btn:first-child > .wj-btn {
    border-right-width: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* Remove last button left-side border radius */
.wj-input-group-btn:last-child > .wj-btn {
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* RTL borders */

/* Remove first button left-side border radius */
[dir="rtl"] .wj-input-group-btn:first-child > .wj-btn {
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* Unset LTR border styles */
    border-right-width: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

/* Remove last button right-side border radius */
[dir="rtl"] .wj-input-group-btn:last-child > .wj-btn {
    border-right-width: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* Unset LTR border styles */
    border-left-width: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

/* Style for buttons in group */
.wj-input-group-btn {
    position: relative;
    width: 26px;
    height: 100%;
    vertical-align: top;
    white-space: nowrap;
}

/* Pager Styles */

.wj-pager {
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
}

    .wj-pager .wj-input-group-btn > .wj-btn {
        min-width: 40px;
    }

    .wj-pager .wj-btn[disabled] span {
        opacity: .5;
        cursor: default;
    }

    .wj-pager .wj-form-control {
        text-align: center;
        border-left: 1px solid rgba(0,0,0,0.2);
        border-right: 1px solid rgba(0,0,0,0.2);
    }

/* Calendar Date Input Styles */

.wj-calendar-outer {
    display: block;
    padding: 10px;
    width: 100%;
    height: auto;
    cursor: default;
}

.wj-calendar td {
    text-align: center;
}

/* In dropdown, set width to avoid flicker as the user navigates months (in IE only...) */
.wj-dropdown-panel.wj-calendar {
    width: 20em;
}

/* In dropdown, remove background and border */
.wj-dropdown-panel .wj-calendar-outer {
    border: 0;
    background: none;
}

.wj-day-today {
    font-weight: bold;
}

.wj-day-othermonth {
    opacity: 0.5;
}

/* -- Date selection -- */

/* Added padding to bottom of date selection options */
.wj-calendar-header {
    display: block;
    padding: 0 0 15px 0;
    width: 100%;
}

/* Float month selection to the left */
.wj-month-select {
    float: left;
    display: inline-block;
    cursor: pointer;
}

/* Float calendar button group to the right */
.wj-calendar-header .wj-btn-group {
    float: right;
    display: inline-block;
    margin-bottom: 7px;
}

    /* Size button group in calendar header */
    .wj-calendar-header .wj-btn-group .wj-btn {
        padding: 0 8px 1px 8px;
        min-height: 25px;
    }

/* -- Days of the month -- */

.wj-calendar-month {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    font: inherit;
}

    .wj-calendar-month td {
        width: 14.29%;
        padding: 5px 0;
        border: none;
    }

    /* Slightly reduce size of text in calendar header */
    .wj-calendar-month .wj-header {
        font-size: 90%;
    }

/* -- Months of the year -- */

.wj-calendar-year {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    font: inherit;
}

    .wj-calendar-year td {
        width: 25%;
        padding: 8px;
        border: none;
    }

/* -- FlexGrid -- */

.wj-flexgrid {
    cursor: default;
    width: 100%;
    overflow: hidden;
}
    .wj-flexgrid .wj-row {
        position: absolute; /* avoid flicker in Chrome: TFS 261344 */
        top: 0;
    }

.wj-cell {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 3px;
    border-right: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
    outline: none;
}

    /* Text color for nested controls in selected rows */
    .wj-cell.wj-state-selected .wj-control,
    .wj-cell.wj-state-multi-selected .wj-control {
        color: #444;
    }

/* Selected headers */
.wj-header.wj-state-multi-selected {
    background-color: #e4e4e4;
    color: #444;
    font-weight: bold;
}

/* Selected column headers */
.wj-colheaders .wj-header.wj-state-multi-selected {
    border-bottom: 2px solid #0085c7;
}

/* Selected row headers */
.wj-rowheaders .wj-header.wj-state-multi-selected {
    border-right: 2px solid #0085c7;
}

/* Sticky headers */
.wj-state-sticky .wj-header {
    opacity: 0.75;
}

/* Selection Marquee */
.wj-flexgrid .wj-marquee {
    position: absolute;
    box-sizing: border-box;
    border: 2px solid #0085c7;
}

/* Invalid state */
.wj-cell.wj-state-invalid {
    border: 1.2px solid red;
}
.wj-rowheaders .wj-cell.wj-state-invalid {
    background: rgba(255, 0, 0, 0.30)
}
.wj-cells .wj-cell.wj-state-invalid:after {
    position: absolute;
    content: '';
    width: 0;
    right: 0;
    top: -6px;
    border: 6px solid transparent;
    border-right: 6px solid red;
}

/* Cells with word-wrapping */
.wj-cell.wj-wrap {
    white-space: normal;
    text-overflow: clip;
}

/* Default grid cell color */
.wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background: #fff;
}

/* Alternate grid cell color */
.wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background: #f9f9f9;
}

/* Group row background */
.wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: #dddddd;
}

/* Frozen area boundaries */
.wj-cell.wj-frozen-row {
    border-bottom: 1px solid #666;
}
.wj-cell.wj-frozen-col {
    border-right: 1px solid #666;
}

/* Grid editor */
.wj-flexgrid .wj-grid-editor {
    box-sizing: border-box;
    padding: 3px;
    border: none;
    width: 100%;
    height: 100%;
    margin: 0px;
}
.wj-cell .wj-elem-dropdown {
    position: absolute;
    top: 0px;
    padding: 3px 6px;
    opacity: .25;
    right: 0;
}

[dir="rtl"] .wj-cell .wj-elem-dropdown {
    left: 0;
    right: auto;
}

/* Grid drag/resize row/col marker */
.wj-flexgrid .wj-marker {
    position: absolute;
    background-color: #0085c7;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
}

/* Row/Col header being dragged */
.wj-flexgrid .wj-cell.wj-header.wj-state-dragsrc {
    opacity: 0.5;
}

/* Switch cell borders in RTL grids */
[dir="rtl"] .wj-cell {
    border-left: 1px solid #c6c6c6;
    border-right: none;
}

/* Switch frozen borders in RTL grids */
[dir="rtl"] .wj-frozen-col {
    border-left: 1px solid #666;
    border-right: none;
}

/* FlexGrid Filter extension */

.wj-header .wj-elem-filter {
    float: right;
    cursor: pointer;
    padding: 0 4px;
}

[dir="rtl"] .wj-header .wj-elem-filter {
    float: left;
}

.wj-filter-on .wj-glyph-filter {
    opacity: 0.85;
}

.wj-filter-off .wj-glyph-filter {
    opacity: 0.25;
}

.wj-columnfiltereditor {
    padding: 10px;
    min-width: 230px;
    max-width: 400px;
}

    .wj-columnfiltereditor .wj-control {
        margin-bottom: 6px;
        width: 100%;
    }

    .wj-columnfiltereditor .wj-listbox .wj-listbox-item label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

/* FlexGrid GroupPanel extension */
.wj-grouppanel {
    display: block;
    background-color: #f4f4f4;
    padding: 15px;
}

    .wj-grouppanel .wj-cell {
        margin-right: 10px;
        padding: 6px 16px;
        border: 1px solid #e6e6e6;
        cursor: pointer;
    }

        .wj-grouppanel .wj-cell:hover {
            background: #e0e0e0;
        }

/* FlexGrid MultiRow extension */
.wj-multirow .wj-cell.wj-record-end:not(.wj-header) {
    border-bottom-color: #787878;
}

.wj-multirow .wj-cell.wj-group-end {
    border-right-color: #787878;
}

.wj-multirow .wj-hdr-collapse {
    position: absolute;
    padding: 6px;
    right: 0;
    top: 0;
    color: #0085c7;
    opacity: 0.5;
}

    .wj-multirow .wj-hdr-collapse:hover {
        opacity: 1;
    }

/* FlexSheet extension */
.wj-flexsheet .wj-marquee {
    position: absolute;
    box-sizing: border-box;
    border: 2px solid #0085c7;
}

.wj-flexsheet .wj-state-multi-selected {
    background: #e6e6e6;
    color: #222;
}

.wj-flexsheet .wj-cell.wj-state-multi-selected {
    border-right: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
}

/* Tooltip */

.wj-tooltip {
    position: absolute;
    z-index: 1000;
    top: 0px;
    left: 0px;
    pointer-events: none;
    max-width: 400px;
    padding: 6px;
    background-color: #ffffe5;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-sizing: border-box;
}

/* FlexChart */

.wj-flexchart {
    height: 400px;
    margin-bottom: 12px;
    display: block;
    padding: 8px;
}

    .wj-flexchart .wj-title {
        font-weight: bold;
    }

    .wj-flexchart .wj-header {
        font-size: 18pt;
        fill: #80044d;
    }

        .wj-flexchart .wj-header .wj-title {
            font-size: 16pt;
            fill: #17a2b8;
            font-weight: normal;
            padding-bottom: 10px;
        }

    .wj-flexchart .wj-footer {
    }

        .wj-flexchart .wj-footer .wj-title {
            fill: #666;
            font-weight: normal;
        }

    .wj-flexchart .wj-plot-area {
    }

    .wj-flexchart .wj-legend .wj-label {
        fill: #666;
    }

    .wj-flexchart .wj-data-label {
        fill: #666;
    }

    .wj-flexchart .wj-data-label-border {
        stroke: rgba(128,128,128,0.5);
    }

    .wj-flexchart .wj-data-label-line {
        stroke: rgba(128,128,128,1);
    }

    .wj-flexchart .wj-axis-x .wj-title,
    .wj-flexchart .wj-axis-y .wj-title {
        font-style: italic;
    }

    /* style for selected items on the chart */
    .wj-flexchart .wj-state-selected {
        stroke-width: 3px;
        stroke-dasharray: 6;
        stroke-linecap: square;
    }

    .wj-flexchart .fibonacci-arcs path.wj-state-selected {
        stroke-dasharray: 5 10;
        stroke-linecap: butt;
    }

.wj-treemap .wj-data-label {
    fill: #fff;
}

/* style for selected items on the chart - smaller screens */
@media(max-width: 1025px) {
    .wj-flexchart .wj-state-selected {
        stroke-width: 2px;
        stroke-dasharray: 4;
    }
}

@media(max-width: 767px) {
    wj-flexchart .wj-state-selected {
        stroke-width: 1px;
        stroke-dasharray: 3;
    }
}

/* Axes */

    /* labels */
    .wj-flexchart .wj-label {
        fill: #666;
    }

    /* bottom line */
    .wj-flexchart .wj-axis-x .wj-line {
        stroke: #aaa;
        stroke-width: 1px;
    }

    /* major/minor ticks */
    .wj-flexchart .wj-tick,
    .wj-flexchart .wj-tick-minor {
        stroke: #aaa;
        stroke-width: 1px;
    }

    /* major grid lines */
    .wj-flexchart .wj-gridline {
        stroke: #666;
        stroke-width: 1px;
        opacity: .75;
    }

    /* minor grid lines */
    .wj-flexchart .wj-gridline-minor {
        stroke: #666;
        stroke-width: 1px;
        opacity: .25;
    }

/****  Range Slider Css *****/

.wj-flexchart .wj-chart-rangeslider {
    position: absolute;
    touch-action: none;
    -ms-touch-action: none;
}

    .wj-flexchart .wj-chart-rangeslider button {
        position: absolute;
        text-align: center;
        vertical-align: middle;
        padding: 0px;
        line-height: 16px;
        border-radius: 2px;
    }

.wj-flexchart .wj-chart-hrangeslider button {
    width: 16px;
    height: 100%;
}

.wj-flexchart .wj-chart-vrangeslider button {
    height: 16px;
    width: 100%;
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-content {
    background-color: #eaeaea;
    height: 100%;
    position: relative;
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-rangehandle {
    position: absolute;
    text-align: center;
    vertical-align: middle;
    background-color: #BDBDBD;
    height: 100%;
    width: 100%;
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-maxhandle {
    border: 1px solid Gray;
    display: block;
    position: absolute;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
    background-color: #BDBDBD;
    cursor: ew-resize;
    height: 22px;
    margin-top: -2px;
    width: 14px;
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-minhandle,
.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
    background-color: #BDBDBD;
    cursor: ns-resize;
    width: 22px;
    margin-left: -2px;
    height: 14px;
}

.wj-flexchart .wj-chart-rangeslider .wj-rangeslider-handle-active {
    z-index: 2;
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-decbtn {
    left: 0px;
}

.wj-flexchart .wj-chart-hrangeslider .wj-rangeslider-incbtn {
    right: 0px;
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-decbtn {
    bottom: 0px;
}

.wj-flexchart .wj-chart-vrangeslider .wj-rangeslider-incbtn {
    top: 0px;
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-left {
    border-top: 5px solid transparent;
    border-right: 4px solid;
    border-bottom: 4px solid transparent;
    margin-right: 2px;
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-right {
    border-bottom: 5px solid transparent;
    border-left: 4px solid;
    border-top: 4px solid transparent;
    margin-left: 2px;
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-down {
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-bottom: 3px;
}

.wj-flexchart .wj-chart-rangeslider .wj-glyph-up {
    border-right: 4px solid transparent;
    border-bottom: 5px solid;
    border-left: 4px solid transparent;
    margin-bottom: 4px;
}

/****  Range Selector Css *****/
.wj-flexchart .wj-chart-rangeselector-container {
    position: relative;
}

    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider,
    .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-content {
        background-color: transparent;
        border-color: transparent;
    }

    .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-rangehandle {
        opacity: 0.3;
    }

    .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-minhandle,
    .wj-flexchart .wj-chart-rangeselector-container .wj-rangeslider-maxhandle {
        background-color: transparent;
        opacity: 0.6;
        border: 2px solid Gray;
        border-radius: 0.5em;
    }

    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle,
    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid hsla(0,0%,50.2%,0.75);
        top: 0;
        bottom: 0;
        margin: auto;
        color: hsl(0,0%,50.2%);
        background: #d3d3d3;
        opacity: 1;
    }

        .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-minhandle:after,
        .wj-flexchart .wj-chart-rangeselector-container .wj-chart-hrangeslider .wj-rangeslider-maxhandle:after {
            content: "\2551";
            text-align: center;
            width: 100%;
            display: inline-block;
            position: absolute;
            margin: 0;
            top: 50%;
            transform: translateY(-55%);
            -webkit-transform: translateY(-55%);
            opacity: 0.75;
            font-size: 10px;
        }

    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle,
    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle {
        height: 20px;
        width: 20px;
        left: 50%;
        border-radius: 50%;
        border: 1px solid hsla(0,0%,50.2%,0.75);
        top: 0;
        bottom: 0;
        color: hsl(0,0%,50.2%);
        background: #d3d3d3;
        opacity: 1;
    }

        .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-minhandle:after,
        .wj-flexchart .wj-chart-rangeselector-container .wj-chart-vrangeslider .wj-rangeslider-maxhandle:after {
            content: "\2550";
            text-align: center;
            height: 100%;
            display: inline-block;
            position: absolute;
            margin: 0;
            left: 50%;
            transform: translate(-50%,15%);
            -webkit-transform: translate(-50%,15%);
            opacity: 0.75;
            font-size: 12px;
        }

    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-minhandle.wj-rangeslider-handle-active,
    .wj-flexchart .wj-chart-rangeselector-container .wj-chart-rangeslider .wj-rangeslider-maxhandle.wj-rangeslider-handle-active {
        background-color: rgba(136,189,230,0.7);
    }

.wj-flexchart .wj-rangeselector .wj-scroller-center {
    background: rgba(128,128,128,0.1);
    position: absolute;
    display: block;
    touch-action: none;
}

/* Chart Gestures Css */
.wj-flexchart .wj-zoom {
    visibility: hidden;
    position: relative;
}

.wj-flexchart .wj-zoom-overlay {
    background: rgba(128,128,128,0.2);
    position: absolute;
    display: block;
    touch-action: none;
}

.wj-flexchart.wj-panable {
    cursor: pointer;
}

.wj-flexchart .wj-block-other-interaction {
    display: none;
}

/* chart marker */
.wj-flexchart-touch-disabled {
    -ms-touch-action: none;
}

.wj-flexchart .wj-chart-linemarker {
    background: rgba(128,128,128,0.3);
    position: absolute;
    padding: 2px;
}

.wj-flexchart .wj-chart-linemarker-container {
    position: relative;
}

.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-hline {
    height: 2px;
    background: rgba(128,128,128,1);
    touch-action: none;
    position: absolute;
}

.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-vline {
    width: 2px;
    background: rgba(128,128,128,1);
    touch-action: none;
    position: absolute;
}

.wj-flexchart.wj-chart-linemarker-draggable,
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-draggable {
    -khtml-user-drag: element;
    cursor: move;
}

/* Gauges */

.wj-gauge:focus {
    outline: none;
}

.wj-gauge {
    display: block;
}

.wj-gauge .wj-face path {
    fill: #f5f5f5;
    stroke: #cbcbcb;
    stroke-width: 1px;
    stroke-linejoin: round;
}

.wj-gauge .wj-pointer {
    fill: #0085c7;
}

.wj-gauge .wj-ticks {
    stroke-width: 2px;
    stroke: white;
}

.wj-gauge .wj-min,
.wj-gauge .wj-max {
    font-size: 60%;
    opacity: .5;
}

.wj-radialgauge .wj-value {
    font-size: 150%;
}

.wj-lineargauge {
    height: 1.2em;
}

/* ColorPicker */

.wj-colorpicker {
    width: 420px;
    height: 200px;
    padding: 4px;
}

.wj-colorbox {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* Popup */

.wj-popup {
    background-color: white;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    z-index: 1500;
    margin: 2px 0px;
}

.wj-popup-backdrop {
    background-color: black;
    opacity: .5;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1500;
}

/**************************************************************************
    Glyphs
*/

.wj-glyph-up {
    position: relative;
    display: inline-block;
    border-right: .4em solid transparent;
    border-bottom: .5em solid;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-down {
    position: relative;
    display: inline-block;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-left,
.wj-glyph-step-backward {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-step-backward {
    transform: translateX(.12em);
}
    .wj-glyph-step-backward:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translate(-100%,-50%);
        content: "";
    }

.wj-glyph-right,
.wj-glyph-step-forward {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-step-forward:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translateY(-50%);
        content: "";
    }

.wj-glyph-left-rtl,
.wj-glyph-step-backward-rtl {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-step-backward-rtl:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translate(100%, -50%);
        content: "";
    }

.wj-glyph-right-rtl,
.wj-glyph-step-forward-rtl {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-step-forward-rtl {
    transform: translateX(.12em);
}
    .wj-glyph-step-forward-rtl:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translateY(-50%);
        content: "";
    }

.wj-glyph-down-left {
    position: relative;
    display: inline-block;
    border-top: .65em solid transparent;
    border-left: .65em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-down-right {
    position: relative;
    display: inline-block;
    border-bottom: .65em solid;
    border-left: .65em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-up-left {
    position: relative;
    display: inline-block;
    border-bottom: .65em solid transparent;
    border-left: .65em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-up-right {
    position: relative;
    display: inline-block;
    border-top: .65em solid;
    border-left: .65em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-circle {
    position: relative;
    display: inline-block;
    border: .25em solid;
    border-radius: 1em;
    transform: translateY(-.1em);
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-square {
    position: relative;
    display: inline-block;
    border: .25em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-diamond {
    position: relative;
    display: inline-block;
    border: .25em solid;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-check {
    position: relative;
    display: inline-block;
    width: .75em;
    height: .75em;
    border-right: .3em solid;
    border-bottom: .22em solid;
    transform: rotate(35deg) scaleX(.5);
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-filter {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    top: -.1em;
    width: .5em;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-filter:after {
        position: absolute;
        box-sizing: border-box;
        border-left: .2em solid;
        height: .4em;
        transform: translateX(-50%);
        top: -.2em;
        content: "";
    }

[dir="rtl"] .wj-glyph-filter:after {
    transform: translateX(50%);
}

.wj-glyph-plus,
.wj-glyph-minus {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    border-top: .25em solid;
    width: .9em;
    top: -.2em;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-plus:after {
        position: absolute;
        box-sizing: border-box;
        border-left: .25em solid;
        width: .25em;
        height: .85em;
        left: .32em;
        top: -.55em;
        content: "";
    }

.wj-glyph-file {
    position: relative;
    display: inline-block;
    border-left: .7em solid;
    height: .85em;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-file:after {
        position: absolute;
        transform: translateX(-100%);
        border-top: .3em solid transparent;
        border-left: .3em solid white;
        content: "";
    }

.wj-glyph-calendar {
    position: relative;
    display: inline-block;
    top: .2em;
    width: 1em;
    height: 1em;
    border: .1em solid;
    border-top: .3em solid;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-calendar:after {
        position: absolute;
        font-size: 50%;
        top: -.15em;
        left: .4em;
        content: '1';
    }

.wj-glyph-clock {
    position: relative;
    display: inline-block;
    top: .2em;
    width: 1em;
    height: 1em;
    border: .13em solid;
    border-radius: 50%;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-clock:after {
        position: absolute;
        border-left: .1em solid;
        border-bottom: .1em solid;
        width: .3em;
        height: .4em;
        top: .05em;
        left: .32em;
        content: "";
    }

.wj-glyph-pencil {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.75;
}
    .wj-glyph-pencil:after {
        position: absolute;
        left: 0;
        content: "\270E";
    }

.wj-glyph-asterisk {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.75;
}
    .wj-glyph-asterisk:after {
        position: absolute;
        left: 0;
        content: "\2731";
    }


/**************************************************************************
    wijmo.grid.sheet module
*/
.wj-flexsheet .wj-content {
    outline: none;
    border-color: #ccc;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.wj-flexsheet-formula-list {
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid rgba(0,0,0,.2);
    font-family: arial,sans-serif;
    font-size: 12px;
    line-height: 22px;
    position: absolute;
    width: 300px;
    z-index: 2001;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 4px rgba(0,0,0,.2);
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
}

.wj-flexsheet-formula-name {
    color: #222;
    font-size: 13px;
    font-family: inconsolata,monospace,arial,sans,sans-serif;
    margin: -2px 0;
}

.wj-flexsheet-formula-description {
    color: #666;
    display: block;
    font-size: 11px;
    margin: -2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wj-state-selected .wj-flexsheet-formula-description {
    color: #fff;
}

/* Hides filter icons in column headers */
.wj-flexsheet div[wj-filter] {
    display: none;
}

div[wj-part='tab-holder'] {
    background: #b8b8b8;
}

span.wj-sheet-icon {
    margin: 0px 14px;
}

div.wj-sheet-page {
    padding-top: 1px;
}

    div.wj-sheet-page > button {
        padding: 0px;
        padding-top: 1px;
        border-radius: 0;
        border: 0;
        background-color: #e4e5e8;
        color: #727275;
    }

        div.wj-sheet-page > button:hover {
            background-color: #dee0e3;
        }

.wj-sheet-tab ul {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    float: left;
}

    .wj-sheet-tab ul li {
        float: left;
        display: block;
        padding-right: 20px;
        padding-left: 20px;
        cursor: pointer;
        margin-top: 1px;
        border-left: 1px solid #b8b8b8;
        min-height: 20px;
        background-color: #d2d3d8;
        text-align: center;
        padding-top: 2px;
        font-size: 11px;
        color: #727275;
    }

        .wj-sheet-tab ul li.active {
            border-top-color: transparent;
            background-color: white;
            cursor: default;
            height: 100%;
            margin-top: 1px;
            border-left: 1px solid #b8b8b8;
            font-weight: bold;
        }

        .wj-sheet-tab ul li.hidden {
            display: none;
        }

        .wj-sheet-tab ul li:not(.active):hover,
        .wj-sheet-tab ul li:not(.hidden):hover {
            background-color: #aabcd6;
            color: #fff;
            cursor: pointer;
        }

        .wj-sheet-tab ul li.wj-new-sheet:hover {
            background-color: #0085c7;
        }

.wj-new-sheet {
    padding: 0;
    width: 32px;
    height: 20px;
    font-size: 11px;
    text-align: center;
    background-color: #668eb9;
    color: #fff;
}

    .wj-new-sheet .wj-sheet-icon {
        color: #727275;
        margin-top: 5px;
    }

div.wj-sheet > div {
    display: block;
    float: left;
}

[dir="rtl"] div.wj-sheet > div {
    display: block;
    float: right;
}

.wj-flexsheet {
    background-color: #a9a9a9;
    width: 100%;
    height: 100%;
}

.wj-context-menu {
    background-color: #fff;
    border: thin solid #808080;
    cursor: default;
    padding: 3px 0px;
}

.wj-context-menu-item {
    padding: 3px 10px;
}

    .wj-context-menu-item:not(.wj-context-menu-item-selected):hover {
        background-color: rgba(0,0,0,.1);
    }

.wj-context-menu-item-selected {
    background-color: #0085c7;
    color: #fff;
}

.wj-header-row {
    background-color: #96abb4 !important;
    color: #fff !important;
}

/**************************************************************************
    wijmo.olap module
*/

/* PivotPanel */
.wj-pivotpanel {
    position: relative;
    padding: 3px 6px;
    overflow: auto;
    min-height: 25em;
    width: 100%;
}
    .wj-pivotpanel a {
        float: right;
        margin: 6px;
    }
    .wj-pivotpanel table {
        table-layout: fixed;
        width: 100%;
    }
    .wj-pivotpanel div {
        width: 100%;
    }
    .wj-pivotpanel tr,
    .wj-pivotpanel td {
        border: 1px none #e0e0e0;
        padding: 3px;
    }
    .wj-pivotpanel label {
        font-weight: bold;
        margin: 0;
    }
    .wj-pivotpanel .wj-flexgrid {
        flex-grow: 1;
        border: none;
        border-radius: 0;
        min-height: 8em;
        max-height: 20em;
        margin-left: 6px;
    }
    .wj-pivotpanel table .wj-flexgrid {
        min-height: 5em;
        height: 5em;
    }
    .wj-pivotpanel .wj-marker {
        position: absolute;
        background-color: #0085c7;
        opacity: 0.5;
        pointer-events: none;
    }
    .wj-pivotpanel .wj-flexgrid .wj-cell:not(.wj-header) {
        border: none;
        background: inherit !important;
        color: inherit !important;
    }
        .wj-pivotpanel .wj-flexgrid .wj-cell:not(.wj-header):hover {
            background: rgba(0, 0, 0, 0.03) !important;
            border-radius: 4px;
            transition: all linear 300ms;
        }
    .wj-pivotpanel .wj-flexgrid .wj-cell.wj-header {
        font-weight: bold;
        color: inherit;
        background: rgba(0, 0, 0, 0.05);
        border: none;
    }
    .wj-pivotpanel .wj-flexgrid .wj-glyph-filter {
        cursor: default;
        opacity: 0.5;
    }
    .wj-pivotpanel .wj-flexgrid .wj-aggregate {
        font-size: 80%;
        opacity: 0.5;
    }
    .wj-pivotpanel .wj-flexgrid label {
        font-weight: normal;
        margin: 0;
    }

/* _ListContextMenu */
.context-menu {
    font-size: 90%;
    padding: 6px;
}

.menu-icon {
    display: inline-block;
    width: 1em;
    margin-right: 6px;
    opacity: .75;
    color: #0000C0;
}

    .menu-icon.menu-icon-remove {
        color: #800000;
        font-weight: bold;
    }

/* PivotFieldEditor */
.wj-pivotfieldeditor {
    min-width: 400px;
}

    .wj-pivotfieldeditor tr.wj-separator {
        border-top: 10px solid transparent;
    }

    .wj-pivotfieldeditor td:first-child {
        text-align: right;
    }

/* PivotFilterEditor */
.wj-pivotfiltereditor {
    padding: 10px;
    min-width: 230px;
}

    .wj-pivotfiltereditor .wj-control {
        margin-bottom: 6px;
        width: 100%;
    }

    .wj-pivotfiltereditor .wj-listbox .wj-listbox-item label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

/* PivotGrid */
.wj-pivotgrid .wj-colheaders .wj-cell.wj-header {
    text-align: center;
}

.wj-pivotgrid .wj-topleft .wj-cell.wj-col-field-hdr {
    text-align: right;
}

.wj-pivotgrid .wj-cell.wj-aggregate {
    font-weight: bold;
}

.wj-pivotgrid .wj-aggregate.wj-cell:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: rgba(0,133,199,0.15); /* slightly highlight scrollable totals cells */
}

/* PivotChart */
.wj-pivotchart {
    position: relative;
}

.wj-pivotchart .wj-dropdown {
    position: absolute;
    margin-right: 15px;
    z-index: 999;
}

.wj-pivotchart .wj-flexchart {
    padding: 35px 15px 10px;
}

/* dialogs */
.wj-detaildialog .wj-flexgrid {
    width: 60vw;
    height: 40vh;
    box-sizing: border-box;
}

.wj-dialog-header {
    width: 100%;
    background: rgba(0,0,0,0.05);
    font-weight: bold;
    font-size: 120%;
    padding: 1em;
    box-sizing: border-box;
}

.wj-dialog-body {
    padding: 1em;
    box-sizing: border-box;
}

.wj-dialog-footer {
    text-align: right;
    margin-top: 1em;
    padding: 1em;
    box-sizing: border-box;
}

/**** TreeView styles ****/

.wj-treeview {
    width: 100%;
    overflow: auto;
    background: transparent;
    border: none;
}
    .wj-treeview *:focus {
        outline: none;
    }
    .wj-treeview.wj-state-loading * {
        cursor: wait !important;
    }
    .wj-treeview .wj-node {
        cursor: pointer;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 3px 10px; /* as in .wj-listbox-item */
    }
        .wj-treeview .wj-node.wj-state-selected {
            color: inherit;
            background: rgba(0, 0, 0, 0.05);
        }
        .wj-treeview .wj-node img {
            padding-right: 4px;
        }
        .wj-treeview .wj-node .wj-node-check {
            margin-right: 4px;
        }
        .wj-treeview .wj-node:before {
            content: "";
            position: relative;
            display: inline-block;
            border-top: .5em solid;
            border-right: .45em solid transparent;
            border-left: .45em solid transparent;
            top: -1px;
            margin-right: 4px;
            opacity: 0.75;
            transition: all .3s cubic-bezier(.4,0,.2,1);
        }
            .wj-treeview .wj-node.wj-state-collapsed:before,
            .wj-treeview .wj-node.wj-state-collapsing:before {
                transform: rotate(-90deg);
                transition: all .3s cubic-bezier(.4,0,.2,1);
            }
            .wj-treeview .wj-nodelist .wj-node.wj-state-empty:before {
                visibility: hidden;
            }
.wj-treeview .wj-nodelist {
    display: block;
    position: relative;
}
    .wj-treeview .wj-nodelist .wj-nodelist {
        padding-left: 1em;
    }
.wj-treeview .wj-node.wj-state-collapsed + .wj-nodelist {
    display: none;
}

/* TreeView drag/drop marker */
.wj-treeview .wj-marker {
    position: absolute;
    background-color: #0085c7;
    opacity: 0.5;
    pointer-events: none;
}

/* TreeView lazy-load animation */
.wj-treeview .wj-node.wj-state-collapsed.wj-state-loading:before {
    animation-name: wjLazyNodeLoading;
    animation-duration: .6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}
@keyframes wjLazyNodeLoading {
    from {
        opacity: 1;
        transform: scale(1.1);
    }
    to {
        opacity: 0.5;
        transform: scale(0.5);
    }
}

/* TreeView RTL support */
[dir="rtl"] .wj-nodelist .wj-nodelist {
    padding-left: unset;
    padding-right: 1em;
}
[dir="rtl"] .wj-node img {
    padding-right: unset;
    padding-left: 4px;
}
[dir="rtl"] .wj-node .wj-node-check {
    margin-right: unset;
    margin-left: 4px;
}
[dir="rtl"] .wj-nodelist .wj-node:before {
    margin-right: unset;
    margin-left: 4px;
}
[dir="rtl"] .wj-nodelist .wj-node.wj-state-collapsed:before,
[dir="rtl"] .wj-nodelist .wj-node.wj-state-collapsing:before {
    transform: rotate(+90deg);
}

/**** MultiAutoComplete styles ****/

.wj-multi-autocomplete {
    width: 100%;
    height: auto;
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    margin-bottom: 10px;
}
    .wj-multi-autocomplete .wj-input {
        float: left;
    }
    .wj-multi-autocomplete .wj-form-control {
        padding: 10px;
    }
    .wj-multi-autocomplete .wj-token {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        display: inline-block;
        float: left;
        border: 1px solid #d9d9d9;
        background-color: #ededed;
        white-space: nowrap;
        margin: 5px 5px 5px 5px;
        vertical-align: middle;
        cursor: default;
    }
        .wj-multi-autocomplete .wj-token .wj-token-label {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px 5px 5px 10px;
            vertical-align: middle;
            max-width: 500px;
        }
        .wj-multi-autocomplete .wj-token:hover {
            border-color: #b9b9b9;
        }
        .wj-multi-autocomplete .wj-token.wj-token-active {
            border-color: #febbbb;
        }
    .wj-multi-autocomplete .wj-token-helper {
        position: absolute;
        left: -10000px;
    }
    .wj-multi-autocomplete .wj-token .wj-token-close {
        font-family: Arial;
        display: inline-block;
        line-height: 100%;
        font-size: 1.1em;
        line-height: 1.49em;
        margin-left: 5px;
        float: none;
        height: 100%;
        vertical-align: middle;
        padding-right: 4px;
        cursor: pointer;
    }
        .wj-multi-autocomplete .wj-token .wj-token-close:hover,
        .wj-multi-autocomplete .wj-token .wj-token-close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
            opacity: .5;
        }

/* Viewer */
.wj-viewer-outer {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding-top: 50px;
}

.wj-viewer-splitter { /* Disable selection to fix #275380 */
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .wj-viewer-outer.mobile.with-footer,
    .wj-viewer-outer.mobile.with-searchbar {
        padding-bottom: 50px;
    }

    .wj-viewer-outer.mobile.with-footer.with-searchbar {
        padding-bottom: 100px;
    }

    .wj-viewer-outer .mobile {
        visibility: hidden;
    }

    .wj-viewer-outer.mobile .wj-toolbar {
        visibility: hidden;
        display: none;
    }

    .wj-viewer-outer.mobile .wj-viewer-splitter {
        visibility: hidden;
        display: none;
    }

    .wj-viewer-outer.mobile .mobile {
        visibility: visible;
    }

    .wj-viewer-outer.mobile .wj-toolbar.mobile {
        display: block;
    }

    .wj-viewer-outer.mobile .wj-toolbar.mobile.hidden {
        display: none;
    }

.wj-viewer {
    height: 750px;
    width: 1100px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

    .wj-viewer.full-screen {
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        display: block;
        position: fixed !important;
        z-index: 1200;
    }

    .wj-viewer.full-screen .wj-viewer-outer {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        padding-top: 0px;
    }

    .wj-viewer.full-screen .wj-viewer-outer.mobile {
        padding-top: 50px;
    }

body.full-screen {
    overflow: hidden;
}

    .wj-viewer.wj-control {
        -webkit-user-select: inherit;
        -ms-user-select: inherit;
        -moz-user-select: inherit;
    }

.wj-toolbarcontainer {
    float: left;
    height: 100%;
    overflow: hidden;
}

.wj-toolbarwrapper {
    padding-top: 5px;
    height: 100%;
}

.wj-toolbar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #c8c8c8;
    box-sizing: border-box;
    position: absolute;
    margin-top: -50px;
}

    .wj-viewer .wj-toolbarwrapper.wj-btn-group > .wj-btn {
        padding-left: 0px;
        padding-right: 0px;
        height: 40px;
        box-sizing: border-box;
        position: relative;
        float: left;
    }

    .wj-viewer .wj-toolbar .hidden {
        display: none;
    }

    .wj-viewer.full-screen .wj-toolbar {
        display: none;
    }

    .wj-viewer.full-screen .wj-viewer-outer.mobile .wj-toolbar {
        display: block;
    }

    .wj-viewer.full-screen .wj-viewer-outer.mobile .wj-searchbar.hidden {
        display: none;
    }

        .wj-toolbar a:hover, .wj-toolbar a:focus {
            background-color: #eee;
            outline: none;
        }

        .wj-toolbar a:hover.disabled, .wj-toolbar a:focus.disabled, .wj-toolbar a:hover.checked, .wj-toolbar a:focus.checked {
            background-color: transparent;
            cursor: default;
        }

    .wj-toolbar .wj-separator {
        height: 33px;
        width: 10px;
        float: left;
        border-right: 2px solid black;
        margin: 3px 10px 0 0;
        opacity: 0.25;
    }

    .wj-toolbar .wj-pagenumber {
        margin: 4px 10px;
        height: 30px;
        width: 80px;
        float: left;
    }

    .mobile .wj-toolbar .wj-pagenumber {
        width: 50px;
    }

    .wj-toolbar .wj-pagecount {
        margin: 5px 10px;
        height: 30px;
        line-height: 30px;
        float: left;
    }

    .mobile .wj-toolbar .wj-pagecount {
        margin: 5px 7px;
    }

    .wj-toolbar .wj-input-zoom {
        margin: 4px 10px;
        height: 30px;
        width: 100px;
        float: left;
    }

    .wj-toolbar .slash {
        float: left;
        height: 40px;
        width: 10px;
        line-height: 40px;
    }

    .wj-toolbar-move {
        width: 12px;
        height: 100%;
        cursor: pointer;
        visibility: hidden;
    }

.wj-viewer-outer.mobile .wj-toolbar .wj-toolbar-move {
    visibility: hidden;
    display: none;
}

@media print {
    .wj-toolbar-move {
        visibility: hidden;
        display: none;
    }
}

        .wj-toolbar-move.left {
            float: left;
        }

        .wj-toolbar-move.right {
            float: right;
        }

        .wj-toolbar-move .wj-glyph-left,
        .wj-toolbar-move .wj-glyph-right {
            margin-top: 23px;
            margin-left: 2px;
        }

    .wj-toolbar:hover .wj-toolbar-move.enabled {
        opacity: 1;
    }
    .wj-toolbar-move:hover.enabled {
        background-color: #f0f0f0;
        opacity: 1;
    }

    .wj-toolbar-move {
        opacity: 0.2;
    }

.wj-mini-toolbar {
    position: absolute;
    vertical-align: bottom;
    border: none;
    bottom: 50px;
    width: 460px;
    background-color: #303030;
    visibility: hidden;
    opacity: 0.8;
}

    .wj-viewer.full-screen .wj-mini-toolbar {
        visibility: visible;
        display: inline-block;
    }

    .wj-viewer.full-screen .wj-viewer-outer.mobile .wj-mini-toolbar {
        visibility: hidden;
        display: none;
    }

    .wj-mini-toolbar a:focus {
        background-color: transparent;
    }

    .wj-mini-toolbar .slash,
    .wj-mini-toolbar .wj-pagecount {
        color: #e0e0e0;
    }

    .wj-mini-toolbar .wj-pagenumber {
        color: #e0e0e0;
        background-color: #202020;
    }

    .wj-mini-toolbar .wj-toolbar-move {
        visibility: hidden;
    }

    .wj-mini-toolbar .wj-svg-btn {
        fill: #ffffff;
        opacity: 1;
    }

    .wj-mini-toolbar .wj-btn:hover {
        background-color: #606060 !important;
    }

.wj-viewer-container {
    height: 100%;
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
}

@media print {
    .wj-viewer-container {
        white-space: nowrap;
    }
}

    .wj-viewer.full-screen .wj-viewer-splitter {
        display: none;
    }

    .wj-viewer.narrow .wj-viewer-splitter {
        display: none;
        visibility: hidden;
    }

    .wj-viewer-container .wj-viewer-splitter {
        float: left;
        height: 100%;
        cursor: pointer;
    }
        .wj-viewer-container .wj-viewer-splitter button {
            padding: 0 2px;
            background-color: #fafafa;
            border-left: 1px solid #c8c8c8;
            border-right: 1px solid #c8c8c8;
            border-top: none;
            border-bottom: none;
        }

            .wj-viewer-container .wj-viewer-splitter button:hover {
                background-color: #dadada;
            }

        .wj-viewer-container .wj-viewer-splitter span {
            text-align: center;
        }

.wj-viewer-leftpanel {
    float: left;
    height: 100%;
}

    .mobile .wj-viewer-leftpanel {
        width: 0px;
    }

    .wj-viewer.full-screen .wj-viewer-leftpanel {
        display: none;
    }

    .wj-viewer.full-screen .wj-viewer-outer.mobile .wj-viewer-leftpanel {
        display: block;
    }

    .wj-viewer.narrow .wj-viewer-leftpanel {
        display: none;
        visibility: hidden;
    }

.wj-nav {
    padding-left: 0;
    list-style: none;
    float: left;
    border-bottom: 0;
    margin-bottom: -1px;
    box-sizing: border-box;
}

.wj-nav > li {
    position: relative;
    display: block;
    float: none;
    margin: 0;
}

.wj-nav > li.hidden{
    display: none;
}

    .wj-nav > li > a {
        position: relative;
        display: block;
        margin-right: 0;
        border: 0;
        border-right: 1px solid #c8c8c8;
        background-repeat: no-repeat;
        background-position: center center;
        width: 48px;
        height: 48px;
        outline: none;
        line-height: 1.42857143;
        border-radius: 0;
        border-width: 0;
        border-color: transparent;
        padding-top: 12px;
        box-sizing: border-box;
    }

    .wj-nav > li > a:hover,
    .wj-nav > li > a:focus {
        text-decoration: none;
        background-color: #eee;
    }

    .wj-nav > li > a .wj-arrow {
        display: none;
    }

        .wj-nav > li > a .wj-arrow .wj-insidearrow {
            position: absolute;
            top: 17px;
            z-index: 1000;
            right: -1px;
            height: 0px;
            width: 0px;
            border: 7px solid transparent;
            border-right-color: #fafafa;
        }

        .wj-nav > li > a .wj-arrow .wj-outsidearrow {
            position: absolute;
            top: 16px;
            right: -1px;
            height: 0px;
            width: 0px;
            border: 8px solid transparent;
            border-right-color: #c8c8c8;
        }

    .wj-nav > li.active > a, .wj-nav > li.active > a:hover, .wj-nav > li.active > a:focus {
        border: 0;
    }

.mobile .wj-nav {
    display: none;
    visibility: hidden;
}

.wj-viewer-tabsleft {
    height: 100%;
    position: relative;
}

    .wj-viewer-outer.mobile .wj-viewer-tabsleft {
        width: 100%;
        position: absolute;
        top: 0px;
        z-index: 1000;
    }

    .wj-viewer-outer.mobile .wj-viewer-tabsleft.collapsed {
        width: 0;
    }

    .wj-viewer-outer.mobile .wj-tabcontent {
        width: 100%;
    }

    .wj-viewer-tabsleft.collapsed .wj-tabcontent {
        width: 0;
        visibility: collapse;
    }

.wj-tabcontent {
    float: right;
    height: 100%;
     width: auto;
}

    .wj-tabcontent h3 {
        margin-top: 0;
        font-family: -apple-system-font, 'Segoe UI Light', 'Segoe UI', 'Roboto', sans-serif;
    }

    .wj-tabcontent .wj-tabpane {
        position: relative;
        left: -9999px;
        background-color: #fafafa;
        width: 0px;
        height: 0px;
        display: none;
    }
        .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper {
            position: absolute;
            width: 299px;
            padding: 10px 20px 20px 20px;
            box-sizing: border-box;
        }

        .wj-viewer-outer.mobile .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper {
            position: absolute;
            width: 100%;
            padding: 10px 20px 20px 20px;
            box-sizing: border-box;
        }

        .wj-tabcontent .wj-tabpane .wj-tabtitle {
            color: #000;
            float: left;
        }

        .wj-tabcontent .wj-tabpane .wj-tabcontent-wrapper {
            height: 100%;
            padding-top: 48px;
            box-sizing: border-box;
        }
        .wj-tabcontent .wj-tabpane .wj-tabcontent-wrapper.search-wrapper {
            padding-top: 202px;
        }

        .wj-tabcontent .wj-tabpane .wj-tabcontent-wrapper.wj-exportformats-wrapper {
            padding-top: 120px;
        }

        .wj-tabcontent .wj-tabpane .wj-tabcontent-inner {
            overflow: auto;
            height: 100%;
            border-top: 1px solid #c8c8c8;
        }

            .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper .wj-close {
                font-size: 30pt;
                line-height: 20px;
                float: right;
                font-weight: bold;
                color: #000;
                text-shadow: 0 1px 0 #fff;
                opacity: .2;
                cursor: pointer;
            }

            .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper .wj-exportcontainer {
                margin-top: 50px;
            }

            .wj-tabcontent .wj-tabpane .wj-tabtitle-wrapper .wj-exportcontainer label {
                margin-right: 8px;
            }

        .wj-tabcontent .wj-tabpane .wj-outlines-tree {
            border-radius: 0;
            background-color: #fafafa;
            padding-top: 20px;
            padding-left: 4px;
        }

    .wj-tabcontent .wj-listbox-item.wj-thumbnail-item.wj-state-selected {
        background-color: transparent;
        color: inherit;
    }

    .wj-tabcontent .wj-listbox-item.wj-thumbnail-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
        background-color: transparent;
        color: inherit;
    }

    .wj-tabcontent .wj-thumbnaillist {
        width: 100%;
        border-radius: 0px;
        border: none;
        display: block;
    }

        .wj-tabcontent .wj-tabpane .wj-thumbnaillist:focus {
            outline: none;
        }

        .wj-tabcontent .wj-tabpane .wj-thumbnaillist .wj-thumbnail-item {
            height: auto;
            text-align: center;
            padding-top: 35px;
        }

            .wj-tabcontent .wj-tabpane .wj-thumbnaillist .wj-thumbnail-item .wj-pagethumbnail {
                background-color: white;
                border: 1px solid #c8c8c8;
                cursor: pointer;
                outline: none;
                margin: 0 auto 10px auto;
            }

        .wj-tabcontent .wj-tabpane .wj-thumbnaillist .wj-listbox-item.wj-thumbnail-item.wj-state-selected .wj-pagethumbnail {
            border-color: #0085C7;
        }

    .wj-tabcontent .active {
        left: 0px;
        width: 300px;
        height: 100%;
        border-left: 1px solid #c8c8c8;
        display: block;
    }

    .wj-viewer-outer.mobile .wj-tabcontent .active {
        left: 0px;
        width: 100%;
        height: 100%;
        border: 1px solid #c8c8c8;
        display: block;
    }

.wj-viewpanel-container {
    position: relative;
    background-color: #e5e5e5;
    float: right;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.wj-pageview {
    outline: none;
    position: relative;
    background-color: #e5e5e5;
    height: 100%;
    width: 100%;
    text-align: center;
    -webkit-overflow-scrolling: touch;
}

.wj-pageview-continuous{
    overflow: auto;
}

    .wj-pageview.hidden {
        display: none;
        visibility: hidden;
    }

.wj-pageview-pagescontainer {
    outline: none;
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block;
    overflow-x: auto;
    overflow-y: scroll;
    text-align: center;
}

    .wj-pageview-single:not(.virtual) .wj-pageview-pagescontainer {
        overflow-y: auto;
    }

.wj-pageview-vscroller {
    outline: none;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 18px;
    float: right;
    overflow-x: hidden;
    overflow-y: scroll;
}

    .wj-pageview-single:not(.virtual) .wj-pageview-vscroller{
        visibility: hidden;
    }

.wj-vscroller-wrapper{
    width: 1px;
}

@media print {
    .wj-viewpanel-container {
        display: inline-block;
        float: none;
    }
}

    .wj-viewpanel-container:focus {
        outline: none;
    }

    .wj-viewpanel-container .wj-view-page {
        width: 1px;
        height: 1px;
        margin: 0 auto 30px auto;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.3);
        box-shadow: 0 2px 8px rgba(0,0,0,.15);
    }

        .wj-viewpanel-container.move .wj-view-page {
            cursor: move;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .wj-viewpanel-container .wj-view-page:last-child {
            margin-bottom: 0px;
        }

.wj-viewer-loading {
    background-color: rgba(204,204,204,.8);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20pt;
}

    .wj-viewer-loading .verticalalign {
        vertical-align: middle;
        display: inline-block;
        width: 1px;
        height: 100%;
        margin-left: -1px;
    }

    .wj-viewer-loading .textspan {
        vertical-align: middle;
        display: inline-block;
        padding: 20px;
    }

    .wj-viewer-loading.errormessage {
        font-size: inherit;
    }

.wj-parameterscontainer {
    float: left;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.wj-parametercontainer {
    float: left;
    width: 100%;
    margin-top: 20px;
}

    .wj-parametercontainer .wj-parameterhead {
        float: left;
        padding-top: 4px;
    }

    .wj-parametercontainer .wj-parametercontrol {
        float: right;
        width: 100%;
    }

    .wj-parametercontainer .error {
        color: red;
        float: right;
    }

    .wj-parametererrors-hidden .wj-parametercontainer .error {
        display: none;
    }

    .wj-parametercontainer textarea {
        float: right;
        resize: none;
        height: 100px;
        width: 140px;
    }

.wj-tabpane .wj-applybutton {
    float: right;
    margin-top: 20px;
}

.wj-outlines-tree {
    border: none;
    width: 100%;
    display: block;
}

    .wj-outlines-tree .wj-cell {
        border: none;
    }

        .wj-outlines-tree .wj-cell span {
            margin-right: 5px;
        }

        .wj-outlines-tree .wj-cell a:link, .wj-outlines-tree .wj-cell a:visited,.wj-outlines-tree .wj-cell a:hover {
            color: #000;
            text-decoration: none;
        }

    .wj-outlines-tree .wj-header.wj-cell {
        color: #fff;
        background-color: #000;
        border: none;
        font-weight: bold;
    }

    .wj-outlines-tree .wj-cell {
        background-color: #fafafa;
        color: #000;
        cursor: pointer;
    }

    .wj-outlines-tree .wj-alt:not(.wj-state-selected):not(.wj-state-multi-selected) {
        background-color: #fafafa;
        color: #000;
    }

    .wj-outlines-tree .wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
        background-color: #fafafa;
        color: #000;
    }

    .wj-outlines-tree .wj-state-selected {
        background-color: #eee;
        color: #000;
    }

    .wj-outlines-tree .wj-state-multi-selected {
        background-color: #fafafa;
        color: #000;
    }

.wj-tabcontent .wj-tabpane .wj-searchbox {
    width: 100%;
    height: 30px;
    float: left;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 4px 60px 4px 8px;
    margin-right: 5px;
    box-sizing: border-box;
}

.wj-tabcontent .wj-tabpane .wj-searchcontainer {
    position: relative;
    float: left;
}

    .wj-tabcontent .wj-tabpane .wj-searchcontainer .wj-btn-group {
        position: absolute;
        top: 0;
        right: 0;
    }

        .wj-tabcontent .wj-tabpane .wj-searchcontainer .wj-btn-group button{
            background-color: transparent;
            background-position: center center;
            background-repeat: no-repeat;
            height: 30px;
            width: 30px;
            border: none;
            padding: 1px;
        }

.wj-tabcontent .wj-tabpane .wj-searchoption {
    margin-top: 10px;
    float: left;
    width: 100%;
}

    .wj-tabcontent .wj-tabpane .wj-searchoption input {
        height: 16px;
        float: left;
        width: 16px;
        margin: 2px 0 0;
    }

    .wj-tabcontent .wj-tabpane .wj-searchoption label {
        margin-top: 2px;
        float: left;
        font-weight: initial;
    }

.wj-tabcontent .wj-tabpane .wj-searchresult {
    color: #000;
    padding-top: 15px;
    float: left;
}

.wj-tabcontent .wj-searchresultlist {
    float: left;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-radius: 0px;
}

        .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-item {
            cursor: pointer;
        }

            .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-item:focus {
                outline: none;
            }

        .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-item:hover,
        .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-item.selected {
            background-color: #eee;
        }

        .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-text {
            font-size: 18px;
            font-weight: 500;
        }

        .wj-tabcontent .wj-tabpane .wj-searchresultlist .wj-search-page {
            font-weight: 300;
        }

.wj-viewer-outer.mobile .wj-tabcontent .wj-tabpane .wj-pagesetupcontainer {
    width: 100%;
}

.wj-view-page .wj-loading {
    background-color: rgba(204,204,204,.8);
    width: 100%;
    height: 100%;
}

.wj-view-page .highlight {
    fill: red;
    fill-opacity: 0.5;
}

.wj-view-page rect {
    pointer-events: none;
}

.wj-view-page a rect {
    pointer-events: inherit;
}

.wj-pages-wrapper {
    display: inline-block;
    margin: 30px;
}

.wj-svg-btn {
    opacity: 0.5;
    width: 22px;
    height: 22px;
    enable-background: new 0 0 22 22;
    stroke-width: 0px;
    stroke: black;
}

.wj-viewer .wj-btn-group > .wj-btn {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    height: 36px;
    width: 34px;
}

.wj-viewer .mobile .wj-btn-group > .wj-btn {
    padding-top: 9px;
}

.wj-content:not(.customEdit) .wj-btn-group .wj-btn:hover {
  background-color: #eee
}

.wj-content:not(.customEdit) .wj-btn-group .wj-btn:focus {
  outline: none;
}

.wj-content:not(.customEdit) .wj-btn-group .wj-btn.wj-state-active .wj-svg-btn {
  fill: #3986C7;
  opacity: 1;
  stroke: #3986C7;
}

.wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-btn .wj-svg-btn {
  fill: #3986C7;
  opacity: 1;
}

.wj-viewer-menu.wj-btn-group-vertical .wj-listbox-item.wj-state-active .wj-btn .wj-svg-btn .wj-hollow-svg {
  fill: none;
  stroke: #3986C7;
}

.wj-viewer-menu > .wj-listbox-item {
  white-space: nowrap;
}

.wj-viewer-footer {
    height: 50px;
    width: 100%;
    border-top: 1px solid #c8c8c8;
    box-sizing: border-box;
    position: relative;
    display: none;
}

    .wj-viewer-outer.mobile .wj-viewer-footer {
        display: block;
    }

    .wj-viewer-outer.mobile .wj-viewer-footer.hidden {
        display: none;
    }

    .wj-viewer-footer .wj-toolbar {
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .wj-viewer-footer .wj-close {
        font-size: 30pt;
        line-height: 20px;
        float: right;
        font-weight: bold;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .2;
        cursor: pointer;
        margin-top: 12px;
    }

/*
    remove the red glow Firefox adds to invalid input elements
    https://developer.mozilla.org/en/docs/Web/CSS/:invalid
*/
.wj-control :invalid {
  box-shadow: none;
}

.wj-export-editor {
    width: 100%;
}

.wj-export-editor td {
    font-size: 8pt;
}

    .wj-exportformats-wrapper .wj-export-editor td:first-child {
        width: 200px;
    }

    .wj-exportformats-wrapper .wj-export-editor td:last-child {
        width: 150px;
    }

.wj-exportformats-group {
    margin: 8px;
}

.wj-exportformats-footer {
    padding: 8px;
}

.wj-pagesetup-footer {
    padding: 8px;
}

.wj-searchbar {
    height: 50px;
    width: 100%;
    border-top: 1px solid #c8c8c8;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
}
    .wj-searchbar.hidden {
        visibility: hidden;
        display: none;
    }

    .wj-searchbar .wj-searchcontainer {
        left: 40px;
        right: 80px;
        float: left;
        width: auto;
        position: absolute;
        padding-top: 4px;
        display: inline-block;
    }

    .wj-searchbar .wj-searchbox {
        width: 100%;
        height: 30px;
        float: left;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 4px 60px 4px 8px;
        margin-right: 5px;
        box-sizing: border-box;
    }

    .wj-searchbar .wj-searchcontainer .wj-btn-group {
        position: absolute;
        top: 5px;
        right: 0;
    }

        .wj-searchbar .wj-searchcontainer .wj-btn-group > .wj-btn-search {
            padding-top: 4px;
        }

        .wj-searchbar .wj-searchcontainer .wj-btn-group button{
            background-color: transparent;
            background-position: center center;
            background-repeat: no-repeat;
            height: 30px;
            width: 30px;
            border: none;
            padding: 1px;
        }

    .wj-searchbar .wj-toolbarcontainer {
        width: 100%;
    }

    .wj-searchbar .wj-toolbarcontainer .wj-toolbarwrapper {
        width: 100%;
    }

    .wj-searchbar .wj-toolbarcontainer .wj-toolbarwrapper > .wj-btn {
        display:inline-block;
    }

    .wj-searchbar .wj-toolbarcontainer .wj-toolbarwrapper .wj-searchbtn-groups {
        float: right;
        width: auto;
        padding-top: 0;
    }

.wj-listbox-item .checkIcon {
    width: 20px;
    margin-right: 5px;
    visibility: hidden;
}
    .wj-listbox-item.checked .checkIcon {
        visibility: visible;
    }

    .wj-listbox-item.hidden {
        display: none;
        visibility: hidden;
    }

.wj-zoombar {
    width: 220px;
}

.wj-rubberband {
    border: solid;
    border-color: red;
    border-width: 2px;
    width: 0px;
    height: 0px;
    z-index: 1;
    position: absolute;
    display: none;
}

.wj-viewpanel-container.rubberband-actived .wj-view-page,
.wj-viewpanel-container.magnifier-actived .wj-view-page {
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wj-magnifier {
    border: solid;
    border-color: black;
    border-width: 1px;
    width: 242px;
    height: 150px;
    z-index: 1;
    position: absolute;
    display: none;
    overflow: hidden;
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.wj-viewpanel-container.rubberband-actived .wj-rubberband.show,
.wj-viewpanel-container.magnifier-actived .wj-magnifier.show {
    display: block;
}

.wj-magnifier .wj-view-page {
    position: absolute;
}
