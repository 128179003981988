.margin_top-9 {
    margin-top: 9px;
}
.margin_top-28 {
    margin-top: 28px !important;
}
.margin_top-10 {
    margin-top: 10px;
}
.margin_top-12 {
    margin-top: 12px;
}
.light_gray {
    background-color: #e9ecef !important;
}
.color_light_gray {
     color: gray !important;
}
.light_gray_for_dropdown
{
    .ng-select-container  {
        background-color: #e9ecef !important
    }
}
.m-17{
    margin-top: 17px !important;
}
.bg-light-gray-2{
    background: #e5e0e0;
}
.width_6{
    width: 6rem !important;
}

.label-weight {
    font-weight: 400 !important;
}
.word-break {
    word-break: break-all;
}

.font-15
{
    font-size: 1.4rem !important;
}
.text-black {
        color: black !important;
}

.back-dark-color{
    background-color: #003355 !important;
}

.back-white{
    background: #fff;
}

.color-gold{
    color: #e0b000;
    font-size: 16px;
}
.padding-top-14{
    padding-top: 14rem !important;
}
.padding-top-11{
    padding-top: 11rem !important;
}
.background_red {
    background-color: $red !important;
}
.height_45 {
    height: 45px !important;
}
.height_44 {
    height: 44px !important;
}
.margin-left_14{
    margin-left: 14px !important;
}


.font-size-14{
    font-size: 1.4rem !important;
}

.min_width_6{
    min-width: 4.9rem !important;
}
.margin_15{
    margin-top: -15px !important;
}
.margin_9{
    margin-top: 9px !important;
}
.height_35{
    height: 35rem !important;
}
.common_scroll{
    height: 53rem;
    overflow-y: scroll;
}
.margin_11 {
    margin-top: -11px !important;
}
.dms_document_scroll {
    height: 55rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
.font-size_2_2{
    font-size: 2.1rem !important;
}
.font-size_1_2{
    font-size: 1.2rem !important;
}
.margin_left_53 {
    margin-left: 53px !important;
}
.margin_left_57 {
    margin-left: 57px !important;
}
.margin_left_13 {
    margin-left: 13px !important;
}
.margin_left_2 {
    margin-left: 13px !important;
}
.margin_left_1 {
    margin-left: -2px !important;
}
.margin_left_3 {
    margin-left: 3rem !important;
}
.margin_8_2{
    margin-top: 0.8rem !important;
    margin-left: 2rem !important;
}
.bright_green{
    color:  	#7FFF00 !important;
}
.min_height_37{
    min-height: 37rem !important;
}
.margin-top_8{
    margin-top: -8px !important;
}
.height_6rem {
    height: 6.5rem !important;
}
.margin_top_9{
    margin-top: -9px !important;
}
.margin_top_6{
    margin-top: -6px;
}
.font-size_2_2{
    font-size: 2.1rem !important;
}
.margin-top_10 {
    margin-top: -10px !important;
}
.reports_default_height {
    height:81.4vh  !important;
}
.drug_disp_reports_default_height {
    height:86vh  !important;
}
.reports_default_height_patient_medication {
    height:63rem !important;
}
.reports_default_height3 {
    height:92.5vh !important;
}
.reports_default_tabs_height {
    height:74.14vh !important ;
}
.reports_default_more_height {
    height:74.95rem  !important ;
}
.padding_4 {
    padding: 0.4rem !important;
}
.custom_font_size_18{
    font-size: 18px !important;
}
.reports_refilldue_more_height {
    height:79rem  !important ;
}
.reports_priceupdate_height {
    height:79.4vh  !important ;
}

.total_remaining_traingle_icon{
    border-right: 10px solid red !important;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    border-bottom: 10px solid transparent !important;
    display: inline-block !important;
    &.IOU-filled {
        border-right: 10px solid #0fa20f !important;
    }
    &.IOU-partialfilled {
        border-right: 10px solid #f7941d !important;
    }

}
.iou_hist_traingle_icon{
    border-right: 10px solid blue !important;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    border-bottom: 10px solid transparent !important;
    display: inline-block !important;
    &.refill {
        border-right: 10px solid #0fa20f !important;
    }

}
.custom_bg_light_red {
    background-color: #e7524b !important;
}
.w-31 {
    width: 31% !important;
}
.width_567 {
    width: 567px !important;
}
.font-size_2_2{
    font-size: 2.1rem !important;
}
.margin-top_10 {
    margin-top: -10px !important;
}
.align-adjust-with-radio {
    display: unset !important;
}
.text-display-notes {
    text-transform: none !important;
}
.move-right{
    left: 2rem !important;
}
.margin-top_340B {
    margin-top: 5% !important;
}
.margin-top_workflow {
    margin-top: -1% !important;
}
.font-size_13 {
    font-size: 1.3rem !important;
}
.tranfer-out-sig-height {
    height: 5rem;
    overflow-y: scroll;
}
.tranfer-in-sig-allergies-height {
    height: 2.5rem;
    overflow-y: scroll;
}
.dispill-sig-height {
    max-height: 1.5rem;
    overflow-y: scroll !important;
    overflow-x: none !important;
}
.dispill-drug-name-height{
    max-height: 2rem;
    overflow-y: scroll !important;
    overflow-x: none !important;
}
.dispill-date-picker-width{
    width: 139px !important;
    padding-left: 4px;
    margin-left: 8px;
}

.dispill-date-picker-width_179px{
    width: 179px !important;
    padding-left: 4px;
}

.disabled {
    color: gray;
    text-decoration: underline;
    pointer-events: none;
}
input:checked + .slider {
    background-color: #429cd0;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 8px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 26px;
  }
  .left-27 {
    left:-27px;
  }
.anchor-tag-sms{
    text-decoration: underline;
    font-size: larger;
}
.search-box-patient-msg-log {
    .input-group {
        width: 103% !important;
    }
}
.cancel-icon-msg-queue {
    color: red;
    margin-left: 22px;
}
.message-queue-custom-input {
    .custom--input {
        width: 57rem;
    }
}
.modal-popup-left-38 {
    left: 38px;
}
.modal-configure {
    font-size: 1.6rem;
    margin-top: 0.7rem;
    color: white;
    position: relative;
}
.cursor-no-pointer {
    cursor: not-allowed;
    // pointer-events: none;
    color:#a6a8b3 !important;
}
.cancel-left-align-7 {
    left: 7rem;
}
.cancel-left-align-25 {
    left: 25rem;
}
.add-edit-icon-sms {
    font-size: 2rem;
    color: #31afe5;
}
.msg-log-sent {
    font-weight: 600;
    color: green;
    text-align: center;
}
.msg-log-delivered {
    font-weight: 600;
    color: blue;
    text-align: center;
}
.msg-log-cancelled {
    font-weight: 600;
    color: orange;
    text-align: center;
}
.msg-log-failed {
    font-weight: 600;
    color: #df3a3a;
    margin-left: 39px;
}
.align--btnReports_width {
    width:10rem;
    text-align:left
}
.export-button-css{
    margin-top: 0.75rem;
    height: 2.7rem !important;
    width: 8rem;
}
.export-button2-css{
    margin-top: 0rem;
    height: 2.7rem !important;
    width: 8rem;
}
.audit-trail-alignment {
    text-align: center;
    padding:3rem 0rem;
    font-size: larger;
}
.audit-trail2-alignment {
    text-align: center;
    padding: 7rem 0rem !important;
    font-size: larger;
}
.resend-icon {
    font-size: 1.2rem;
    color: #5E646C;
}
.disabled-resend-icon {
    font-size: 1.2rem;
    color:#B2B2B2;
    cursor: not-allowed;
}
.ml-77 {
    margin-left:77rem !important;
}

.font_size_16 {
    font-size: 16px !important;
}
.common_checkBox_size {
    width: 22px !important;
    height: 17px !important;
}
.disabled--item {
    color: gray !important;
    pointer-events: none !important;
}
.notification-dot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 1.5rem;
    margin-top: 10px;
  }
  .plus_icon_withoutHover {
    margin: 0rem 0.25rem 0rem 0.25rem;
    color: #31afe5;
    border-radius: 50%;
    font-size: 1.25rem;
  }

.ttext-rej {
    font-size: 14px;
    color: #5D636B;
}

.table--header-rej {
    top: 0;
    position: sticky;
    background: #31afe5;
    color: #FFFFFF;
}
  .refreshiconstyle {
    font-size: 0.8rem;
    color: #31afe5;
    padding-top: -2rem;
    margin-left: -6rem;
    left: 3rem;
    // top: 3rem;
    // top: 2.1rem;
    top: -0.9rem;
    position: absolute;
}
.notes_History {
    line-height: 1.92rem !important;
    height: 5.1rem !important;
    background: white !important;
    box-shadow: 0 0.21rem 0.5rem 0 white !important;
}
.marging_top14 {
    margin-top: 14px !important;
}
.fontSize_9px {
    font-size: 9px !important;
}
.attachment{
    position: absolute;
    right: 1.7rem !important;
    top: 2px;
}
.label_Color {
    color: $cyan;
}
.ml-11{
    margin-left: 11px !important;
}
.border_25{
    border-radius: 0.25rem !important;
}
.font_size_22 {
    font-size: 22px  !important;
}

.ltb-print {
    background: #31afe5;
    border: 1px solid #31afe5;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 500;
    a {
        color: #FFF;
    }
    
}
.ltb-print:hover{
    border: 1px solid #31afe5;
    padding: 4px 8px;
    background: #FFF;
    a {
        color: #31afe5;
    }
}

