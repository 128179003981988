.search-tooltip-patient_bucket_for_left_display {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding:9px !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite;
        color: black !important;
        left: -37% !important;
        top: -8px !important;
        height: 41px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}

.electron_rx_tooltip{
    .tooltip-inner {
        width: 81rem !important;
    }
}
.search-tooltip-patient {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding: 1rem 1rem 1rem 1rem !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        height: 54px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}


.erx-tooltip_large {
    .tooltip-inner {
        // background-color: white;
        font-size: 1rem;
        max-width: 750px;
        min-width: 520px;
        color: white;
        border: 3px solid $success;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
        label {
            color: black !important;
            font-weight: 600 !important;
        }
        table {
            td {
                font-size: unset !important;
            }
        }
    }
    .arrow {
        &::before {
            border-top-color: $success;
        }
    }
    opacity: 1 !important;
}

.payment-tooltip_inner {
    .tooltip-inner {
        // background-color: white;
        font-size: 1rem;
        max-width: 400px;
        min-width: 400px;
        color: white;
        border: 3px solid $success;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
        label {
            color: black !important;
            font-weight: 600 !important;
        }
        table {
            td {
                font-size: unset !important;
            }
        }
    }
    .arrow {
        &::before {
            border-top-color: $success;
        }
    }
    opacity: 1 !important;
}

.erx-tooltip {
    .tooltip-inner {
        // background-color: white;
        font-size: 1rem;
        max-width: 600px;
        min-width: 520px;
        color: white;
        border: 3px solid $success;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
        label {
            color: black !important;
            font-weight: 600 !important;
        }
        table {
            td {
                font-size: unset !important;
            }
        }
    }
    .arrow {
        &::before {
            border-top-color: $success;
        }
    }
    opacity: 1 !important;
}



.reject_codeForStatus{
    @extend .erx-tooltip_large ;
    .tooltip-inner {
        border: 3px solid #e2b77e;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}

.search-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.iou--toolTip {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
             border-top-color: #e2b77e;
        }
    }
}
.search-tooltip-DrugVaccine{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        min-width: 784px;
        margin-left: -441px;
        overflow-y: auto;
        max-height: 250px
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.DPVVerf-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 2px solid #e2b77e;
        max-width: 640px !important;
        min-width: 630px !important;
        background-color: #FFFBEC !important;
        label{
            color:#17a2b8 !important;
            font-weight: 400 !important;
        }
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.search-tooltip-pat{
    @extend .search-tooltip;
    @extend .erx-tooltip;
    .tooltip-inner {
       margin-top:-4rem !important;
    }
}
.search-tooltip-patient {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding: 1rem 1rem 1rem 1rem !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        height: 54px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.title-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding: 1rem 1rem 1rem 1rem !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        height: auto !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.help-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        min-width:385px !important;
        margin-top: 60% !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
        }
    }
}
.help-tooltip-bottom{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        min-width:385px !important;
        margin-top: 742% !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
        }
    }
}
.erx-drug-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        font-size: 1rem;
        max-width: 800px;
    }
    .arrow {
        &::before {
            // border-left-color: $success;
        }
    }
}
.payment-tooltip {
    @extend .payment-tooltip_inner;
    .tooltip-inner {
        font-size: 1rem;
        max-width: 350px;
    }
    .arrow {
        &::before {
            // border-left-color: $success;
        }
    }
}


.erx-patient-tooltip {
    @extend .erx-tooltip;
    .arrow {
        &::before {
            // border-right-color: $success;
        }
    }
}

.erx-prescriber-tooltip {
    @extend .erx-tooltip;
    .arrow {
        &::before {
            // border-right-color: $success;
        }
    }
}
.notes-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 400px;
        min-width: 400px;
    }
    .arrow {
        &::before {
           // border-left-color: $success;
        }
    }
}

.presc-notes-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 400px;
        min-width: 400px;
    }
    .arrow {
        &::before {
            border-left-color: $success;
        }
    }
}

.tooltip-auto {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: auto !important;
        min-width: 200px !important;
    }
}

.search-tooltip-patient {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding: 1rem 1rem 1rem 1rem !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        height: 54px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}

.search-tooltip-patient_electronic {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding: 1rem 1rem 1rem 1rem !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        height: auto !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.search_tooltip_benefitcheck_infoDetails {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 400px  !important;
        min-width: 76px !important;
        position: relative !important;
        color: white !important;
        padding:9px !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        top: -8px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.search_tooltip_befitcheck_info {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 344px  !important;
        min-width: 76px !important;
        position: relative !important;
        color: white !important;
        padding:9px !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: 12% !important;
        top: -8px !important;
        height: 192px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.search-tooltip-patient_bucket {
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        font-size: 1rem;
        max-width: 579px !important;
        min-width: 100px !important;
        position: relative !important;
        color: white !important;
        padding:9px !important;
        border-radius: 0.5rem !important;
        background-color: antiquewhite !important;
        color: black !important;
        left: -37% !important;
        top: -8px !important;
        height: 41px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
    .tooltip-title{
        .tooltip-inner {
           border: 3px solid #e2b77e;
           min-width: auto;
           max-width: 600px;
           font-size: 1rem;
           padding:0px 2px 0px 3px;
           border-radius: 0.5rem;
           background-color: antiquewhite;
           color:#080b10;
           font-weight: bold;
        }
    }
    .tooltip-title-audit{
        .tooltip-inner {
            text-transform: uppercase;
        }
    }
    .button-tooltip{
        .tooltip-inner{
            border: 3px solid #080b10;
            min-width: auto;
            max-width: 900px;
            font-size: 1rem;
            padding:0px 2px 0px 3px;
            border-radius: 0.5rem;
            background-color: #080b10;
            color: white !important;
            max-height:127px !important;
        }
    }
    .toolTipIcon-msg-log {
        position: absolute;
        font-weight: bold;
        color: white;
        height: 21px;
        width: 21px;
        padding-left: 7px;
        font-size: 17px;
        background: rgb(247, 148,29);
        border-radius: 50%;
    }
    .toolTipIcon-billNumber {
        position: absolute;
        font-weight: bold;
        color: white;
        height: 23px;
        width: 23px;
        padding-left: 8px;
        font-size: 17px;
        background: #f7941d;
        border-radius: 50%;
        margin-top: 1px;
    }

.tooltip-patient-file {
    position: absolute;
    font-weight: bold;
    color: #ffffff;
    height: 21px;
    width: 21px;
    font-size: 14px;
    border-radius: 50%;
    background-color: search-tooltip;
    background-color: rgb(247, 148,29);
    padding: 1px;
    padding-left: 7px;
    top: 2px;
}

.search-tooltip_benefitcheck {
    .tooltip-inner {
        font-size: 1rem;
        max-width: 193px;
        min-width: 383px;
        color: white;
        border: 3px solid #51a351;
        padding: 10px;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
     }
}

.search-tooltip_warn {
    .tooltip-inner {
        font-size: 1rem;
        max-width: 193px;
        min-width: 383px;
        color: white;
        border: 3px solid #e2b77e;
        padding: 10px;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;

     }
}

.oth-amt-tooltip .tooltip-inner {
    padding-bottom: 0rem !important;
}
.search-tooltip_Pharm{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #e2b77e !important;
        }
    }
}
.tooltip_PharmSet {
    position: absolute;
    font-weight: bold;
    color: white;
    top: 4px;
    right: 137px;
    height: 16px;
    width: 16px;
    text-align: center;
    font-size: 12px;
    background: rgb(247, 148,29);
    border-radius: 50%;
}
.hover--width{
    max-width: 3rem !important;
}
.tooltip_WorkflwSet {
    position: absolute;
    font-weight: bold;
    color: white;
    top: -2px;
    right: 65px;
    height: 23px;
    width: 23px;
    text-align: center;
    font-size: 1.2rem;
    background: rgb(247, 148,29);
    border-radius: 50%;
}
.count-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        min-width:227px !important;
        position: relative;
        top: -7px !important;
        left: -223px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: #e2b77e;
        }
    }
}
.tooltip_notes_dur{
    @extend .tooltip-title;
    .tooltip-inner {
        font-weight: normal !important;
        width:14rem !important
    }
}
.tooltip_notes_intakeQueue{
    @extend .tooltip-title;
    .tooltip-inner {
        font-weight: normal !important;
        width:16rem !important
    }
}
.tooltip-patient-file_mobile{
    position: absolute;
    font-weight: bold;
    color: #ffffff;
    height: 19px;
    width: 19px;
    font-size: 12px;
    border-radius: 50%;
    background-color: search-tooltip;
    background-color: #f7941d;
    padding: 1px;
    padding-left: 7px;
    top: 59px;
    left: 79%;
}
.effective_date_tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        min-width: 137px !important;
        max-height: 4rem !important;
        border: 3px solid #e2b77e !important;
        padding: inherit !important;
    }
}
.tooltip_notes{
    @extend .tooltip-title;
    .tooltip-inner {
        font-weight: normal !important;
    }
}
.tooltip_Inventory {
    position: absolute;
    font-weight: bold;
    color: white;
    top: -2px;
    right: 59px !important;
    height: 16px;
    width: 16px;
    text-align: center;
    background: rgb(247, 148,29);
    border-radius: 50%;
    padding-left: 4px !important;
    padding-right: 4px !important;
    font-size: 12px;
    top: 0.3rem;
    right: 4rem;
}
.tooltip_Rx_Defer_Setting{
    @extend .tooltip_Inventory;
    top: 1.2rem !important;
}
.search-tooltip_EDIInv{
    @extend .erx-tooltip;
    .tooltip-inner {
        min-width: 195px !important;
        max-width: 200px !important;
        border: 1px solid #F7931D;
        background-color: #FFF9F2 !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
            border-right-color: #F7931D !important;
        }
    }
}
.info_display{
    position: absolute;
     font-weight: bold;
     color: white;
     text-align: center;
     background: #f7941d;
     border-radius: 50%;
     padding-left: 6px !important;
     padding-right: 6px !important;
     font-size: 13px;
 }
.medicare-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        min-width:271px !important;
        height: 96px;
        background-color: #FFF9F2 !important;
        color: $text-color-black !important;
        font-size: 15px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #F7931D !important;
        }
    }
}
.search-tooltip_IP{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        max-width: 419px !important;
        min-width: 337px !important;
        background-color: #FFF9F2 !important;
        height: 8.5rem !important;
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #F7931D !important;
        }
    }
}
.compound--tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        min-width:260px !important;
        background-color: #FFF9F2 !important;
        margin-left: 1rem !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
            border-right-color: #F7931D !important;
        }
    }
}
.tooltip_blackBoxWarning{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        max-width: 419px !important;
        min-width: 337px !important;
        background-color: #FFF9F2 !important;
        height: 9.5rem !important;
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #F7931D !important;
        }
    }
}
.tooltip_primeESC {
    @extend .medicare-tooltip;
    .tooltip-inner {
        min-width: 32rem !important;
        padding-top: 12px;
    }
}
.tooltip_DDI_Days {
    @extend .tooltip_blackBoxWarning;
    .tooltip-inner {
    max-width: 49rem !important;
    height: 14.5rem !important;
    }
}
.tooltip_Qty_Dis {
    @extend .tooltip_blackBoxWarning;
    .tooltip-inner {
        font-size: 1rem;
        color: white;
        border: 3px solid #e2b77e;
        padding: 10px;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
    min-width: 0rem !important;
    max-width: 25rem !important;
    height: 6.5rem !important;
    }
}
.tooltip_rx_Transfer {
    @extend .medication_Category;
    line-height: 1rem;
    padding-top: 0.1rem;
}
.tooltip_rxTransfer_box {
    @extend .medicare-tooltip;
    .tooltip-inner {
        min-width: 31rem !important;
        padding-top: 10px !important;
        height: 5rem !important;
    }
}
.tooltip_ViewPhysiclaInventoryRecords {
    @extend .medicare-tooltip;
    .tooltip-inner {
        min-width: 31rem !important;
        padding-top: 10px !important;
        height: 6rem !important;
    }
}
.tooltip_DisplayTransactionsPerpetualInventory {
    @extend .medicare-tooltip;
    .tooltip-inner {
        padding: 3px !important;
        height: 2rem !important;
    }
}

.eligibilityCheck--tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        min-width:800px !important;
        background-color: white !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
            border-left-color: #F7931D !important;
        }

    }

}
.search-tooltip_DrugImg{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 3px solid #e2b77e;
        max-width: 448px !important;
        min-width: 337px !important;
        background-color: #FFF9F2 !important;
        height: 8.5rem !important;
        label{
            color:#17a2b8 !important;
            font-weight: 400 !important;
        }
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #e2b77e !important;
        }
    }
}
.vaccine_tooltip {
    position: absolute;
    font-weight: bold;
    color: white;
    height: 16px;
    width: 16px;
    text-align: center;
    background: rgb(247, 148,29);
    border-radius: 50%;
    padding-left: 4px !important;
    padding-right: 4px !important;
    font-size: 12px;
    top: 0.2rem !important;
    margin-left: 7rem !important;
}

.vaccineInfo-tooltip{
    @extend .search-tooltip;
    @extend .erx-tooltip;
    .tooltip-inner {
      margin-left: -24rem !important;
    }
}


.rxnotes-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: auto;
        min-width: auto;
    }
}

.dscsa-check-tooltip {
    @extend .erx-tooltip;
    .tooltip-inner {
        max-width: 450px !important;
        min-width: 350px !important;
    }
    .arrow {
        &::before {
            border-right-color: #51a351;
            border-top-color: transparent;
        }
    }
    
}

.dscsa-uncheck-tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        max-width: 450px !important;
        min-width: 350px !important;
        border: 3px solid #e2b77e;
    }
    .arrow {
        &::before {
            border-right-color: #e2b77e;
            border-top-color: transparent;
        }
    }
}
.title-tooltip {
    .tooltip-inner {
        max-width: auto !important;
        min-width: auto !important;

    }
}
.auto-title-tooltip {
    .tooltip-inner {
        border: 3px solid #e2b77e;
        background-color: antiquewhite !important;
        color: black !important;
        font-size: 1rem;
    }
    &.fontBold {
        font-weight: bold
    }
    .arrow {
        &::before {
             border-top-color: #e2b77e;
        }
    }
}
.payment-information-edit-rx {
    .tooltip-inner {
        font-size: 1rem;
        max-width: 264px;
        min-width: 264px;
        color: white;
        border: 3px solid $success;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 0.5rem;
        background-color: antiquewhite;
        color: black;
        label {
            color: black !important;
            font-weight: 600 !important;
        }
        table {
            td {
                font-size: unset !important;
            }
        }
    }
    .arrow {
        &::before {
            border-top-color: $success;
        }
    }
    opacity: 1 !important;
}

.pickup--tooltip {
    @extend .payment-information-edit-rx;
    .tooltip-inner {
        max-width: 316px;
        min-width: 264px;
        border: 2px solid #e2b77e !important;
        padding: 0.7rem;
    }
    .arrow {
        &::before {
            border-right-color: #e2b77e !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
        }
    }
}

.settings--tooltip_height{
    @extend .erx-tooltip;
    .tooltip-inner {
        max-width: 222px !important;
        max-height: 7rem !important;
        border: 1px solid #F7931D !important;
        padding: inherit !important;
        min-width: 195px !important;
        background-color: #FFF9F2 !important;
    }
    .arrow {
        &::before {
            border-bottom-color: transparent !important;
            border-top-color: transparent !important;
            border-right-color: #F7931D !important;
        }
    }
}
.tooltip-inner_height-scroll {
    max-height: 9rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.minWidth--hover--tooltip{
    @extend .erx-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        max-width: 291px !important;
        min-width: 267px !important;
    }
    .arrow {
        &::before {
             border-bottom-color: transparent !important;
             border-top-color: transparent !important;
             border-right-color: #F7931D !important;
             border-left-color: #F7931D !important;
        }
    }
}

.paStatus--tooltip--1 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $pending !important;
        padding: inherit !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $pending !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--2, .paStatus--tooltip--14 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $approved !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $approved !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--3, .paStatus--tooltip--13 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $submitted !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $submitted !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--6 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $cancelled !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $cancelled !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--7 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $error !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $error !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--8, .paStatus--tooltip--15 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $denied !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $denied !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--9 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $inActive !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $inActive !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--11 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $notInitate !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $notInitate !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--12 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $archive !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $archive !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--16 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $delete !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-bottom-color: $delete !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--right--1 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $pending !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $pending !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--right--2, .paStatus--tooltip--right--14 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $approved !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $approved !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--right--3, .paStatus--tooltip--right--13 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $submitted !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $submitted !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--right--6 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $cancelled !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $cancelled !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--right--7 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $error !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $error !important;
            border-top-color: transparent !important;
        }
    }
}
.paStatus--tooltip--right--8, .paStatus--tooltip--right--15 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $denied !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $denied !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--right--9 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $inActive !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $inActive !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--right--11 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $notInitate !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $notInitate !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--right--12 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $archive !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $archive !important;
            border-top-color: transparent !important;
        }
    }
}

.paStatus--tooltip--right--16 {
    @extend .erx-tooltip;
    .tooltip-inner {
        width: 280px!important;
        border: 1px solid $delete !important;
        padding: 0px !important;
        min-width: 195px !important;
        background-color: #ffffff !important;        
    }
    .arrow {
        &::before {
            border-right-color: $delete !important;
            border-top-color: transparent !important;
        }
    }
}

// .patooltip-inner_height-scroll {
//     max-height: 23rem;
//     overflow-y: scroll;
//     overflow-x: hidden;
// }
.bottom--toolTip{
    @extend .medicare-tooltip;
    .tooltip-inner {
        border: 1px solid #F7931D;
        height: 9rem !important;
        min-width: 314px !important;
        padding:9px !important;
        font-size: 14px !important;
        color: black !important;
    }
    .arrow {
        &::before {
            border-bottom-color: #F7931D !important;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-left-color: transparent !important;
        }
    }
}

