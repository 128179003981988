.label--navigation__icons {
    list-style: none !important;
    padding: 0rem;
    li {
        float: left;
        a {
            padding: 0.35rem;
            cursor: pointer;
        };
        margin-left: 0.3rem;
    }
    li {
        label {
            color: $white !important;
            input[type="file"] {
                display: none;
                cursor: pointer;
            }
            .fa {
                font-size: 1.37rem;
                margin-top: 0.71rem;
            }
            .zoom-count{
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}

.header--font__block {
    .font--size {
        margin-top: 3px;
    }
}

.canvas--block {
    border: 0.07rem solid black;
    .canvas-container{
        border: 0.07rem solid $primary;
    }
}

.image-edit{
    .canvas-container{
        width: 100% !important;
    }
}
ul,
#ConfirmationPopUp {
    padding-left: 15px;
}

ul,
#myUL {
    list-style-type: none;
}

.toggleTree {
    padding-left: 0rem !important;
    color: $jet ;
    font-size: 1.14rem;
}

.label--design__header {
    background: $primary;
    color: $white;
}

.caret {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 1.42rem;
    &::before {
        content: "\25B6";
        color: $jet;
        display: inline-block;
        margin-right: 0.42rem;
    }
}

.caret-down::before {
    transform: rotate(90deg);
  }

.nested {
    display: none;
    li {
        padding: 0.42rem;
        cursor: pointer;
    }
}

.child--menu {
    // min-height: 7.14rem !important;
    max-height: 35.7rem !important;
    overflow-y: scroll;
}

.active {
    display: block !important;
}

