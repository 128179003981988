.fak.fa-dur::before, .fa-kit.fa-dur::before { content: "\e001"; }
.fak.fa-notes::before, .fa-kit.fa-notes::before { content: "\e000"; }
.fak.fa-notes-light::before, .fa-kit.fa-notes-light::before { content: "\e002"; }
.fak.fa-notes-solid::before, .fa-kit.fa-notes-solid::before { content: "\e003"; }
.fak.fa-solid-prescription-bottle-circle-dollar::before, .fa-kit.fa-solid-prescription-bottle-circle-dollar::before { content: "\e005"; }
.fak.fa-solid-prescription-bottle-tag::before, .fa-kit.fa-solid-prescription-bottle-tag::before { content: "\e004"; }
.fak.fa-solid-prescription-bottle-tag::before, .fa-kit.fa-solid-prescription-bottle-tag::before { content: "\e004"; }
.fak.fa-previous-refill-line::before, .fa-kit.fa-previous-refill-line::before {content: "\e00a";}
.fak.fa-clear-all::before, .fa-kit.fa-clear-all::before {content: "\e00d"}
.fak.fa-check::before, .fa-kit.fa-check::before {content: "\e014"}
.fak.fa-uncheck::before, .fa-kit.fa-uncheck::before {content: "\e015"}
.fak.fa-dscsa-1::before, .fa-kit.fa-dscsa-1::before {content: "\e017"}
.fak.fa-add-record::before, .fa-kit.fa-add-record::before {content: "\e020"}
.fak.fa-house-medical-regular::before, .fa-kit.fa-house-medical-regular::before {content: "\e01f"}
.fak.fa-house-medical-circle-check-regular::before, .fa-kit.fa-house-medical-circle-check-regular::before {content: "\e01d"}
.fak.fa-hospital-user-regular::before, .fa-kit.fa-hospital-user-regular::before {content: "\e01b"}
.fak.fa-house-medical-thin::before, .fa-kit.fa-house-medical-thin::before {content: "\e01c"}
.fak.fa-regular-prescription-bottle-circle-arrow-right::before, .fa-kit.fa-regular-prescription-bottle-circle-arrow-right::before {content: "\e01a"}
.fak.fa-circle-chevron-right-solid::before, .fa-kit.fa-circle-chevron-right-solid::before {content: "\e021"}
.fak.fa-circle-chevron-down-solid::before, .fa-kit.fa-circle-chevron-down-solid::before {content: "\e022"}
.fak.fa-notes-filled::before, .fa-kit.fa-notes-filled::before {content: "\e024"}
.fak.fa-notes-line::before, .fa-kit.fa-notes-line::before {content: "\e025"}
.fak.fa-hourglass-clock::before, .fa-kit.fa-hourglass-clock::before {content: "\e02d"}
.fak.fa-circle-check::before, .fa-kit.fa-circle-check::before {content: "\e029"}
.fak.fa-circle-exclamation::before, .fa-kit.fa-circle-exclamation::before {content: "\e02a"}
.fak.fa-circle-xmark::before, .fa-kit.fa-circle-xmark::before {content: "\e02b"}
.fak.fa-ban-sharp::before, .fa-kit.fa-ban-sharp::before {content: "\e027"}
.fak.fa-envelope::before, .fa-kit.fa-envelope::before {content: "\e02c"}
.fak.fa-calendar-xmark::before, .fa-kit.fa-calendar-xmark::before {content: "\e028"}
.fak.fa-light-paper-plane-circle-xmark::before, .fa-kit.fa-light-paper-plane-circle-xmark::before {content: "\e026"}
.fak.fa-paper-plane::before, .fa-kit.fa-paper-plane::before {content: "\e02e"}
.fak.fa-retry::before, .fa-kit.fa-retry::before {content: "\e02f"}
.fa-kit.fa-file-prescription-regular::before, .fa-kit.fa-file-prescription-regular::before {content: "\e031"}
.fa-kit.fa-pills-regular::before, .fa-kit.fa-pills-regular::before {content: "\e033"}
.fa-kit.fa-regular-cloud-slash::before, .fa-kit.fa-regular-cloud-slash::before {content: "\e03b"}
.fa-kit.fa-regular-list-slash::before, .fa-kit.fa-regular-list-slash::before {content: "\e03c"}
.fak, .fa-kit {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome Kit";
  cursor: pointer !important;
}

@font-face {
  font-family: "Font Awesome Kit";
  font-style: normal;
  font-display: block;
  src: url("../webfonts/custom-icons.woff2") format("woff2"), url("../webfonts/custom-icons.ttf") format("truetype");
}
