.dashbord--body {
    .row {
        &.search--res {
            @media (min-width: 320px) {
                display: block !important;
            }
        }
    }
    .dashboard--search {
        .dashboard-tooltip {
            margin-top: -2.6rem;
            float: right;
            right: -2rem;
            position: absolute;
            color: solid-lighten($orange, 0.9946);
            .fa {
                font-size: 1.5625rem;
            }
            .dashboard-tooltip-text {
                visibility: hidden;
                width: 10.75rem !important;
                background-color: solid-lighten($orange, 0.9946);
                font-size: 0.75rem;
                color: $white;
                text-align: justify;
                padding: 0.5625rem 0.5rem;
                position: absolute;
                bottom: 120%;
                right: 0;
                margin-left: -2.5rem;
                opacity: 0;
                transition: opacity 0.6s;
                &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 96%;
                    margin-left: -0.3125rem;
                    border-width: 0.3125rem;
                    border-style: solid;
                    border-color: solid-lighten($orange, 0.9946) transparent
                        transparent transparent;
                }
            }
            &:hover {
                .dashboard-tooltip-text {
                    visibility: visible;
                    opacity: 1;
                    width: 10rem;
                    height: 6rem;
                    z-index: 99999;
                }
            }
        }
        .input-group {
            @extend %search-block;
            .search--input__box {
                border: 0rem !important;
            }
        }
        .search--help__text {
            color: $monsoon !important;
            padding-top: 0.3125rem;
        }
    }
    .dashboard--cards {
        .card--block {
            width: 24%;
            margin-right: 1%;
            .card--icons {
                margin-bottom: -2.14rem;
                // background: $white;
                .inake--icon {
                    padding: 1.57rem;
                    background: solid-lighten($light-blue, 0.9946);
                    border-radius: 50% !important;
                    box-shadow: 0px 3px 6px 1px rgba(165, 165, 165, 0.6);
                    width: 80px;
                }
            }
            .dashboard--cards__info-box {
              /*  box-shadow: 0px 2px 2px 0px rgba(165, 165, 165, 0.5);*/
                padding: 1.4rem 1rem 1rem 1rem;
                background: $white;
                border-radius: 15px;
                /*height: 20.5rem;*/
                .cards--info-box__grid {
                    .cards--info-box__header {
                        font-size: 1.5rem;
                        font-family: $default-font-family;
                        font-weight: 400;
                        position: relative;
                        top: 0.5rem;
                    }
                    .cards--info-box__content {
                        .cards--info-box__row {
                            display: inline-flex;
                            width: 100%;
                            padding: 2rem 1rem 3rem 1rem;
                            .notification-title,
                            .notification-title-100 {
                                // padding: 2rem;
                                width: 50%;
                                .notification-title-pad {
                                    text-align: center;
                                    align-items: center;
                                    width: 100%;
                                    line-height: 1;
                                    cursor: pointer;
                                    // height: 4rem;
                                    .notification-count a{
                                        color: $dflt-txt-clr;
                                        font-size: 2rem;
                                        font-weight: 400;
                                    }
                                    .notification-title{
                                        color: $dflt-txt-clr;
                                        font-size: 1.14rem;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                        position: relative;
                                        top: 0.285rem;
                                        padding-left: 0.642rem;
                                    }
                                }
                            }
                            .notification-title-100 {
                                width: 100%;
                            }
                        }
                        .cards--info-box__subheadr {
                            font-size: 1rem !important;
                            font-family: $default-font-family;
                            font-weight: 700;
                            position: relative;
                            top: 0.5rem;
                        }
                        .cards--info-box__intake {
                            padding: 1rem !important;
                            .cards--intake {
                                .notification-count {
                                    // a {
                                    //     font-size: 2.5rem !important;
                                    //     font-weight: 300 !important;
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dashbord--tip {
        li {
            float: left;
            width: 50%;
        }
    }
}

.bg-grey{
    background:$smoke;
}

.horz--rigt-line__seprator {
    border-right: 1px solid $disabled-txt-clr;
    // &::after{
    //     content: "";
    //     border-right: 1px solid $disabled-txt-clr;
    // }
}
.bottom--line__seprator {
    border-bottom: 1px solid $disabled-txt-clr;
}
.padding-top-5{
    padding-top: 5rem !important;
}


.inprogress-button{
    height: auto;padding: 0.5rem !important;
    background: rgb(247, 148,29);margin-top: 1rem;
    padding: 0.5rem;
    background-color: rgb(247, 148,29);
    color: white;
    &.ref-inprogress {
        background: #20c997;
        background-color: #20c997;
    }
    .rx-number{
        font-size:1.2rem;
        text-transform: initial !important;
        color: white !important;
        background-color:transparent !important;
    }
    .inprogress-font{
        font-size:1rem;
        text-transform: initial !important;
        color: white !important;
        background-color:transparent !important;
    }
}

.unbilled-queue--tabl {
    th, td {
        line-height: 0.6;
    }
}

.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
    border: 1px solid $smoke !important;
    // line-height: 1.2;
}

.border-bottom-dashbrd {
    &:after {
        border-bottom: 1px solid $base;
        width: 40%;
        content: "";
        position: absolute;
        top: 43px;
        text-align: center;
        left: 30%;
    }
}
.dasboard-header-hlp {
    margin-top: 6px;
    display: inline-flex;
    .hotkey_primary, .enable--hotkey__buttons {
        background-color: unset !important;
        border: unset !important;
        height: unset;
        max-width: unset !important;
        width: unset;
        padding: unset;
        margin: 4px !important;
        min-width: unset;
        box-shadow: unset;
        font-size: 0.9rem;
        margin: 0px !important;
        &:hover, &:focus, &:active {
            background-color: unset !important;
            border: unset !important;
            height: unset;
            max-width: unset !important;
            width: unset;
            padding: unset;
            margin: unset;
            min-width: unset;
            box-shadow: unset;
            color: $primary !important;
            span {
                background-color: $primary !important;
                color: $white !important;
            }
        }
    }
    .no-cap {
        span {
            padding: 3px 9px !important;
            &::before {
                content: unset !important;
            }
        }
    }

    .hotkey_primary {
        span {
            padding: 3px 4px;
        }
    }

    .enable--hotkey__buttons {
        .hotkey--buttons {
            margin-right: 5px !important;
        }
        .save--buttons {
            font-size: 0.9rem !important;
        }
        &:hover, &:focus, &:active {
            .hotkey--buttons {
                background-color: $success !important;
            }
            .save--buttons {
                background-color: $white !important;
                color: $success !important;
            }
        }
    }
    .gren-alt {
        position: relative;
        padding-top: 1px;
    }
}
.dashborad-widgets-sizes{
    .row{
        font-size: 1.2rem;
        color: $tuatara;
        margin-top: 11px;
    }
}

.rxFilled-widgets{
    .table {
        border: 1px solid rgba(0,0,0,0.2);
    }
    .table thead {
        &.inv--c1 {
            background-color: solid-lighten($cyan, 0.8746);
        }
        &.inv--c2 {
            background-color: #d5503f;
        }
        &.inv--c3 {
            background-color: solid-lighten($orange, 0.9746);
        }
        &.inv--c4 {
            background-color: solid-lighten($green, 0.98);
        }
        &.inv--c5 {
            background-color: #7266ba;
        }
        &.inv--c6 {
            background-color: #5e666d;
        }
    }
    .table th, .table td {
        border-bottom: 2px solid #c6c6c6;
        border-right: 2px solid #c6c6c6;
        padding: .1rem;
    }
}

.widgest_count_colors{
    background: rgb(247, 148,29) !important;
    font-weight: bold !important;
    color: black;
}

.cursor_pointer{
    cursor: pointer !important;
    border-radius: 5px;
}

.bg_light_gray{
    background-color: #e1e1e1;
    border-radius: 15px;
}

.mini-search-golbal {
    .input-group {
        .search--input__box {
            height: 2.57rem !important;
        }
    }
    .search--icon {
        .fa-search {
            top: 0.5rem !important;
        }
    }
}
.prescSearch{
    .search--icon {
        right: 8rem ;
        top:1.1rem ;
        position: absolute;
    }
}
