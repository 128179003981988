.p-chips {
    display: block !important;
    .p-chips-multiple-container {
    background: #FFF;
        &:hover,
        &:focus,
        &:active {
            border: 0.07rem solid #31afe5 !important;
        }
        .p-chips-token {
            padding: 0.2rem 0.25rem 0.25rem 0.5rem;
            margin-right: 0.8rem;
            background: #ebf5ff;
            color: #444444;
            border-radius: 15px;
            margin-bottom: 3px;
            margin-top: 3px;
            text-transform: uppercase;
        }
    }
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none ;
    border-color: $aluminium;
    background-color: $input-focus;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    width: 2.5rem;
    font-family: $default-font-family;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 16px;
    margin-right: 6px;
    margin-top: 2px;
    color: #6D6D6D;
}
.p-chips-token-label {
    margin-left: 4px;
}

.sort-icon {
    position: absolute;
    right: 28px;
    color: #999999;
    font-size: 17px;
    top: 26px;
    &.up_icon {
        top: 32px; 
    }
}

  