.edit--drug {
    .tablate--preview {
        width: 80px;
        height: 80px !important;
    }
    .rx-tablate--preview {
        width: 165px !important;
        height: 80px !important;
        padding-bottom: 4px;
    }
    .disphistory {
        padding-top: 1px;
    }
    .drugcoun {
        padding-bottom: 10px;
    }
    .drug--price__info {
        .flex--one {
            flex: 1;
            margin: 0rem 1.07rem 1.07rem 1.07rem;
            padding: 0rem;
            .box {
                box-shadow: unset;
                margin-bottom: 0rem;
            }
        }
    }
    .dinamic--fields {
        .plues--circle {
            font-size: 1.42rem;
            margin-top: 1.64rem;
            cursor: pointer;
        }
    }
    .inventory {
        flex: 1;
        min-height: 7.14rem;
        text-align: center;
        border-radius: 0.35rem;
        &.drug-inven {
            margin: 0rem 1.07rem 1.07rem 1.07rem;
        }
        &.inventory--color1 {
            background-color: solid-lighten($cyan, 0.8746);
        }

        &.inventory--color2 {
            background-color: #d5503f;
        }
        &.inventory--color3 {
            background-color: solid-lighten($orange, 0.9746);
        }
        &.inventory--color4 {
            background-color: solid-lighten($green, 0.98);
        }
        &.inventory--color5 {
            background-color: #7266ba;
        }
        &.inventory--color6 {
            background-color: #5e666d;
        }
        .inv--information {
            color: $white;
            .inv--value {
                padding-top: 5px;
            }
            .smalldata-inv--value {
                padding-top: 23px !important;
            }
            .inv--image--sml {
                height: 3.71rem;
               img {
                    width: 50%;
                    padding: 0.71rem;
                    height: 4.64rem;
                }
            }
            .inv--image {
                height: 5.71rem;
                // .button {
                //     border:none;
                // }

                img {
                    width: 50%;
                    padding: 0.71rem;
                    height: 4.64rem;
                }
            }
            &.bi-myPat {
                padding-top: 20px;
            }

        }
    }
    .grid {
        height: auto;
        max-height: 16.42rem;
        max-width: 82rem;
    }
    .drug--icons {
        list-style: none;
        padding: 0rem;

        color: $primary;
        font-size: 2.3rem;
        float: right;
        margin-left: 20px;

        li {
            float: right;
            cursor: pointer;
            width: 16%;
        }
    }
    .icons--padding {
        padding-right: 0.5rem;
    }
    .refresh--button {
        color: $primary;
        font-size: 1.5rem;
    }
    .white--space {
        padding-bottom: 0.5rem;
    }
    .padding--left__0 {
        padding-left: 0;
    }
    .padding--right__7 {
        padding-right: 0.5rem;
    }

    .padding--left__7 {
        padding-left: 0.5rem;
    }

    .before--transistion,
    .after--transistion {
        -webkit-transition: width 1s ease, height 1s ease;
        -moz-transition: width 1s ease, height 1s ease;
        -o-transition: width 1s ease, height 1s ease;
        transition: width 1s ease, height 1s ease;
    }
    .before--transistion {
        height: 0px;
        overflow: hidden;
    }
    .after--transistion {
        height: 20rem;
    }
    .dis-filter {
        float: right;
    }
    .fa--font {
        font-size: 1.42rem;
        margin-top: 1.64rem;
        cursor: pointer;
    }
    .actions {
        margin: 0rem 0.25rem 0rem 0.25rem;
        color: $primary;
        border-radius: 50%;
        font-size: 1.25rem;
        &.danger-actve {
            color: red !important;
        }
    }
    .headerImage {
        text-align: center;
        border-radius: 20%;
        background-color: $primary;
        position: relative;
        top: -0.12rem;
    }
    .headerimg {
        margin: 0px 0px 5px 5px;
        height: 18px;
    }
    .commonbut {
        padding-top: 10px;
    }
    .actions:hover {
        color: #ffc107 !important;
    }
    .chart {
        width: 70%;
        height: 100%;
        position: absolute;
        top: 10;
        transform: rotate(-0deg);
    }

    .text-area {
        resize: none;
    }
    .salesPadding {
        padding-bottom: 15px;
        padding-left: 46px;
    }
    .salesbtm {
        padding-bottom: 10px;
    }
    .salesrht {
        padding-right: 30px;
    }
    .Drug_tablate--preview {
        width: 125px !important;
        height: 100px !important;
    }

}
.editRx--tabing{
    margin-bottom: 1.07rem;
    $AlignList: 30;
    $TabList: 1,2,3,4,5,6,7,8,9,13,10,11;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.editdrug--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 1, 5, 6, 8, 11, 12, 13;
    $TabList: 1,2,3,4,5,6,7,8,9,10,11,12,13;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.rxtransfer--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 1,2;
    $TabList: 1,2;
    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}

.settingsicon {
    text-align: center;
    padding: 0.2rem 0.4rem 0rem 0.4rem;
    border-radius: 50%;
    background-color: $tertiary;
    margin: 0rem 0.8rem 0rem 1.2rem;
    position: relative;
    top: -0.12rem;
    height: 40px;
    .clogicon {
        color: white;
        font-size: 20px;
        padding-top: 7px;
    }
}

.settingheight {
    height: 5.6rem;
}

.refillWarning {
    color: red;
    text-align: center;
}

.settings--tabing {
    margin-bottom: 1.07rem;
    $AlignList: 0;
    $TabList: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17;

    .nav-tabs {
        @include nav-tabs-base($AlignList, $TabList);
    }
}


.business--tabbing {
        margin-bottom: 1.07rem;
        $AlignList: 0;
        $TabList: 1,2,3,4,5;
        margin-top: 1rem !important;
        .nav-tabs {
            @include nav-tabs-base($AlignList, $TabList);
    }
    }

.reports--condi {
    position: absolute;
    left: 25%;
    top: 50%;
}

.lotNum--Display:hover {
    color: rgb(247, 148,29) !important;
    font-weight: 900;
}
.addLot-align{
    height:2.6rem !important;
    align-items: center;
    display: inline-flex;
    padding-left: inherit;
    font-weight: bold;
}
.reportsicon {
    text-align: center;
    padding: 0.5rem 0.8rem 0.2rem 0.8rem;
    border-radius: 50%;
    background-color: #f7941d;
    margin: 0rem 0.8rem 0rem 1.2rem;
    position: relative;
    .customicon {
        color: white;
        font-size: 20px;
    }
}