// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable

$white       : #FFFFFF;
$ghost       : #FAFAFA;
$snow        : #F9F9F9;
$vapor       : #F6F6F6;
$white-smoke : #F5F5F5;
$body-back   : #e9ecf3;
$silver      : #EFEFEF;
$smoke       : #EEEEEE;
$gainsboro   : #DDDDDD;
$iron        : #CCCCCC;
$lightestGray: #ECF2F8;
$lightWhite  :#ffffffbd;
$base        : #AAAAAA;
$aluminium   : #a6a8b3;
$aluminum    : #999999;
$jumbo       : #888888;
$monsoon     : #777777;
$steel       : #666666;
$charcoal    : #555555;
$tuatara     : #444444;
$oil         : #333333;
$jet         : #222222;
$black       : #000000;
$thick_light_gray : #9b9999;
$disabled : #a4a2a2;
// $lightGray   :#e1e1e1;
$light-blue:    rgb(49, 175, 229) !default;
// $light-blue:    #5fb0d5 !default;
$Mirage:        #1b2033 !default;
// $red:           #df3c33 !default;
$red:           #f6251a !default;
$orange:        rgb(247, 148,29) !default;
$yellow:        #ffc107 !default;
$green:         #51a351 !default;
$teal:          #20c997 !default;
$cyan:          #17a2b8 !default;
$brown:         #964b00 !default;
$catskill-White:#ECF0F5 !default;
$body-dark-back: #d5d5d5;
// $carrot-orange: #f18b1d !default;
$gray-900:      #212529 !default;
$input-focus:   #ffd3ae !default;
$pink:          #fd14a0 !default;
$alice_blue: #f8fafe !default;
$light-gray: #5b6068 !default;
$dark-brown: brown !default;
$dark-green: darkgreen !default;
$off-white: #faebd7 !default;
$antiquewhite:antiquewhite !default;
$sky-blue: #429cd0 !default;
$dark_blue : blue;
$grey: gray !default;
$lightSkyBlue: #31AFE5 !default;
$light-pink: #ffbbbb !default;
$dark-gray : #999;
$text-color-black: #5d636b !default;
$stickyNotesbg: #D9F9C1 !default;
$stickyNotesborder: #5FBA94 !default;
$seaBlue:#0C7BB8;
$brightOrange: #F7941D;
$appleGreen: #51A351;
$brightRed: #F6251A;
$burntRed: #BD362F;
$orangePink: #FF7F50;
$lightBrown: #A3774B;
$deepPurple: #673AB7;
$transferinBorder: #D1D1D1;
$darkGray: #5D5D5D; 

//use these varible names
$primary:           $light-blue !default;
$secondary:         $Mirage !default;
$tertiary:          $orange !default;
$success:           $green !default;
$info:              $cyan !default;
$warning:           $yellow !default;
$danger:            $red !default;

$dflt-txt-clr:      $black !default;
$faded-txt-clr:     $monsoon !default;
$disabled-txt-clr:  $base !default;
$shaded-bg-clr:     $gainsboro;
$inactive-button-color: $jumbo;
$actn-btn-color:    $success;

// PaQueue colors
$pending : $seaBlue;
$approved: $appleGreen;
$submitted: $brightOrange;
$denied: $brightRed;
$cancelled: $tuatara;
$error: $burntRed;
$notInitate: $orangePink;
$inActive: $lightBrown;
$archive: $deepPurple;
$delete: $darkGray;



// Body
//
// Settings for the `<body>` element.
$bodybg: $catskill-White !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:    'Roboto', sans-serif !important;
$font-family-monospace:     'Roboto', sans-serif !important;
$font-family-base:          $font-family-sans-serif !important;
$default-font-family:       'Roboto', !default;
$secondary-font-family:     'Roboto', sans-serif !default;
$tertiary-font-family:      'Roboto Condensed', sans-serif !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

//header
$header-background:         $white !default;
$header-height:             80px !default;

//side Nav bar
$side-nav-text:             $aluminium !default;
$side-nav-active-text:      $white !default;

//login
$login-button:              $tertiary !default;

//dashboard
$daboard-search-btn:        $tertiary !default;



// Brand Colors

// Custom pixels
$fourtyfive : 45px !default;
$space: 8px;
$borderColor: #DBDBDB !default;

