.eprx--header__icons {
    list-style: none;
    padding: 0rem;
    margin: 0rem;
  //  margin-right: 1rem;
    float: right;
    &.rph-icons_postion {
        width: 10%;
    padding-right: 3px;
    padding-top: 1px;
    li {
        background-color: white;
    }
    .fa-lg {
        font-size: 1.7rem;
      padding-right: 4px;
    }
    }
    &.expr--icons__postition {
        position: absolute;
        right: 2%;
        background: #5fb0d5;
        width: 7%;
    }
    li {
        float: right;
        padding: 0 0 0 0.4rem;
        cursor: pointer;
        @media (max-width: rem) {
            width: 48%;
        }
        img,
        .fa {
            width: 1.9rem;
            height: 1.7rem;
        }
    }
    .icon-fon-s{
        font-size: 1.5rem;
    }
}
.focus {
    border: 0.07rem solid $white;
}

.pull-right > .fa {
    font-size: 1.35rem;
    line-height: 2rem;
    cursor: pointer;
}
.panal-title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
}
.pop--up__title {
    border-bottom: $light-blue 0.143rem solid;
    margin-bottom: 10px;
    .title--label {
        font-size: 1.2rem;
    }
}

.rx--filter {
    padding-left: 36%;
    position: absolute;
    display: flex;
    top: 6%;
    .rx--filter_text {
        color: $catskill-White;
        padding: 0rem 0rem 0rem 0.5rem;
        .header__btn {
            height: 2.6rem;
            padding: 0px 5px;
            color: $ghost;
            border: 1.5px solid $catskill-White;
            :hover {
                border: 0.125rem solid $white !important;
            }
        }
    }
    .rx--filter_ddl {
        height: 1.6rem;
        padding-left: 0.625rem;
        color: $Mirage;
        border-radius: 0.25rem 0rem 0rem 0.25rem !important;
        border-color: $brown;
    }
    .custom--input {
        width: 9rem;
        .ng-value {
            span {
                position: relative;
                top: 0.35rem;
            }
        }
        .ng-arrow-wrapper {
            position: relative;
            top: 0.35rem;
        }
    }
}

// cancel/change action menu css
.rx--actions{
    // float: right;
    .enable--hotkey__buttons {
        .dropdown-menu{
            &.show{
                width: unset;
                overflow-y: unset;
            }
        }

        .hotkey--buttons{
            font-size: 0.9rem !important;
        }

    }
    .Highlight--hotkey__buttons {
        .hotkey--buttons{
            font-size: 0.9rem !important;
            padding-left:0.4rem !important;
        }

    }
    .dropdown-toggle::after{
        top: 2px;
        position: relative;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 0.5rem !important;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        white-space: nowrap;
        background-color: inherit;
        border: 0;
        &:hover,
        &:active,
        &:focus {
            background: $light-blue  !important;
            color: $white  !important;
        }
        &.active{
            background: $light-blue  !important;
            color: $white  !important;
        }
    }
}

.drug--counsell-header{
    .custom--input{
        height: unset;
    }
}

.drug--counsell {
    border: 0.07rem solid $base !important;
    overflow-x: hidden;
    .questions {
        border-right: 0.07rem solid $base !important;
        max-height: 42.85rem;
        overflow-y: auto;
        .counsell--body {
            p {
                font-size: 0.85rem;
            }
        }
    }
    .drug--discription {
        display: table;
        height: 28.57rem;
        .description {
            display: table-cell;
            vertical-align: middle;
            .drug-desc--hdr {
                font-weight: 700;
                .drug-desc--txt {
                    &::before {
                        content: ":";
                    }
                }
                label{
                    padding: 0;
                    min-width: 8rem;
                }
            }
        }
    }
}

.sigCode--buttons {
    .submit {
        padding: 0.2rem 0.5rem;
        height: unset;
        margin: 0.1rem;
    }
}

.iou--button {
    background: $primary;
    border: 0.1rem solid $primary;
    border-radius: 0.2rem;
    color: $white;
    font-size: 1.2rem;
    margin: 1.45rem 0.1rem 0.9rem 0.1rem;
    min-width: 1.4rem;
    position: relative;
    top: -3px;
    .fa-people-carry{
        position: relative;
        left: -0.2rem;
    }
}

.bookshelf--block {
    .nav--Arrows {
        width: 2%;
        float: left;
        margin-top: 2rem;
    }
    .bookshelf {
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        width: 95%;
    }
    .book-tilted {
        float: left;
        padding: 0.15rem 0.15rem 0.15rem 0.15rem;
        width: 3rem;
        .book {
            height: 7.5rem;
            width: 2.75rem;
            float: left;
            color: $white;
            font-family: inherit;
            margin-bottom: -1rem;
            margin-top: 1.2rem;
            transition: transform 0.4s;
            &.book-blue {
                background-color: $primary;
                border-left: 2px solid lighten($primary, 4%);
                border-right: 2px solid darken($primary, 4%);
                border-radius: 5px;
                &.active-state {
                    background-color: $green;
                    border: 0.1rem solid $gainsboro;
                }
            }
            &h2 {
                padding: 0rem 1rem 0rem 0.5rem;
                font-size: inherit;
                transform-origin: 128% 84%;
                transform: rotate(-90deg) translateY(-2.3rem)
                    translateX(-2.7rem);
            }
            &h3 {
                padding: 0rem 1.5rem 0.4rem 1.3rem;
                font-size: inherit;
                transform-origin: 14% -38%;
                transform: rotate(-90deg) translateY(-1.2rem) translateX(-4rem);
                width: 11.8rem;
            }
        }
        .book:hover {
            // transform: scale(0.75);
            cursor: pointer;
            background-color: $orange;
            border: 0.1rem solid $gainsboro;
        }
    }

    .book-tilted a > .book {
        transform: translateY(-3px) translateX(15px) rotate(9deg);
    }
}
.bookshelf--block--releasenotes {
    .nav--Arrows {
        width: 2%;
        float: left;
        margin-top: 2rem;
    }
    .bookshelf {
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        width: 95%;
    }
    .book-tilted-release {
        padding: 0.15rem 0.15rem 0.15rem 0.15rem;
        .book {
            height: 2.5rem;
            width: 11rem;
            float: left;
            color: $white;
            font-family: inherit;
            margin-bottom: -1rem;
            margin-top: 1.2rem;
            transition: transform 0.4s;
            &.book-blue {
                background-color: $primary;
                border-left: 2px solid lighten($primary, 4%);
                border-right: 2px solid darken($primary, 4%);
                border-radius: 5px;
                &.active-state {
                    background-color: $green;
                    border: 0.1rem solid $gainsboro;
                }
            }
            &h2 {
                font-size: inherit;
                transform-origin: 128% 84%;
                transform: rotate(-90deg) translateY(-2.3rem)
                    translateX(-2.7rem);
            }
            &h3 {
                font-size: inherit;
                transform-origin: 14% -38%;
                transform: rotate(-90deg) translateY(-1.2rem) translateX(-4rem);
                width: 11.8rem;
            }
        }
        .book:hover {
            cursor: pointer;
            background-color: $orange;
            border: 0.1rem solid $gainsboro;
        }
    }

    .book-tilted a > .book {
        transform: translateY(-3px) translateX(15px) rotate(9deg);
    }
}
.book h2 {
    padding: 0rem 1rem 0rem 0.5rem;
    font-size: inherit;
    transform-origin: 128% 84%;
    transform: rotate(-90deg) translateY(-2.3rem) translateX(-2.7rem);
}
.book--release{
    font-size: inherit;
    transform-origin: 128% 84%;
    cursor: pointer;
    margin-top: 5px;
}
.book h3 {
    padding: 0rem 1.5rem 0.4rem 1.3rem;
    font-size: inherit;
    transform-origin: 14% -38%;
    transform: rotate(-90deg) translateY(-1.2rem) translateX(-4rem);
    width: 11.8rem;
}

.transmit--block{
    padding-left: 1.5rem;
    .tr--block {
        margin-top: -2px;
        .inline-flex{
            line-height: 0.8;
        }
        .eprx--block__content{
            .trasmit--rx{

            }
        }
    }

    .tr-rx-details{
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $faded-txt-clr;
    }


    .notes-font{
        font-size: 1.2rem !important;
    }



    .trasmit--rx {
        font-size: 1.1rem;
        label {
            min-width: 8rem;
            font-weight: 500;
        }
        .label--data {
            display: inline;
            line-height: 1.5;
            &::before {
                content: ":";
            }
        }
        table {
            tbody,
            thead {
                tr {
                    line-height: 0.5;
                    th {
                        color: $primary;
                        line-height: 0.5;
                    }
                    .text-right{
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                }
            }
        }

    }

    .transmit--btns{
        .inactive, .ternary, .secondary{
            min-width: 12rem !important;
            text-align: left;
            padding: 3px 10px;
            margin: 0.1rem !important;
        }
        .hotkey_success{
            min-width: 12rem;
            text-align: left;
            padding: 3px 10px;
            height: 2.4rem;
            margin-bottom: 0.2rem;
            span {
                width: 25px;
                display: inline-flex;
                padding: 3px 7px;
            }
        }
    }
    .pad-left-24{
        padding-left: 1.8rem;
    }
    .pad-left-34{
        padding-left: 2.5rem;
    }

    .bagrnd-profit{
        background: $green;
        color: white;
        padding: 0.2rem 0.5rem;
    }

    .bagrnd-loss{
        background: $red;
        color: white !important;
    }

    .bagrnd-profit__Heading{
        background: $green;
        color: white !important;
    }

}



.easy-ref--block{
    .easy-ref--content {
        padding-bottom: 1rem;
        .easy-ref {
            .label {
                min-width: 3rem !important;
                padding-top: 0.5rem !important;
            }
        }
    }
}

.pat-hist--header{
    display: inline-flex;
    float: right;
    .rx--filter_text {
        .secondary{
            height: 2.3rem;
            position: relative;
            top: 0.1rem;
        }
    }
    .custom--input {
        margin-bottom: unset;
        position: relative;
        top: 0.3rem;
        height: unset;
        .custom--select__span{
            position: relative;
            top: unset;
        }
        .rx--select, .form-control{
            height: 2.3rem !important;
            min-height: 2.2rem !important;
        }
    }
    .rx--filter_input{
        .custom--input{
            top: unset;
        }
    }
}

.rx-number--grid{
    padding-right: 8%;
    position: absolute;
    top: 1rem;
    right: -9rem;
    font-size: 1.4rem;
    color: $orange;

}
.rx-number--grid_refill{
    padding-right: 10%;
    position: absolute;
    top: 1.7rem;
    right: -4rem;
    font-size: 1.4rem;
    color: rgb(247, 148,29);
}
.rx-number--grid1{
    color: black !important;
    font-size: 1.4rem !important;
}

.rx-number--grid2{
    font-size: 1.4rem !important;
    color: $orange !important;
}
.scrool--vert{
    position: relative;
    display: block;
    min-height: 15rem;
    overflow-y: auto;
}

.bg--white-smoke{
    background-color: $white-smoke;
}

.pcard--sleek-view{
    padding: 0.3rem 0.7rem !important;
    .pcard--sleek__grid{
        display: inline-flex;
        width: 100%;
        line-height: 1.3;
        label{
            width: 120px;
        }
        div{
            &::before {
                content: ":";
            }
        }
    }
}

.rx-pres--search-grid{
    display: inline-flex;
    width: 100%;
    .rx-presc--search{
        width: 95%;
    }
    .pat--presc-list__icon{
        padding: 0.77rem 0 0rem 0.3rem;
        margin-left: 0.5rem;
        .fa-user-md{
            background: $tertiary;
            padding: 5px 4.1px;
            padding: 6px 5.1px;
            border-radius: 50%;
            color: white;
            cursor: pointer;
            font-size: 1.2rem;
            font-size: 1.8rem;
        }
        &:hover, &:active {
            .fa-user-md{
                background: #000;
            }
        }
    }
}

.newrx--body{
    .eprx--block__content{
        .label--data{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .custom--rx-info{
        display: block;
        background: white;
        padding: 0px;
        border: 0.07rem solid #e5e5e5;
        box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        margin-top: 0.5rem;
        .exprx--common-block{
            margin-top: 0px;
        }
        .eprx--block__content{
            border: unset;
            box-shadow: unset;
        }
    }
}

.custom--select__height-hide {
    height: 2.5rem;
    margin-bottom: 0.5rem !important;
}

.help--text__icons {
    float: right;
    position: relative;
    right: 30px;
}

.rx--tech-passwrd{
    .custom--input {
        height: unset;
    }
}

.custom-width-20 {
    width: 20% !important;
    display: block;
}

.primary-secondary--actns{
    .btn--red, .secondary, .inactive, .submit{
        padding: 0.5rem 0.4rem;
        min-width: 6rem;
        text-transform: uppercase;
        margin: 0 0.25rem 0.35rem 0;
        height: unset;
        top: -2px;
        position: relative;
        border-radius: 5px;
    }
    .inactive {
        padding: 0.4rem;
    }
    .secondary{
        padding: 0.55rem 0.4rem;
    }
}

.edit--insurance{
    position: absolute;
    top: 0px;
    right: 2rem;
    // color: $secondary;
    color: #5fb0d5;
    cursor: pointer;
}

.custm-width-27{
    width: 27%;
}

.custm-width-22{
    width: 22%;
}

.custm-width-28{
    width: 28%;
}

.custm-width-72{
    width: 72%;
}

.pat-pre-drug-block {
    .fa-kit {
        font-size: 1.4rem;
        margin-top: 7px;
    } 
    .fa_start_icon {
        font-size: 1.2rem !important;
        margin-top: -5px !important;
    }
    .eprx--header__heading {
        display: inline-flex !important;
        width: 100%;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .header--sml__text{
            width: 49%;
            // font-size: 1.2rem;
            .header--sml__lstName {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .header--sml__fstName {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .eprx--header__icons{
            //  width: 39%;
            width: 54%;
            li {
                padding:0px !important;
            }
            &.rph-icons_postion {
                width: 10%;
            padding-right: 3px;
            padding-top: 1px;
            li {
                background-color: white;
            }
            .fa-lg {
                font-size: 1.7rem;
              padding-right: 4px;
            }
            }
        }
        .header--sml__htex{
            width: 65px;
        }
    }
}
.pat-pre-drug-block-for_small_screen {
    .fa-kit {
        font-size: 1.3rem;
        margin-top: 10px;
    } 
    
    .fa_start_icon {
        font-size: 1.2rem !important;
        margin-left: -5px !important;
    }
    .fa-lg {
        font-size: 1.14rem !important ;
        line-height: .75em !important;
        vertical-align: -.0667em !important;
    }
    .eprx--header__heading {
        display: inline-flex !important;
        width: 100%;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .header--sml__text{
            width: 44%;
            // font-size: 1.2rem;
            .header--sml__lstName {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .header--sml__fstName {
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .eprx--header__icons{
            width: 53.1%;
            li {
                padding:0px !important;
            }
            &.rph-icons_postion {
                width: 10%;
            padding-right: 3px;
            padding-top: 1px;
            li {
                background-color: white;
            }
            .fa-lg {
                font-size: 1.7rem;
              padding-right: 4px;
            }
            }
        }
        .eprx--header__icons_width{
            width: 92.1% !important;
        }
        .header--sml__htex{
            width: 65px;
        }
    }
}
.wizard-color-code {
    .his-bg--red__text--white  {
      background-color: #bd362f;
      color: #ECF0F5;
    }
    .his-bg--disabled__text--white {
        background-color: $disabled;
        color: $white;
      }
    .his-bg--grey__text--white {
        background-color: grey;
        color: #ffffff;
      }
    .his-bg--blue__text--white {
        background-color: #0000FF;
        color: #ECF0F5;
    }
    .his-bg--darkgreen__text--white {
        background-color: darkgreen;
        color: #ECF0F5;
    }
    .his-bg--lightgreen__text--white {
        background-color: lightgreen;
        color: #000
    }
    .his-bg--darkgreen__text--black {
        background-color: darkgreen;
        color: #000;
    }
    .his-bg--white__text--black {
        background-color: #ECF0F5;
        color: #000;
    }
    .his-bg--lightgreen__text--black {
        background-color: lightgreen;
        color: #000;
    }
    .his-bg--orange__text--black {
        background-color: orange;
        color: #000;
    }
    .his-bg--orange__text--red {
        background-color: orange;
        color: #FF0000;
    }
    .his-bg--white__text--red {
        background-color: #FFF;
        color: #FF0000;
    }
    .his-bg--white__text--black {
        background-color: #FFF;
        color: #000;
    }
    .his-bg--orange__text--blue {
        background-color: orange;
        color: #0000FF;
    }
    .his-bg--white__text--blue {
        background-color: #FFF;
        color: #0000FF;
    }
    .his-bg--white__text--lightGreen {
        background-color: #FFF;
        color: lightgreen;
    }
    .his-bg--white__text--Darkgreen {
        background-color: #FFF;
        color: darkgreen;
    }
    .his-bg--red_text-brown {
        background-color:#ff7770;
        color:brown;
    }
    .his-bg--red_text-brown_with_text_black {
        background-color:#ff7770;
        color:black;
    }
    .his-bg--green_text-brown_text_balck {
        background-color:red;
        color:black !important;
    }
    .his-bg--red_text-white {
        background-color:#ff7770;
        color: white;
    }
    .his-bg--green_text-black {
        background-color: #86e096;
        color:black;
    }
    .his-bg--white_color-brown {
        background-color:#FFF;
        color:brown;
    }
    .his-bg--white_text-black {
        background-color:#FFF;
        color:black;
    }
    .his-bg--white_text-gray {
        background-color:#FFF;
        color:gray;
    }
    .his-bg-white_text-orange{
        background-color:#FFF;
        color:orange;
    }
    .his-bg-white_text-red{
        background-color:#FFF;
        color:#FF5C54;
        border: 1px solid #B7B4B4 !important;
        border-radius: 4px !important;
    }
    .his-bg--white_text-green {
        background-color: #FFF;
        color:#51A351;
        border: 1px solid #B7B4B4 !important;
        border-radius: 4px !important;
    }
    .his-bg--white_text-black_drug_grid {
        background-color:#FFF;
        color:black;
        border: 1px solid #B7B4B4 !important;
        border-radius: 4px !important;
    }
    .help_preferred_drug_grid {
        border: 1px solid #B7B4B4 !important;
        border-radius: 4px !important;
    }
    .color_legend_vertical_line_drug_grid{
        border-left: 6px solid rgb(8, 111, 27) !important;
    }
    .color_legend_vertical_line-notassign_drug_grid{
        border-left: 6px solid #A8A8A8 !important;       
    }
    .his-bg-green_text-white {
        background-color: #51a351;
        color: white !important;
    }
    .his-bg-orange_text-white {
        background: $orange;
        color: white !important;
    }
    .his-bg--white_text-green--noborder {
        background-color: #FFF;
        color:#51A351;
    }
}

.editRx--price {
    margin-bottom: 0px;
    .col-4, .col-3, .col-2, .col-1, .col-6 {
        border: 1px solid #ccc;
        text-align: center;
        .control-label{
            padding-top: 5px;
            font-weight: 600;
            overflow: hidden;
            span {
                font-weight: 600;
            }
        }
    }
    .custom--input{
        height: 1.8rem !important;
        .form-control {
            height: 2rem !important;
        }
    }
}

.rxActionInput{
    height: 36px;
    width: 32px;
    margin-right: 3px;
    .form-control{
        border: 1px solid #f6251a !important;
    }
}

.Quick-verificaition{
    overflow-x: hidden;
    max-height: 82vh;
    overflow-y: scroll;
    label{
        padding: unset !important;
    }
    .eprx--block__content{
        overflow: unset;
        height: unset;
    }
}

.print-med-guide-inactive {
    margin: 0 0 5px 0;
    height: 2.2rem;
}

.med-guide-inactive {
    background: #f5f5f5;
    border: 0.125rem solid #888;
    border-radius: 0.1875rem;
    font-size: 1rem;
    min-width: 7rem;
    text-transform: uppercase;
    font-weight: 250;
    font-family: Roboto;
    cursor: no-drop;
}

.print-med-guide {
    margin: 0 0 5px 0;
    height: 2.2rem;
    span{
        background: transparent !important;
        /* margin-left: 1rem; */
        font-size: 12px;
        margin: 0px;
        color: white !important;
    }
    &:hover, &:active, &:focus {
        // color: $white;
        // background: $light-blue;
        // box-shadow: 0 0.0625rem 0.125rem 0.125rem $gainsboro;
    }
}


.print-med-guide_dms {
    margin: 0 0 5px 0 !important;
    height: 1.8rem;
    span{
        background: transparent !important;
        /* margin-left: 1rem; */
        font-size: 12px;
        margin: 0px;
        color: white !important;
    }
    &:hover, &:active, &:focus {
        // color: $white;
        // background: $light-blue;
        // box-shadow: 0 0.0625rem 0.125rem 0.125rem $gainsboro;
    }
}


.equvlnt-drgs--grid{
    position: fixed;
    right: 1.100vw;
    bottom: 1vh;
    border: 0.07rem solid $gainsboro;
    z-index: 9999;
    width: 57% !important;
    .modal-body {
        background-color: $snow;
        .wj-flexgrid {
            max-height: 27rem;
            width: 48vw;
        }
        .custom--close {
            color: $black;
            right: 2rem;
        }
    }
    .modal-bodyDrug {
        background-color: $snow;
        .wj-flexgrid {
            max-height: 27rem;
            width: 56vw;
        }
        .custom--close {
            color: $black;
            right: 2rem;
        }
    }
    .card {
        background-color: transparent;
    }
    width: 57% !important;
}



.prev-ref--grid {
    @extend .equvlnt-drgs--grid;
    right: 2rem !important;
    top: 40rem !important;
    bottom: unset !important;
    border: unset !important;
}

.rx--prev-next__grd{
    display: inline-flex;
    padding-right: 16%;
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-size: 1.5rem;
    color: $primary;
    .backwrd--icon, .forwrd--icon, .backwrd--icon-disbl, .forwrd--icon-disbl {
        padding: 0px 0.5rem;
        text-align: center;
        line-height: 1;
        cursor: pointer;
        div {
            font-size: 1rem;
        }
    }
    .backwrd--icon-disbl, .forwrd--icon-disbl {
        color: $disabled-txt-clr;
    }
    .custom-control-label {
        font-size: 1rem;
    }
    .custom-control {
        padding-right: 1rem;
    }
}

.editRx-dollor-sign {
    .fa-dollar-sign {
        padding-top: 7px !important;
    }
}


.rx-doc-blk{
    position: absolute;
    right: 16px;
    top: 52px;
    width:480px;
    border: 2px solid;
    z-index:1
}

.help-text-blk{
    &.exprx--common-block{
        border-radius: 25px !important;
        cursor: move;
        .eprx--header__heading{
            // cursor: move;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }
        .eprx--block__content{
            max-height: 86vh;
            overflow-x: hidden;
            overflow-y: scroll;
            border: 0.1rem solid #003355 !important;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }
    position: absolute;
    width:300px;
    // border: 2px solid;
    z-index:2000;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
        box-shadow: 0px 2px 15px ;
    .free-dragging {
        box-shadow: 0px 10px 25px;;
    }
    .color-box{
        border: 1px solid;
        border-radius: 8px;
        height: 30px;
        margin: 2px;
        font-weight: bold;
        padding-left: 0rem;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
    }
    .his-bg--peach__color--black{
        background-color : #FF9999;
        color : #000;
    }
    .his-bg--peach__color--white{
        background-color : #FF9999;
        color : #FFF;
    }
    .his-bg--lightgreen__text--black{
        background-color : lightgreen;
        color : #000;
    }
    .his-bg--lightgreen__text--white{
        background-color : lightgreen;
        color : #000
    }
    .his-bg--darkgreen__text--white{
        background-color:darkgreen;
        color : #FFF;
    }
    .his-bg--blue_text--white{
        background-color:#0000FF;
        color : #ECF0F5;
    }
    h5{
        color: #003355 !important;
    }
    .text-left {
        text-align: left !important;
    }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
.buttons-center{
    margin-block-start:auto;
}

.Quick-verificaition_eRx {
    overflow-x: hidden;
    max-height: 82vh;
    label{
        padding: unset !important;
    }
}

.QuickVerf--eRxDoc__height {
    height: 43rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.QuickVerf--height_normal--grid {
    height: 40rem !important;
}
.U_c_popup{
    @extend .trasmit--rx;
    .label{
        min-width: 4rem !important
    }


}

.U_c_popupRx{
    @extend .trasmit--rx;
    .label{
        min-width: 2rem !important
    }


}
.font_size_17px{
    font-size: 17px !important;
}
.medium-color--legend {
    height: 2rem !important;
}
