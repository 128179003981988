.tabing_css_navtabs_medium {
    .nav-tabs{
        li {
            width: 7.9rem !important;
        }
    }
   
         .nav-link .txt div{
            line-height: 1.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .demo_css{
            line-height: 2 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .business_intelligence{
            line-height: 2.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         
         li .txt {
                 /* z-index: 120; */
    /* left: 7.5rem; */
    /* position: relative; */
    /* width: 9.28rem; */
    line-height: 2;
    height: 2.9rem;
    width: 9.28rem  !important;
    background-image: url(/assets/inActiveTab-outline3-mm.svg) !important;
    /* position: absolute; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: unset;
    background-color: unset;
    border-color: unset;
    border: unset;
    &:hover {
        background-image: url("/assets/ActiveTab-dropshadow3-mm.svg")!important;
        z-index: 200;
        color: $white;
        height: 3rem;
        position: absolute;
    }
         }
         .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            border-color: none  !important;
         }
         .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
             border: none !important;
         }
         .txt div {
            font-size: 0.92rem;
            font-weight: 700;
            line-height: 2.4;
            line-height: 1.1;
        }
       .avail-rec, .payRecords-tab-head-bg-green{
            .txt {
                background-image: url("/assets/ActiveTab-green.svg") !important;
                color: white;
            }
        }
         .nav-link .active  {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                z-index: 200;
                color: $white;
                height: 3rem;
                position: absolute;
            }
            
        }
         .nav-tabs .nav-link.active, .tabing_css_patient .nav-tabs .nav-item.show .nav-link {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg") !important;
                z-index: 200;
                color: $white;
                height: 3rem;
            }
        }
        .nav-tabs .nav-link {
            border:  none !important;
        }
        .nav-tabs .nav-item .disabled {
            .txt {
               
                background-image: url("/assets/inActiveTab-outline3grey-mm.svg") !important;
                &:hover {
        
                    background-image: url("/assets/inActiveTab-outline3grey-mm.svg") !important;
                    color: unset;
                    cursor: not-allowed;
                }
            }
        }
}
.tabing_css_navtabs_medium {
    .nav-tabs{
        li {
            width: 6.4rem !important;
        }
    }
    
         .nav-link .txt div{
            line-height: 1.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .demo_css{
            line-height: 2 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .business_intelligence{
            line-height: 2.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         
         li .txt {
            background-image: url(/assets/inActiveTab-outline3-mm.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            color: unset;
            background-color: unset;
            border-color: unset;
            border: unset;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            color: unset;
            background-color: unset;
            border-color: unset;
            border: unset;
            &:hover {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg")!important;
                z-index: 200;
                color: $white;
                height: 3rem;
                position: absolute;
            }
         }
         .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            border-color: none  !important;
         }
         .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
             border: none !important;
         }
         .txt div {
            font-size: 0.92rem;
            font-weight: 700;
            line-height: 2.4;
            line-height: 1.1;
        }
         .nav-link .active  {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                z-index: 200;
                color: $white;
                height: 3rem;
                position: absolute;
            }
            
        }
         .nav-tabs .nav-link.active, .tabing_css_patient .nav-tabs .nav-item.show .nav-link {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg") !important;
                z-index: 200;
                color: $white;
                height: 3rem;
                position: absolute;
            }
        }
        .nav-tabs .nav-link {
            border:  none !important;
        }
}

.tabing_css_navtabs_fax {
    .nav-tabs{
        li {
            width: 7.8rem !important;
        }
    }
   
         .nav-link .txt div{
            line-height: 1 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .demo_css{
            line-height: 2 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .business_intelligence{
            line-height: 2.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         li .txt {
            background-image: url(/assets/inActiveTab-outline3-mm.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            color: unset;
            background-color: unset;
            border-color: unset;
            border: unset;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            color: unset;
            background-color: unset;
            border-color: unset;
            border: unset;
         }
         .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            border-color: none  !important;
         }
         .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
             border: none !important;
         }
         .txt div {
            font-size: 0.92rem;
            font-weight: 700;
            line-height: 2.4;
            line-height: 1.1;
        }
         .nav-link .active  {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                z-index: 200;
                color: $white;
                height: 3rem;
            }
            
        }
         .nav-tabs .nav-link.active, .tabing_css_patient .nav-tabs .nav-item.show .nav-link {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg") !important;
                z-index: 200;
                color: $white;
                height: 3rem;
            }
        }
        .nav-tabs .nav-link {
            border:  none !important;
        }
}

.tabing_css_navtabs_forlarge {
    li {
        width: 20.9rem !important;
    }
         .nav-link .txt div{
            line-height: 2.1 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .demo_css{
            line-height: 2 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .business_intelligence{
            line-height: 2.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         li .txt {
            position: relative;
            width: 26.28rem !important;
            background-image: url(/assets/inActiveTab-outline3-mm.svg);
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            color: unset;
            background-color: unset;
            border-color: unset;
            border: unset;
            line-height: 1.5;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: unset;
            border-color: unset;
            border: unset;
            line-height: 2;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &:hover {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg")!important;
                z-index: 200;
                color: $white;
                height: 3rem;
                position: absolute;
            }
         }
         .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            border-color: none  !important;
         }
         .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
             border: none !important;
         }
         .txt div {
            font-size: 0.92rem;
            font-weight: 700;
            line-height: 2.4;
            line-height: 1.1;
        }
         .nav-link .active  {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                z-index: 200;
                color: $white;
                height: 3rem;
            }
        }
         .nav-tabs .nav-link.active, .tabing_css_patient .nav-tabs .nav-item.show .nav-link {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg") !important;
                z-index: 200;
                color: $white;
                height: 3rem;
            }
        }
        .nav-tabs .nav-link {
            border:  none !important;
        }
        .nav-tabs .nav-item .disabled {
            color: #6c757d;
            background-color: transparent;
            border-color: transparent;
        }
}
.tabing_css_navtabs_medium-rx {
    .nav-tabs{
        li {
            width: 7.1rem !important;
        }
    }
         .nav-link .txt div{
            line-height: 1.3!important;
            padding: 0.6rem 1.67rem 0.6rem 1rem;
            word-break: break-word !important;
         }
         .nav-link .txt .demo_css{
            line-height: 2 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         .nav-link .txt .business_intelligence{
            line-height: 2.3 !important;
            padding: 0.6rem 1.67rem 0.6rem 1.4rem;
            word-break: break-word !important;
         }
         li .txt {
                 /* z-index: 120; */
    /* left: 7.5rem; */
    /* position: relative; */
    /* width: 9.28rem; */
    line-height: 2;
    height: 3rem;
    width: 7.5rem  !important;
    background-image: url(/assets/inActiveTab-outline3-mm.svg) !important;
    /* position: absolute; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: unset;
    background-color: unset;
    border-color: unset;
    border: unset;
    &:hover {
        background-image: url("/assets/ActiveTab-dropshadow3-mm.svg")!important;
        z-index: 200;
        color: $white;
        height: 3rem;
        position: absolute;
    }
         }
         .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            border-color: none  !important;
         }
         .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
             border: none !important;
         }
         .txt div {
            font-size: 0.92rem;
            font-weight: 700;
            line-height: 2.4;
            line-height: 1.1;
        }
        .avail-rec, .payRecords-tab-head-bg-green{
            .txt {
                background-image: url("/assets/ActiveTab-green.svg") !important;
                color: white;
            }
        }
         .nav-link .active  {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg");
                z-index: 200;
                color: $white;
                height: 3rem;
            }
            
        }
         .nav-tabs .nav-link.active, .tabing_css_patient .nav-tabs .nav-item.show .nav-link {
            .txt {
                background-image: url("/assets/ActiveTab-dropshadow3-mm.svg") !important;
                z-index: 200;
                color: $white;
                height: 3rem;
            }
        }
        .nav-tabs .nav-link {
            border:  none !important;
        }
        .nav-tabs .nav-item .disabled {
            .txt {
               
                background-image: url("/assets/inActiveTab-outline3grey-mm.svg") !important;
                &:hover {
        
                    background-image: url("/assets/inActiveTab-outline3grey-mm.svg") !important;
                    color: unset;
                    cursor: not-allowed;
                }
            }
        }
}

.navTabStyles {
    .nav-tabs {
        list-style: none;
        font-family: sans-serif;
        font-size: 12px;
        height: 2.9rem !important;
      
    }
    .nav-tabs li:has(> a.avail-rec):after,  .nav-tabs li:has(> a.avail-rec):before { // if tab will has data. 
        background-color: green !important;
        color: white !important;
    }
    .nav-tabs li:has(> a.disabled):after,  .nav-tabs li:has(> a.disabled):before { // if tab will be disabled. 
        background-color: #CCCCCC !important;
        cursor: not-allowed !important;
    }
    .nav-tabs li:has(> a.payRecords-tab-head-bg-green):after,  .nav-tabs li:has(> a.payRecords-tab-head-bg-green):before { // if tab will has data. 
        background-color: green !important;
        color: white !important;
    }
    a.avail-rec, a.payRecords-tab-head-bg-green{
        color: white !important;
    }
    .nav-tabs li {
        height: 36px;
        width: 93px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 700;
    }
    
    .nav-tabs li::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        border-radius: 10px 0 0 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        transform: skewX(-28deg);
        z-index: -1;
        background: #fefefe; 
    }
    
    .nav-tabs li::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        border-radius: 0 10px 0 0;
        transform: skewX(20deg);
        background-color: inherit;
        z-index: -1;
        box-shadow: 1px 0px 0px  0px rgba(0, 0, 0, 0.2);
        background: #fefefe;
    }
   

    .nav-tabs li:has(> a.active) {
        height: 36px;
        width: 93px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        z-index: 16;
        color: white !important;
    }

    .nav-tabs .nav-link.active {
       border: none !important;
    }

    

    .nav-tabs li:not([class='active']):hover::before, .nav-tabs li:not([class='active']):hover::after {
        background-color: #5FAFD5; 
    }
    .nav-tabs li:not([class='active']):hover {
       z-index: 16;
       color: white !important;
    }
    .nav-tabs li:has(> a.active) {
        z-index: 16;
        color: white !important;
     }
     .nav-tabs li:has(> a.active)::before, .nav-tabs li:has(> a.active)::after  {
        background-color: #5FAFD5!important;
    }
    .nav-tabs .nav-link.active {
        border-color: none !important;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #ffffff !important;
        border: 0px !important;
    }
}
.navTabStyles_Medium {
    .nav-tabs {
        list-style: none;
        font-family: sans-serif;
        font-size: 12px;
        height: 2.9rem !important;
      
    }
    .nav-tabs li:has(> a.avail-rec):after,  .nav-tabs li:has(> a.avail-rec):before { // if tab will has data. 
        background-color: green !important;
        color: white !important;
    }
    .nav-tabs li:has(> a.disabled):after,  .nav-tabs li:has(> a.disabled):before { // if tab will be disabled. 
        background-color: #CCCCCC !important;
        cursor: not-allowed !important;
    }
    .nav-tabs li:has(> a.payRecords-tab-head-bg-green):after,  .nav-tabs li:has(> a.payRecords-tab-head-bg-green):before { // if tab will has data. 
        background-color: green !important;
        color: white !important;
    }
    a.avail-rec, a.payRecords-tab-head-bg-green{
        color: white !important;
    }
    .nav-tabs li {
        height: 36px;
        width: 75px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 700;
    }
    
    .nav-tabs li::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        border-radius: 10px 0 0 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        transform: skewX(-28deg);
        z-index: -1;
        background: #fefefe; 
    }
    
    .nav-tabs li::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        border-radius: 0 10px 0 0;
        transform: skewX(20deg);
        background-color: inherit;
        z-index: -1;
        box-shadow: 1px 0px 0px  0px rgba(0, 0, 0, 0.2);
        background: #fefefe;
    }
   

    .nav-tabs li:has(> a.active) {
        height: 36px;
        width: 75px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        z-index: 16;
        color: white !important;
    }

    .nav-tabs .nav-link.active {
       border: none !important;
    }

    

    .nav-tabs li:not([class='active']):hover::before, .nav-tabs li:not([class='active']):hover::after {
        background-color: #5FAFD5; 
    }
    .nav-tabs li:not([class='active']):hover {
       z-index: 16;
       color: white !important;
    }
    .nav-tabs li:has(> a.active) {
        z-index: 16;
        color: white !important;
     }
     .nav-tabs li:has(> a.active)::before, .nav-tabs li:has(> a.active)::after  {
        background-color: #5FAFD5!important;
    }
    .nav-tabs .nav-link.active {
        border-color: none !important;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #ffffff !important;
        border: 0px !important;
    }
}
.navTabStyles_large {
    .nav-tabs {
        z-index: 5;
        list-style: none;
        font-family: sans-serif;
        font-size: 12px;
        height: 2.9rem !important;
      
    }
    .nav-tabs li:has(> a.avail-rec):after,  .nav-tabs li:has(> a.avail-rec):before { // if tab will be active 
        background-color: green !important;
        color: white !important;
    }
    .nav-tabs li:has(> a.disabled):after,  .nav-tabs li:has(> a.disabled):before { // if tab will be disabled. 
        background-color: #6c757d !important;
        color: white !important;
        
    }
    .nav-tabs li:has(> a.payRecords-tab-head-bg-green):after,  .nav-tabs li:has(> a.payRecords-tab-head-bg-green):before { // if tab will has data. 
        background-color: green !important;
        color: white !important;

    }
    a.avail-rec, a.payRecords-tab-head-bg-green{
        color: white !important;
    }
    .nav-tabs li:has(> a.disabled){
        color: white !important;
        cursor: not-allowed !important;
    }
    a.avail-rec{
        color: white !important;
    }
    .nav-tabs li {
        height: 36px;
        width: 160px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .nav-tabs li::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        border-radius: 10px 0 0 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        transform: skewX(-28deg);
        background-color: inherit;
        z-index: -1;
        background: #fefefe; 
    }
    
    .nav-tabs li::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        border-radius: 0 10px 0 0;
        transform: skewX(20deg);
        background-color: inherit;
        z-index: -1;
        box-shadow: 1px 0px 0px  0px rgba(0, 0, 0, 0.2);
        background: #fefefe;
    }
   

    .nav-tabs li:has(> a.active) {
        height: 36px;
        width: 160px;
        display: flex;
        padding: 5px;
        position: relative;
        float: left;
        text-align: center;
        z-index: 1;
        align-items: center;
        justify-content: center;
        z-index: 16;
        color: white !important;
    }

    .nav-tabs .nav-link.active {
       border: none !important;
    }

    
    .nav-tabs li.active::before,
    .nav-tabs li.active::after {
        background-color: #5FAFD5;
        border-bottom-color: #fff;
    }

    .nav-tabs li:not([class='active']):hover::before {
        background-color: #5FAFD5; 
    }
    
    .nav-tabs li:not([class='active']):hover::after {
        background-color: #5FAFD5;
    }
    .nav-tabs li:not([class='active']):hover {
       z-index: 16;
       color: white !important;
    }
    .nav-tabs li:has(> a.active) {
        z-index: 16;
        color: white !important;
     }
     .nav-tabs li:has(> a.active)::before {
        background-color: #5FAFD5!important;
    }
    
    .nav-tabs li:has(> a.active)::after {
        background-color: #5FAFD5!important;
    }
    .nav-tabs .nav-link.active {
        border-color: none !important;
    }
    .nav-tabs  .nav-link {
          font-family: 'Roboto';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 12px;
        line-height: 13px;
    }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #ffffff !important;
        border: 0px !important;
    }
}
.nav-tabs .nav-link:not(.active) .txt > div {
    font-weight: bold !important;
}
.nav-tabs .nav-link.active .txt > div {
    font-weight: bold !important;
}
.nav-tabs .nav-link.disabled .txt > div {
    font-weight: normal !important;
}