#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    .alert-success , .alert-danger{
        height:unset;
        bottom:unset;
    }
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: $dflt-txt-clr;
    opacity: 0.4;
    z-index: 9999;
    -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0); /* IE 9 */
    transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
    left: 0;
}

#loader-wrapper .loader-section.section-right {
    right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(-100%); /* IE 9 */
    transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(100%); /* IE 9 */
    transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

// ADD Loader SCSS here

svg {
    display: block;

    margin: 0;
    padding: 0;
}


@keyframes zoomanim{
        0%{width: 100px;}
    25%{width: 70px;}
    50%{width: 100px;}
    75%{width: 70px;}
    100%{width: 100px;}
}

.spinner {
    width: 450px;
    text-align: center;
    // height: 66px;
    // animation: contanim 2s linear infinite;
    position: absolute;
    z-index: 99999999;
    left: 40vw;
    top: 40vh;
    .loader-img{
        margin: auto;
        animation: zoomanim 5s linear infinite !important;
    }
}

$colors: $yellow, #00abeb, $green, $Mirage;
$d: 175.6449737548828;

svg {
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;
    position: absolute;


    @for $i from 1 through 4 {
        &:nth-child(#{$i}) circle {
            stroke: nth($colors, $i);
            stroke-dasharray: 1, 300;
            stroke-dashoffset: 0;

            animation: strokeanim 3s calc(0.2s * (#{$i})) ease infinite;
            transform-origin: center center;
        }
    }
}

@keyframes strokeanim {
    0% {
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -$d / 3;
    }
    100% {
        stroke-dasharray: 120, 300;
        stroke-dashoffset: -$d;
    }
}

@keyframes contanim {
    100% {
        transform: rotate(360deg);
    }
}
#toast-container{
    .toast .toast-message{
        color: #ffffff;
        background-color: $steel;
        border-color: $oil;
    }
}
